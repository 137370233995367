import { Box, Button, Dialog, DialogContent, Stack, Step, Chip, StepLabel, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react';

import SelectDay from './Steps/SelectDay';
import SelectDriver from './Steps/SelectDriver';
import SelectRace from './Steps/SelectRace';
import SelectTelemetry from './Steps/SelectTelemetry';
import axios from 'axios';
import myUserStore from 'stores/UserStore';
import { observer } from "mobx-react-lite";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DriverAnalytics from 'components/pages/DriverAnalytics';



const DriversDates = ({
  isOpen = '',
  laps = [],
  driverSessions = [],
  race = '',
  day = '',
  arrayChange,
  step,
  stepPayload,
  setStepPayload,
  setIsOpen,
  setStep,
  secondOpen,
  selectedDriver,
  completed,
  setCompleted,
  chatType,
  isDriverAnalytics,
 
  isTry,
  isDist,
  notTelem,
  onComplete = () => { }
}) => {
  console.log('chatType', chatType)
  const isAiMotor1 = chatType === 'AiMotor 1';
  const selectedTeam = myUserStore.getSelectedTeam;
  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
  const myEmail =isTry ? 'support@aimotor.com' :  parsedUserData.myEmail
  const isPersonalSpace = myUserStore.isPersonalSpace;
  const status = myUserStore.status
  const [myPlan, setMyPlan] = useState(status != 'CANCELLED' ? myUserStore?.planCode : 'No Plan');
  const [isFetchComplete, setIsFetchComplete] = useState(false);
  console.log("secondOpen", secondOpen)
  //const [openDialog, setOpenDialog] = useState(isOpen)
  let steps;
  if (isPersonalSpace || isAiMotor1) {
    if(isDriverAnalytics) {
      steps = ['Select race', 'Select day', 'Select telemetry'];

    }else{
      steps = ['Select race', 'Select day', 'Select Driver'];
    }
  } else if (isDriverAnalytics) {
    steps = ['Select race', 'Select day', 'Select driver', 'Select telemetry'];
  } else {
    steps = ['Select race', 'Select day', 'Select driver'];
  }




  const handleComplete = () => {
    // Aggiungi uno 0 al vettore 'laps'
 
    const updatedPayload = {
      ...stepPayload,
      ...(stepPayload?.laps && {
        laps: (() => {
          debugger; // Punto di interruzione per ispezionare stepPayload e laps
          return [...stepPayload.laps, 0];
        })(),
      }),
    };
    saveFilters(updatedPayload); // Salva i filtri al completamento
    onComplete(updatedPayload);
    handleClose();
    setCompleted(true);
  };
  const handleChooseAll = () => {
    setStepPayload((prev) => ({
      ...prev,
      ['drivers']: []
    }))
    onComplete(stepPayload)
    handleClose()
    setCompleted(true)

  }
  

  const handleClose = () => {
    setIsOpen(false)
    arrayChange(stepPayload)
  }

  const handleStepChange = (who, value, goNext = true) => {
    debugger
    setStepPayload((prev) => ({
      ...prev,
      [who]: value
    }))
    if ((isPersonalSpace || isAiMotor1) && stepPayload?.drivers?.length === 0) {
      setStepPayload((prev) => ({
        ...prev,
        ['drivers']: [{
          value: JSON.parse(localStorage.getItem("user_data"))?.email,
          label: `${JSON.parse(localStorage.getItem("user_data"))?.name} ${JSON.parse(localStorage.getItem("user_data"))?.surname}`
        }]   
         }))
    }
    goNext && handleStepNext()
  }

  const handleStepBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepNext = () => {
    setStep((prevActiveStep) => prevActiveStep + 1);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchFilters = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_filters/?team_id=${selectedTeam.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        });
      const filters = response.data;
      console.log("Filters fetched successfully:", filters);

      if (Object.keys(filters.filters)?.length > 0) {
        setStepPayload(filters.filters);
        arrayChange(filters.filters); // Aggiorna i dati nella componente
      }
    } catch (error) {
      console.error("Error fetching filters:", error);
    } finally {
      setIsFetchComplete(true); // Indica che la fetch è completata
    }

  };
  console.log('step payload', stepPayload)
  const shouldOpenDialog = (stepPayload) => {
    Object.entries(stepPayload).forEach(([key, value]) => {
      console.log(`Key: ${key}, Value:`, value); // Log per debug
    });
   
    return Object.entries(stepPayload).some(([key, value]) =>
      (value === "" || (key === 'laps' && (Array.isArray(value) && value.length === 0)))
    );
  };

  const saveFilters = async (filters) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_DOMAIN}/set_filters/?team_id=${selectedTeam.id}`,
        filters,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }

      );
      console.log("Filters saved successfully");
    } catch (error) {
      console.error("Error saving filters:", error);
    }
  };

  useEffect(() => {
    if (selectedTeam) {
      fetchFilters();
    }
    // Carica i filtri iniziali per il team selezionato

  }, [selectedTeam]);
  

  return (
    <>

      {(isTry || isDist || ((isFetchComplete && shouldOpenDialog(stepPayload)) || secondOpen)) && (
        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"md"}
          sx={{ zIndex: 30000000000 }}

        >
          <Chip
  variant="outlined"
  color='info'
  sx={{
    marginTop: 3, // Margine superiore
    marginLeft: "auto", // Centrato orizzontalmente
    marginRight: "auto",
    marginBottom:-3, // Testo in grassetto
    width: selectedTeam?.name?.length * 8 + 16 || "auto", // Larghezza dinamica basata sulla lunghezza del nome del team
  }}
  label={isPersonalSpace ? "Personal Space" : selectedTeam?.name}
/>

          <DialogContent >
            <Stepper activeStep={step} alternativeLabel sx={{ mt: 2 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box p={1}>
              {step === 0 && <SelectRace isTry={isTry} selectedTeam={selectedTeam} activeRace={stepPayload.race} onChange={(newValue) => handleStepChange('race', newValue)} />}
              {step === 1 && <SelectDay isTry={isTry} activeRace={stepPayload.race} activeDay={stepPayload.day} onChange={(newValue) => handleStepChange('day', newValue)} />}
              {step === 2 && (isPersonalSpace || isAiMotor1) && isDriverAnalytics
                ? <SelectTelemetry isTry={isTry} selectedDriver={selectedDriver} selectedTeam={selectedTeam} activeDriver={stepPayload.drivers} activeDay={stepPayload.day} activeRace={stepPayload.race} laps={stepPayload?.laps} driverSessions={stepPayload.driverSessions} completed={completed} onChangeDriverSessions={(newValue) => handleStepChange('driverSessions', newValue, false)} onChange={(newValue) => handleStepChange('laps', newValue, false)} />
                :  step === 2  && <SelectDriver notTelem={notTelem} isTry={isTry} selectedTeam={selectedTeam} activeDriver={stepPayload.drivers} onChange={(newValue) => handleStepChange('drivers', newValue, false)} />}
              {step === 3 && !(isPersonalSpace || isAiMotor1) && isDriverAnalytics && !(isPersonalSpace || isAiMotor1) && <SelectTelemetry isTry={isTry} selectedDriver={selectedDriver} selectedTeam={selectedTeam} activeDriver={stepPayload.drivers} activeDay={stepPayload.day} activeRace={stepPayload.race} laps={stepPayload.laps} driverSessions={stepPayload.driverSessions} completed={completed} onChangeDriverSessions={(newValue) => handleStepChange('driverSessions', newValue, false)} onChange={(newValue) => handleStepChange('laps', newValue, false)} />}              <Stack direction={"row"} spacing={2} alignItems={'center'} justifyContent={'center'} mt={3}>
                <Button variant="text" disabled={step === 0} onClick={() => handleStepBack()}>Prev</Button>


                {
                  step === (isDriverAnalytics && !(isPersonalSpace || isAiMotor1) ? 3 : 2)
                    ? <Button variant="outlined" onClick={() => handleComplete()}>COMPLETE</Button>
                    : null
                }
                {/*{
                step === (isDriverAnalytics ? 25 : 2)
                  ? <Button variant="outlined" onClick={() => handleChooseAll()}>CHOOSE ALL</Button>
                  : null
              }*/}
                {
                  step === (isDriverAnalytics && !(isPersonalSpace || isAiMotor1)? 2 : 1) ? <Button variant="outlined" disabled={stepPayload.drivers.length === 0} onClick={() => handleStepNext()}>Next</Button> : null
                }


              </Stack>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default observer(DriversDates);
