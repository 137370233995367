import { Box, Skeleton, Stack } from '@mui/material';
import { Button, Chip, ListItemIcon, ListItemText, Menu, MenuItem, Popover,FormControl, InputLabel, Select } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ChatIcon from '@mui/icons-material/Chat';
import ChatMessage from 'components/Chat/Message';
import ChatMessageLoader from 'components/Chat/MessageLoader';
import ChatSuggestion from 'components/Suggestions';
import DOMPurify from 'dompurify';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Grid from '@mui/material/Grid';
import HttpProvider from 'services/HttpProvider'
import IconButton from '@mui/material/IconButton';
import Markdown from 'marked-react';
import Paper from '@mui/material/Paper';
import Pricing from '../Pricing';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Try_Drawer from './Try_Drawer';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

import Typography from '@mui/material/Typography';
import Writer from 'components/Chat/Writer';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useLocation } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddIcon from '@mui/icons-material/Add';
import SuggestionsList from 'components/Chat/Suggestions/SuggestionsList';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AddCommentIcon from '@mui/icons-material/AddComment';
import Fab from '@mui/material/Fab';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import ListIcon from '@mui/icons-material/List';
const languageOptions = [
    { code: 'en', label: 'English', flag: '🇺🇸' },
    { code: 'it', label: 'Italiano', flag: '🇮🇹' },
    { code: 'fr', label: 'Français', flag: '🇫🇷' },
    { code: 'de', label: 'Deutsch', flag: '🇩🇪' },
    { code: 'es', label: 'Español', flag: '🇪🇸' },
    { code: 'pt', label: 'Português', flag: '🇵🇹' },
    { code: 'ru', label: 'Русский', flag: '🇷🇺' },
    { code: 'zh', label: '中文', flag: '🇨🇳' },
    { code: 'ja', label: '日本語', flag: '🇯🇵' },
    { code: 'ar', label: 'العربية', flag: '🇸🇦' },
    { code: 'hi', label: 'हिन्दी', flag: '🇮🇳' },
    { code: 'ko', label: '한국어', flag: '🇰🇷' },
    { code: 'tr', label: 'Türkçe', flag: '🇹🇷' },
    { code: 'nl', label: 'Nederlands', flag: '🇳🇱' },
    { code: 'pl', label: 'Polski', flag: '🇵🇱' },
    { code: 'sv', label: 'Svenska', flag: '🇸🇪' },
    { code: 'fi', label: 'Suomi', flag: '🇫🇮' },
    { code: 'no', label: 'Norsk', flag: '🇳🇴' },
    { code: 'da', label: 'Dansk', flag: '🇩🇰' },
    { code: 'cs', label: 'Čeština', flag: '🇨🇿' },
    { code: 'el', label: 'Ελληνικά', flag: '🇬🇷' },
    { code: 'he', label: 'עברית', flag: '🇮🇱' },
    { code: 'vi', label: 'Tiếng Việt', flag: '🇻🇳' },
    { code: 'th', label: 'ภาษาไทย', flag: '🇹🇭' },
    { code: 'ms', label: 'Bahasa Melayu', flag: '🇲🇾' },
    { code: 'id', label: 'Bahasa Indonesia', flag: '🇮🇩' },
];


const chatTypes = [
    {
        title: 'AiMotor 1',
        description: 'Great for Personal Space',
        type: 'normal',
        icon: <FlashOnIcon fontSize="small" />,
        activeFor: ['Growth', 'Pro', 'Team'],
        userPlan: ['Growth'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day: 1,
            day_team: 1,
        },
    },
    {
        title: 'AiMotor 2',
        description: 'Advanced model for Racing Teams',
        type: 'advanced',
        icon: <StarIcon fontSize="small" />,
        activeFor: ['Pro', 'Team'],
        userPlan: ['Growth', 'Pro', 'Team'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day: 1,
            day_team: 1,
        },
    },
];

const Chat = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [chatSuggestions, setChatSuggestions] = useState([]);
    const [openPricing, setOpenPricing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chatType, setChatType] = useState(chatTypes[1]);
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const [messageText, setMessageText] = useState('');
    const location = useLocation();
  
    const query = new URLSearchParams(location.search);
    const initialQuestion = query.get('question');
    const [currentStreamingMessage, setCurrentStreamingMessage] = useState('');
    const [incomingFragments, setIncomingFragments] = useState([]);
    const [isResponseStreaming, setIsResponseStreaming] = useState(false);
    const selectedChatId = '66de0882378af215137603b7';
    const [allChatMessages, setAllChatMessages] = useState({});
    const [conversation, setConversation] = useState([]);
    const [showChipSuggestions, setShowChipSuggestions] = useState(true);
    const [showChipSubmit, setShowChipSubmit] = useState(true);
    const [anchorElDropdown, setAnchorElDropdown] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('en')
    const [dataset, setDataset] = useState({});
    const [newMemory, setNewMemory] = useState('');
    const [showScrollFab, setShowScrollFab] = useState(false);
    const [selection, setSelection] = useState("3");
    const [openChat, setOpenChat] = useState(false);
    const [openDistribution, setOpenDistribution] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleMenuItemClick = (model) => {
        const getChatToBeSelected = chatTypes.find((c) => c.title === model);

        if (getChatToBeSelected) {
            setChatType(getChatToBeSelected);
            handleClose();
        }
    };

    useEffect(() => {
        if (initialQuestion) {
            handleMessageSubmit(initialQuestion); // Automatically submit question from URL
        }
    }, [initialQuestion]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };
    const toggleSelection = (value) => {
        setSelection((prev) => {
            if (prev === "3") {
                return value.toString(); // Se nulla era selezionato, imposta il nuovo valore
            }

            if (prev === "1" && value === 0) return "2"; // Aggiunta Setup a Telemetry
            if (prev === "0" && value === 1) return "2"; // Aggiunta Telemetry a Setup

            if (prev === "2" && value === 0) return "1"; // Rimozione Setup
            if (prev === "2" && value === 1) return "0"; // Rimozione Telemetry

            return "3"; // Se tutto è deselezionato torna a 3
        });
    };
    const suggestionsRef = useRef(null);
    const handleNewChat = async () => {
        // //    const newChatId = await generateChatId();
        // //    setAllChatMessages(prevMessages => ({ ...prevMessages, [newChatId]: { name: '', messages: [], createdAt: new Date() } }));
        // //    setSelectedChatId(newChatId);
        // // We do NOT create the conversation here.
        // setSelectedChatId(null);
        // // Possibly clear out message text, etc.
        // setMessageText('');

        // Actually create a new chat and select it
        const newChatId = await generateChatId();
        setAllChatMessages(prevMessages => ({
            ...prevMessages,
            [newChatId]: { name: '', messages: [], createdAt: new Date() },
        }));
        

        setMessageText('');
    };

    const handleAttachClick = (event) => {
        setAnchorElDropdown(event.currentTarget);
    };

    const handleCloseDropdown = () => {
        setAnchorElDropdown(null);
    };

    const isDropdownOpen = Boolean(anchorElDropdown);

    const generateChatId = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/create_conversation`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        const data = await response.json();
        const conversation_id = data.conversation_id;
        return conversation_id;
    };
    // Close suggestions when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                setChatSuggestions([]);
                setShowChipSuggestions(true)// Clear the list

               // setMessageText('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const showSuggestionsByChip = (suggestion) => {
        // set message to add into searchbar -> helpText
        setMessageText(suggestion.helpText)
        setChatSuggestions(suggestion.suggestions)
        setShowChipSuggestions(false)
    };

    const handleSuggestionClick = (message) => {
        setMessageText('')
        setChatSuggestions([])
        handleMessageSubmit(message)
        setShowChipSuggestions(false)
    };

    useEffect(() => {
        scrollToBottom();  // Scroll to bottom whenever messages change
    }, [messages]);  // Depend on messages state

    const handleMessageComplete = (message, role = 'system') => {
        setMessages((prevMessages) => [...prevMessages, { message, role }]);

        setConversation((prevConversation) => [
            ...prevConversation.slice(-3), // Keep only the last conversation entry
            { message, role },
        ]);

        setIncomingFragments([]);
        setIsResponseStreaming(false);
    };
    const MAX_CONVERSATION_CHARACTERS = 4000;
    const truncateConversation = (conversation) => {
        let totalCharacters = 0;

        // Reverse the conversation array to prioritize retaining the most recent messages
        const truncatedConversation = [...conversation].reverse().filter((entry) => {
            const messageLength = entry.message.length; // Get the length of the `message` key
            if (totalCharacters + messageLength <= MAX_CONVERSATION_CHARACTERS) {
                totalCharacters += messageLength;
                return true; // Keep this entry
            }
            return false; // Exclude this entry
        });

        return truncatedConversation.reverse(); // Reverse it back to the original order
    };


    const handleMessageSubmit = async (message) => {
        debugger
        setShowChipSubmit(false);
        const encodedMessage = encodeURIComponent(message);
        const newMessage = { role: 'user', message: message };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setConversation((prevConversation) => [
            ...prevConversation.slice(-3), // Keep only the last conversation entry
            newMessage,
        ]);

        setIsResponseStreaming(true);
        scrollToBottom();

        try {
            setIsLoading(true);
            const endpoint = chatType.title === 'AiMotor 1' ? 'try_aimotor_1' : 'try_aimotor_2';

            const truncatedConversation = JSON.stringify(truncateConversation(conversation));

            await fetchEventSource(`${process.env.REACT_APP_API_DOMAIN}/${endpoint}?request=${encodedMessage}&conversation=${truncatedConversation}&choice=${selection}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Accept: "text/event-stream",
                },
                onmessage(event) {
                    setIsLoading(false);
                    let message = event.data;
                    if (message === "[DONE]") {
                        message = `"${message}"`;
                    }
                    setIncomingFragments((prev) => [...prev, JSON.parse(message)]);
                },
                onclose() {
                    setIncomingFragments((prev) => [...prev, JSON.parse('"[DONE]"')]);
                    setIsLoading(false);
                },
                onerror(err) {
                    setIsLoading(false);
                    console.error('Error querying LLM:', err);
                },
            });
        } catch (error) {
            setIsLoading(false);
            console.error('Error querying LLM:', error);
        }
    };



    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleWriterComplete = () => {
        if (currentStreamingMessage) {
            setMessages((prevMessages) => [
                ...prevMessages,
                { message: currentStreamingMessage, role: 'system' },
            ]);

            setConversation((prevConversation) => [
                ...prevConversation,
                { message: currentStreamingMessage, role: 'system' },
            ]);
        }
        setIsResponseStreaming(false);
        setCurrentStreamingMessage('');
    };

    const resetChat = () => {
        setMessages([]);  // Clear the messages
        setConversation([]);  // Clear the conversation
        setCurrentStreamingMessage('');  // Clear the streaming message
        setIsResponseStreaming(false);  // Stop streaming response
    };


    console.log("Conv:", conversation)
    return (
        <>
            <Box className="chat-wrapper" container={true} justifyContent="center">
                <Box sx={{ position: 'absolute', top: '16px', left: '16px', display: 'flex', alignItems: 'center' }}>
                    <Button
                        aria-controls={open ? 'version-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        variant="text"
                        sx={{ color: '#0D47A1', textTransform: 'none', fontSize: '22px', fontWeight: 'bold' }}
                    >
                        {chatType.title}
                        <ArrowDropDownIcon />
                    </Button>
                    <Menu
                        id="version-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ mt: 1 }}
                    >
                        {chatTypes.map((type) => (
                            <MenuItem key={type.title} onClick={() => handleMenuItemClick(type.title)}>
                                <ListItemIcon>
                                    {type.icon}
                                </ListItemIcon>
                                <ListItemText primary={type.title} secondary={type.description} />
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

                <Box sx={{ height: "100%", width: '100%', pt: 10 }} display={'flex'} flexDirection={'column'} mx={'auto'}>
                   <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ flex: '1', display: messages.length ? 'none' : 'flex' }}>
                        {showChipSuggestions && (<ChatSuggestion onClick={showSuggestionsByChip} sx={{ maxWidth: 800 }} />)}
                    </Stack>
                    <div
                        style={{ flex: '1', overflowY: 'auto', display: messages.length ? 'block' : 'none' }}
                        ref={chatContainerRef}
                    >
                        <Box sx={{
                            maxWidth: '800px',
                            margin: '0 auto'
                        }}>

                            {messages.map((msg, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                    <ChatMessage owner={msg.role === "user"} message={msg.message} />
                                </div>
                            ))}
                            {isLoading && (
                                <ChatMessageLoader />
                            )}


                            {/* Show the streaming message while loading */}
                            {isResponseStreaming && !isLoading && (
                                <div style={{ marginBottom: '10px' }}>
                                    <Writer
                                        incomingFragments={incomingFragments}
                                        setIncomingFragments={setIncomingFragments}
                                        onComplete={(message) => handleMessageComplete(message)}
                                        scrollChat={scrollToBottom}
                                    />
                                </div>
                            )}
                        </Box>
                    </div>
                    <div ref={suggestionsRef} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <SuggestionsList list={chatSuggestions} onClick={handleSuggestionClick} />
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            const message = e.target.elements.message.value;
                            if (message.trim() !== '') {
                                handleMessageSubmit(message)
                                e.target.reset();
                                setMessageText('');
                            }
                        }}
                        style={{ display: 'flex', alignItems: 'center', position: 'relative', bottom: 0, padding: '10px', zIndex: 2, width: '100%', maxWidth: '800px', margin: '0 auto' }}
                    >
                        <Paper
                            sx={{
                                p: '7px',
                                borderRadius: '16px',
                                display: 'flex',
                                flexDirection: "column",
                                alignItems: 'center',
                                width: '100%',
                                backgroundColor: 'whitesmoke',
                            }}
                        >
                            <TextField
                                fullWidth
                                placeholder="Message AiMotor"
                                variant="standard"
                                name="message"
                                value={messageText}
                                multiline
                                InputLabelProps={{
                                    style: { color: 'black', textAlign: 'center', width: '100%' },
                                    shrink: true,
                                }}
                                InputProps={{
                                    style: { color: 'black', textAlign: 'center' },
                                    disableUnderline: true,
                                }}
                                sx={{ textAlign: 'center', minHeight:45  }}
                                onChange={(e) => setMessageText(e.target.value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                {/* Chat button */}
                                <div style={{ display: 'flex', gap: 10 }}>
                                <Tooltip title="Start a New Chat">
                                        <IconButton
                                            onClick={resetChat}
                                            sx={{
                                                color: '#0D47A1',
                                                backgroundColor: 'transparent',
                                                padding: '10px',
                                                width: '35px',
                                                height: '35px'
                                            }}
                                        >
                                            <AddCommentIcon sx={{ fontSize: '25px' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <IconButton
                                        sx={{
                                            color: '#0D47A1', // Colore grigio
                                            backgroundColor: 'transparent', // Rimuove sfondo
                                            padding: '10px',
                                            width: '35px',
                                            height: '35px'
                                        }}
                                        onClick={handleAttachClick}
                                    >
                                        <ListIcon sx={{ fontSize: '25px' }} />
                                    </IconButton>
                                    <Popover
                                        open={isDropdownOpen}
                                        anchorEl={anchorElDropdown}
                                        onClose={handleCloseDropdown}
                                        anchorOrigin={{
                                            vertical: 'top', // Posizionamento sopra rispetto all'ancoraggio
                                            horizontal: 'center', // Centra orizzontalmente rispetto all'ancoraggio
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom', // L'origine parte dal basso del popover
                                            horizontal: 'center',
                                        }}
                                        PaperProps={{
                                            sx: {
                                                borderRadius: '16px', // Bordi arrotondati
                                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Ombra delicata
                                                padding: '10px', // Spaziatura interna
                                                backgroundColor: 'whitesmoke', // Colore di sfondo bianco
                                                marginTop: '-20px', // Aumenta la distanza per spostarlo sopra
                                            },
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            <Button
                                                startIcon={<AttachFileIcon />}
                                                onClick={() => history.push('/racing-space-try/telemetry-data')}
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    color: '#0D47A1', // Testo nero
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5', // Colore hover più chiaro
                                                    },
                                                }}
                                            >
                                                Insert Telemetry
                                            </Button>
                                            <Button
                                                startIcon={<AddIcon />}
                                                onClick={() => history.push('/racing-space-try/racing-data')}
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    color: '#0D47A1', // Testo nero
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5', // Colore hover più chiaro
                                                    },
                                                }}
                                            >
                                                Insert Setup
                                            </Button>
                                            <Button
                                                startIcon={<MyLocationIcon />} // Icona "Track Map"
                                                onClick={() => setOpenChat(true)} // Mostra dialogo Track Map
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    color: '#0D47A1', // Testo nero
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5', // Colore hover più chiaro
                                                    },
                                                }}
                                            >
                                                Track Map
                                            </Button>
                                            {/* <Button
                                                startIcon={<AutoGraphIcon />} // Icona "Data Distribution"
                                                onClick={() => setOpenDistribution(true)} // Mostra dialogo Data Distribution
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    color: '#0D47A1', // Testo nero
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5', // Colore hover più chiaro
                                                    },
                                                }}
                                            >
                                                Data Distribution
                                            </Button> */}
                                            <Button
                                                startIcon={<ChatIcon />} // Icona Chat
                                                onClick={() => {
                                                    setIsDrawerOpen(true); // Apri il drawer
                                                    setAnchorElDropdown(false); // Chiudi il dropdown
                                                }} // Logica per aprire il drawer
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    color: '#0D47A1', // Testo nero
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5', // Colore hover più chiaro
                                                    },
                                                }}
                                            >
                                                Previous chat history
                                            </Button>
                                            {/* Selettore delle lingue */}
                                            <FormControl fullWidth
                                                sx={{
                                                    '& .MuiInputLabel-root': {
                                                        color: '#0D47A1',               // Etichetta blu
                                                    },
                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                        color: '#0D47A1',              // Etichetta blu quando in focus
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#0D47A1',        // Bordo blu iniziale
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#0D47A1',        // Bordo blu al passaggio mouse
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#0D47A1',        // Bordo blu quando in focus
                                                    },
                                                    borderRadius: '8px',             // Arrotondi gli angoli
                                                }}>
                                                {/* <InputLabel id="language-select-label">Language</InputLabel>
                                                <Select
                                                    labelId="language-select-label"
                                                    value={selectedLanguage}                // <-- Deve mostrare la lingua selezionata
                                                    label="Language"
                                                    onChange={(e) => handleLanguageChange(e.target.value)} // <-- Aggiorna lo state
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200, // Altezza massima per lo scroll
                                                            },
                                                        },
                                                    }}
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        borderRadius: '8px',
                                                        fontFamily: 'Arial, sans-serif', // Necessario per le emoji
                                                    }}
                                                >
                                                    {languageOptions.map((lang) => (
                                                        <MenuItem key={lang.code} value={lang.code}>
                                                            {lang.flag} {lang.label} {/* Emoji + Nome lingua 
                                                        </MenuItem>
                                                    ))}
                                                </Select> */}
                                            </FormControl>

                                        </Box>
                                    </Popover>
                                </div>

                                {/* Send button */}
                                <div style={{ display: 'flex', gap: 10 }}>
                                    {/* <IconButton
                                        sx={{
                                            color: '#0D47A1', // Colore grigio
                                            backgroundColor: 'transparent', // Rimuove sfondo
                                            padding: '10px',
                                            width: '35px',
                                            height: '35px'
                                        }}
                                        onClick={() => history.push('/racing-space-try/racing-data')}
                                    >
                                        <AddIcon sx={{ fontSize: '25px' }} />
                                    </IconButton> */}
<Chip
                                        label="Telemetrist"
                                        clickable
                                        icon={<SignalCellularAltIcon />}
                                        variant={selection === "1" || selection === "2" ? "contained" : "outlined"}
                                        sx={{
                                            color: selection === "1" || selection === "2" ? "white" : "#0D47A1", // Colore del testo
                                            backgroundColor: selection === "1" || selection === "2" ? "#0D47A1" : "transparent", // Colore di sfondo
                                            borderColor: "#0D47A1", // Colore del bordo
                                            '& .MuiChip-icon': {
                                                color: selection === "1" || selection === "2" ? "white" : "#0D47A1", // Cambia colore dell'icona dinamicamente
                                            },
                                            '&:hover': {
                                                backgroundColor: selection === "1" || selection === "2" ? "#0D47A1" : "#e3f2fd", // Hover
                                            },
                                        }}
                                        onClick={() => toggleSelection(1)}
                                    />

                                    <Chip
                                        label="Setup"
                                        clickable
                                        icon={<TireRepairIcon />}
                                        variant={selection === "0" || selection === "2" ? "contained" : "outlined"}
                                        sx={{
                                            color: selection === "0" || selection === "2" ? "white" : "#0D47A1", // Colore del testo
                                            backgroundColor: selection === "0" || selection === "2" ? "#0D47A1" : "transparent", // Colore di sfondo
                                            borderColor: "#0D47A1", // Colore del bordo
                                            '& .MuiChip-icon': {
                                                color: selection === "0" || selection === "2" ? "white" : "#0D47A1", // Cambia colore dell'icona dinamicamente
                                            },
                                            '&:hover': {
                                                backgroundColor: selection === "0" || selection === "2" ? "#0D47A1" : "#e3f2fd", // Hover
                                            },
                                        }}
                                        onClick={() => toggleSelection(0)}
                                    />
                                    <IconButton
                                        type={isResponseStreaming ? 'button' : 'submit'}
                                        //onClick={isResponseStreaming ? handleStopChat : undefined}
                                        style={{
                                            minWidth: 'auto',
                                            backgroundColor: isResponseStreaming
                                                ? '#0D47A1' // Blue for stop
                                                : messageText.trim() !== ''
                                                    ? '#0D47A1' // Blue for send action
                                                    : 'transparent',
                                            borderRadius: '50%',
                                            padding: '10px',
                                            width: '35px',
                                            height: '35px'
                                        }}
                                    >
                                        {isResponseStreaming ? (
                                            <StopCircleIcon style={{ color: 'white' }} />
                                        ) : (
                                            <ArrowUpwardIcon
                                                style={{ color: messageText.trim() !== '' ? 'white' : 'gray' }}
                                            />
                                        )}
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>
                    </form>
                </Box>
                <div style={{ textAlign: 'center', fontSize: '13px', color: '#888' }}>
                    This is a demo version. Data has already been inserted.  </div>

                <Try_Drawer
                    isOpen={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    onNewChat={resetChat}
                    openChat={openChat}
                    setOpenChat={setOpenChat}  // Pass resetChat as a prop
                />

                <Pricing open={openPricing} onClose={() => setOpenPricing(false)} />
            </Box>
        </>
    );
};

export default Chat;
