import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { isToday, isWithinInterval, isYesterday, subDays } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DistributionDialog from './DistributionDialog';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Draggable from 'react-draggable';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import TrackMapChat from './TrackMapChat'; // Adjust the import as per your file structure
import Highlights from './Highlights';


// Import icons and ListItemIcon for menu items
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';


import AddCommentIcon from '@mui/icons-material/AddComment';


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1E1E1E',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#AAAAAA',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h6: {
            fontWeight: 'bold',
            color: '#BBBBBB',
        },
    },
});


const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
        backgroundColor: '#333333',
    },
}));


const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: 'hidden', // Prevents overflow
    textOverflow: 'ellipsis', // Adds ellipsis if text is too long
    whiteSpace: 'normal', // Allows wrapping
    wordBreak: 'break-word', // Breaks long words if necessary
    '& .MuiListItemText-primary': {
        fontWeight: '500',
        color: '#FFFFFF',
    },
    '& .MuiListItemText-secondary': {
        color: '#AAAAAA',
    },
}));
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:hover': {
        backgroundColor: '#333333',
    },
}));


const AnchorTemporaryDrawer = ({ onNewChat, handleChatSelection, openChat, isOpen, setOpenChat, onClose, isMessage ,trackClick = () => { }}) => {
    const [historyChats, setHistoryChats] = useState([]);
    const [categorizeChat, setCategorizeChat] = useState({});
    const userData = JSON.parse(localStorage.getItem("user_data"));
    const [race, setRace] = useState("");
    const [day, setDay] = useState("");
    const [driverSessions, setDriverSessions] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [openHighlights, setOpenHighlights] = useState(false);
    const [openDistribution, setOpenDistribution] = useState(false);
    // State to control Track Map Dialog visibility
    const [activeChatId, setActiveChatId] = useState(null); // State for active chat item
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [menuChatId, setMenuChatId] = useState(null);
    const [openTrack, setOpenTrack] = useState(false);
    const [editChatId, setEditChatId] = useState(null); // ID of chat being edited
    const [editedTitle, setEditedTitle] = useState(''); // New title for chat
    const originalTitleRef = React.useRef(''); // To store the original title in case of cancel
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isMobile = useMediaQuery(darkTheme.breakpoints.down('sm'));


    const loadHistoryChat = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/get_conversations/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });


        let data = [];
        if (response?.ok) {
            data = await response.json();
        }
        setHistoryChats(data);
        const categorized = handleCategorizeChats(data);
        setCategorizeChat(categorized);
        return data;
    };


    const getLatestRace = async () => {
        axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/latest_race`, {
                //.get(`https://aimotor.eu/api/latest_race`, {
                params: {
                    user_email: userData?.email
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    accept: "application/json",
                },
            })
            .then((res) => {
                setRace(res.data.race);
                setDay(res.data.day);
                setDriverSessions(res.data.driverSessions);
            })
            .catch((error) => {
                console.error("Axios Error:", error);
            });
    };


    useEffect(() => {

        if (isOpen) {
            getLatestRace();
            loadHistoryChat();
        }
    }, [,isOpen]);
    useEffect(() => {
        debugger
        if (openChat || isMessage) {
            setIsDialogOpen(true)
            setOpenChat(false)

        }

    }, [openChat, isMessage]);

    useEffect(() => {
        if(isMessage){
            setOpenTrack(true)
        }
      

    }, [isMessage]);


    const handleCategorizeChats = (chats) => {
        const today = [];
        const yesterday = [];
        const previous7Days = [];
        const previous30Days = [];


        const oneDayAgo = subDays(new Date(), 1);
        const sevenDaysAgo = subDays(new Date(), 7);
        const thirtyDaysAgo = subDays(new Date(), 30);


        chats.forEach(chat => {
            const chatDate = new Date(chat.last_interaction);
            if (isToday(chatDate)) {
                today.push(chat);
            } else if (isYesterday(chatDate)) {
                yesterday.push(chat);
            } else if (isWithinInterval(chatDate, { start: sevenDaysAgo, end: oneDayAgo })) {
                previous7Days.push(chat);
            } else if (isWithinInterval(chatDate, { start: thirtyDaysAgo, end: sevenDaysAgo })) {
                previous30Days.push(chat);
            }
        });


        return { today, yesterday, previous7Days, previous30Days };
    };


    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };


    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        trackClick(false)
    };


    const handleMenuOpen = (event, chatId) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setMenuChatId(chatId);
    };


    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setMenuChatId(null);
    };


    const handleDeleteChat = async (chatId) => {
        handleMenuClose(); // Close the menu if it's open


        try {
            const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/delete_conversation?conversation_id=${chatId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });


            if (response.ok) {
                await loadHistoryChat();
                setActiveChatId(null);
            } else {
                const data = await response.json();
                console.error(data.detail);
            }
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
    };








    const initiateEditChat = (chatId, currentTitle) => {
        setEditChatId(chatId);
        setEditedTitle(currentTitle || '');
        originalTitleRef.current = currentTitle || '';
    };


    const handleUpdateChatTitle = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/update_conversation_title?conversation_id=${editChatId}&title=${encodeURIComponent(editedTitle)}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });


            if (response.ok) {
                setEditChatId(null);
                setEditedTitle('');
                await loadHistoryChat();
            } else {
                const data = await response.json();
                console.error(data.detail);
            }
        } catch (error) {
            console.error('Error updating conversation title:', error);
        }
    };


    const handleCancelEdit = () => {
        setEditChatId(null);
        setEditedTitle('');
    };


    const handleChatItemInteraction = (chatId, event) => {
        if (isTouchDevice) {
            event.stopPropagation();
            setActiveChatId(prevId => (prevId === chatId ? null : chatId));
        }
    };


    const handleKeyDown = (event, chatId) => {
        if (event.key === 'Enter') {
            handleUpdateChatTitle();
        } else if (event.key === 'Escape') {
            handleCancelEdit();
        }
    };


    return (
        <>
            {isDialogOpen && !isMobile && (
            <Draggable bounds="parent">
                <div
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        top: 50,
                        right: 20,
                        backgroundColor: '#fff',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: 30,
                        padding: 20,
                    }}
                >
                    <IconButton
                       onClick={() => {
                        setIsDialogOpen(false);
                        trackClick(false);
                      }}
                      onTouchStart={
                        () => {
                            setIsDialogOpen(false);
                            trackClick(false);
                      }}
                        style={{
                            position: 'absolute',
                            bottom: 10,
                            right: 10,
                            backgroundColor: 'red',
                            color: 'white',
                            borderRadius: '50%',
                            width: 30,
                            height: 30,
                            zIndex: 100000,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        ✖
                    </IconButton>

                    <TrackMapChat
                        race={race}
                        driverSessions={[driverSessions]}
                        day={day}
                    />
                </div>
            </Draggable>
        )}
            <Highlights open={openHighlights} onClose={() => setOpenHighlights(false)} />
            <DistributionDialog open={openDistribution} onClose={() => setOpenDistribution(false)} />
            <ThemeProvider theme={darkTheme}>


                <Drawer anchor="left" open={isOpen} onClose={onClose}>
                    <Box sx={{ width: 280, backgroundColor: '#1E1E1E', color: '#FFFFFF' }}>
                        <List>
                            <Typography variant="h6" sx={{ padding: 2 }}>
                                New
                            </Typography>


                            <StyledListItem disablePadding button onClick={onNewChat}>
                                <IconButton>
                                    <AddCommentIcon />
                                </IconButton>


                                <StyledListItemText primary="New Chat" />
                            </StyledListItem>
                            {/* <Typography variant="h6" sx={{ padding: 2 }}>
                               Info
                           </Typography>
                           <StyledListItem disablePadding button onClick={handleOpenDialog}>
                               <IconButton>
                                   <MyLocationIcon />
                               </IconButton>
                               <StyledListItemText primary="Track Map" />
                           </StyledListItem>
                           {/* <StyledListItem disablePadding button onClick={() => setOpenHighlights(true)}>
                               <IconButton>
                                   <AutoGraphIcon />
                               </IconButton>
                               <StyledListItemText primary="Highlights" />
                           </StyledListItem>
                           <StyledListItem disablePadding button onClick={() => setOpenDistribution(true)}>
                               <IconButton>
                                   <AutoGraphIcon />
                               </IconButton>
                               <StyledListItemText primary=" Data Distribution" />
                           </StyledListItem> */}
                        </List>
                        <Divider sx={{ backgroundColor: '#333333' }} />


                        {/* Render Chat Categories */}
                        {['today', 'yesterday', 'previous7Days', 'previous30Days'].map((category) => (
                            categorizeChat?.[category]?.length > 0 && (
                                <React.Fragment key={category}>
                                    <Typography variant="h6" sx={{ padding: 2 }}>
                                        {category === 'today' && "Today's Chats"}
                                        {category === 'yesterday' && "Yesterday's Chats"}
                                        {category === 'previous7Days' && "Previous 7 Days Chats"}
                                        {category === 'previous30Days' && "Previous 30 Days Chats"}
                                    </Typography>
                                    <List>
                                        {categorizeChat[category].map(chat => (
                                            <StyledListItem
                                                key={chat._id}
                                                disablePadding
                                                onMouseEnter={() => !isTouchDevice && setActiveChatId(chat._id)}
                                                onMouseLeave={() => !isTouchDevice && setActiveChatId(null)}
                                                onClick={(event) => handleChatItemInteraction(chat._id, event)}
                                                secondaryAction={
                                                    isMobile ? (
                                                        activeChatId === chat._id && (
                                                            <IconButton edge="end" onClick={(event) => handleMenuOpen(event, chat._id)}>
                                                                <MoreVertIcon style={{ color: '#FFFFFF' }} />
                                                            </IconButton>
                                                        )
                                                    ) : (
                                                        activeChatId === chat._id && (
                                                            <Box display="flex" gap={1}>
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        initiateEditChat(chat._id, chat.title);
                                                                    }}
                                                                >
                                                                    <EditIcon style={{ color: '#FFFFFF' }} />
                                                                </IconButton>
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleDeleteChat(chat._id); // Pass chat._id directly
                                                                    }}
                                                                >
                                                                    <DeleteIcon style={{ color: '#FFFFFF' }} />
                                                                </IconButton>




                                                            </Box>
                                                        )
                                                    )
                                                }
                                            >
                                                <ListItemButton onClick={() => {
                                                    if (isTouchDevice && activeChatId === chat._id) {
                                                        handleChatSelection(chat._id);
                                                    } else if (!isTouchDevice) {
                                                        handleChatSelection(chat._id);
                                                    }
                                                }}>
                                                    {editChatId === chat._id ? (
                                                        <TextField
                                                            value={editedTitle}
                                                            onChange={(e) => setEditedTitle(e.target.value)}
                                                            variant="standard"
                                                            fullWidth
                                                            autoFocus
                                                            onKeyDown={(e) => handleKeyDown(e, chat._id)}
                                                            onBlur={() => handleUpdateChatTitle()}
                                                            InputProps={{
                                                                style: { color: '#FFFFFF' },
                                                            }}
                                                        />
                                                    ) : (
                                                        <StyledListItemText
                                                            primary={chat?.title || chat?.messages?.[0]?.message || "No messages"}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            </StyledListItem>
                                        ))}
                                    </List>
                                    <Divider sx={{ backgroundColor: '#333333' }} />
                                </React.Fragment>
                            )
                        ))}


                        {/* No Chats Available */}
                        {historyChats.length === 0 && (
                            <Typography variant="h6" sx={{ padding: 2, textAlign: 'center' }}>No Chats Available</Typography>
                        )}
                    </Box>
                </Drawer>
            </ThemeProvider>


            {/* Track Map Dialog */}
            {isMobile && (<Dialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                fullWidth
            // Aumenta la larghezza massima

            >
                <DialogContent >
                    <IconButton
                        onClick={handleCloseDialog}
                        style={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <TrackMapChat
                        race={race}
                        driverSessions={[driverSessions]}
                        day={day}
                    />
                </DialogContent>
            </Dialog>)}


            {/* More Options Menu */}
            {isMobile && (
                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                        style: {
                            backgroundColor: '#1E1E1E',
                            color: '#FFFFFF',
                        },
                    }}
                >
                    <StyledMenuItem onClick={() => initiateEditChat(menuChatId, historyChats.find(chat => chat._id === menuChatId)?.title)}>
                        <ListItemIcon>
                            <EditIcon fontSize="small" style={{ color: '#FFFFFF' }} />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => handleDeleteChat(menuChatId)}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" style={{ color: '#FFFFFF' }} />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                    </StyledMenuItem>


                </Menu>
            )}
        </>
    );


};


export default AnchorTemporaryDrawer;


