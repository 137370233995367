import { Box, Button, Divider, Stack, Typography, Backdrop, Chip } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import HttpProvider from 'services/HttpProvider'
import PaypalProvider from '../../services/PaypalProvider';
import Pricing from './Pricing';
import UserPlanLoader from 'components/Loaders/UserPlanLoader';
import UserPlanRecap from 'components/Plans/UserPlanRecap';
import { getToken } from '../../services/PaypalHelper';
import myUserStore from '../../stores/UserStore';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

function UserPlan({ }) {
  const [isLoading, setIsLoading] = useState(false)
  const [openPricing, setOpenPricing] = useState(false)
  const [wantToCancel, setWantToCancel] = useState(false)
  const [showAll, setShowAll] = useState("")
  const history = useHistory();
  const userSubscriptionId = myUserStore.subscriptionId
  const subscription = myUserStore.subscriptionDetails || {}

  const getSubscriptionByUser = async () => {
    setIsLoading(true)
    const token = await getToken();
    if (token && userSubscriptionId) {
      return PaypalProvider.get(`billing/subscriptions/${userSubscriptionId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          const subs = res.data
          myUserStore.setUserSubscriptionDetails(subs)
          postSubscription(subs);
          return subs
        })
        .catch((err) => {
          console.error('Error getting subscription details:', err);
          return err
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  const postSubscription = async (subscriptionDetails) => {
    const apiUrl = `/post_subscription/`
    await HttpProvider.post(
      apiUrl,
      {
        subscriptionDetails
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      }
    )
      .catch(
        (error) => {
          console.error("Error!", error)
        }
      )
  };

  const cancelSubscription = async () => {
    setIsLoading(true)
    const token = await getToken();
    if (token) {
      return PaypalProvider.post(`billing/subscriptions/${userSubscriptionId}/cancel`, {
        reason: 'User requested cancellation'
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(() => {
          setWantToCancel(false);
          return getSubscriptionByUser();
         
        })
        .catch((err) => {
          console.error('Error cancelling subscription:', err);
          setWantToCancel(false);
          return err
        })
        .finally(() => {
          setIsLoading(false)
          setWantToCancel(false);
        })
    } else {
      setIsLoading(false)
      setWantToCancel(false);
    }
  };

  const downGradePlan = () => {

  }

  const isSubscriptionCanceled = useMemo(() => {
    return subscription?.status === 'CANCELLED'
  }, [subscription?.status])

  const togglePlan = (boolval = false, all) => {
    setOpenPricing(boolval)
    { all ? setShowAll("showAll") : setShowAll("") }
  }
  const myPlan = myUserStore.planCode

  return (
    <Box>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Button onClick={() => history.goBack()} variant="outlined" sx={{ color: 'blue', borderColor: 'black' }} startIcon={<ArrowBack />}>
          Go Back
        </Button>
      </Stack>
      {
        isLoading ? <UserPlanLoader times={14} /> :
          Object.keys(subscription).length > 0 ?
            <Stack spacing={4}>
              <UserPlanRecap subscriptionDetail={subscription} isCanceled={isSubscriptionCanceled} />
              <Divider />
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography sx={{ fontWeight: 'bold' }}> Cancel Plan </Typography>
                <Button onClick={() => setWantToCancel(true)} variant='outlined' color="error" disabled={isSubscriptionCanceled}> Cancel Subscription</Button>
              </Stack>
              <Backdrop
                sx={{ color: '#fff', top:-30, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={wantToCancel}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '16px',
                    padding: '20px',
                    textAlign: 'center',
                    maxWidth: '400px',
                    margin: 'auto',
                    width: '80%', // Responsive width
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: 20, sm: 24 }, // Smaller font size for smaller screens
                      margin: "15px",
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    ARE YOU SURE?
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '10px',
                      marginTop: '20px',
                      flexWrap: 'wrap', // Stack buttons on smaller screens
                    }}
                  >
                    <Chip
                     onClick={cancelSubscription}
                    // onClick={() => setWantToCancel(false) }
                      sx={{
                        width: { xs: '120px', sm: '150px' }, // Smaller width for smaller screens
                        borderRadius: '8px',
                        padding: { xs: '5px 10px', sm: '10px 20px' }, // Responsive padding
                        fontSize: { xs: '14px', sm: '16px' }, // Smaller font size for smaller screens
                        display: 'flex',
                        height: 65,
                        flexDirection: 'column', // Keep text stacked
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      label={
                        <Typography sx={{ textAlign: 'center' }}>
                          CANCEL <br /> SUBSCRIPTION
                        </Typography>
                      }
                      color='error'
                    />
                    <Chip
                      onClick={() => setWantToCancel(false)}
                      sx={{
                        width: { xs: '120px', sm: '150px' }, // Smaller width for smaller screens
                        borderRadius: '8px',
                        height: 65,
                        padding: { xs: '5px 10px', sm: '10px 20px' }, // Responsive padding
                        fontSize: { xs: '14px', sm: '16px' }, // Smaller font size for smaller screens
                      }}
                      label="BACK"
                      color='info'
                    />
                  </div>
                  <Typography
                    sx={{
                      marginTop: '15px',
                      fontSize: { xs: '12px', sm: '14px' }, // Smaller font size for smaller screens
                      color: 'gray',
                    }}
                  >
                    You will be able to continue using AiMotor until your billing month finishes.
                  </Typography>
                </div>
              </Backdrop>

              {myPlan != "Team" && (<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography sx={{ fontWeight: 'bold' }}> Update Plan </Typography>
                <Button onClick={() => togglePlan(true, false)} variant='contained'> Upgrade plan </Button>
              </Stack>)}
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => togglePlan(true, true)}
                >
                  Downgrade plan
                </Typography>
              </Stack>
            </Stack>
            :
            <Stack spacing={2} alignItems={'center'} mt={10}>
              <Typography sx={{ fontWeight: 'bold' }}> No plans found for your account </Typography>
              <Button onClick={() => togglePlan(true)} variant='outlined'> Subscribe to plan </Button>
            </Stack>
      }
      <Pricing open={openPricing} isDowngrade={true} onClose={() => togglePlan(false)} showAll={showAll} />
    </Box>
  )
}

export default observer(UserPlan);
