import { Box, Skeleton, Stack } from '@mui/material';
import { Button, Chip, ListItemIcon, ListItemText, Menu, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { useHistory } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Popover from '@mui/material/Popover';
import DistributionDialog from './DistributionDialog';
import TrackMapChat from './TrackMapChat';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Fab from '@mui/material/Fab';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import ChatIcon from '@mui/icons-material/Chat';
import ListIcon from '@mui/icons-material/List';
import ChatMessage from 'components/Chat/Message';
import ChatMessageLoader from 'components/Chat/MessageLoader';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ChatSuggestion from '../Suggestions';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AddIcon from '@mui/icons-material/Add';
import Drawer from './Drawer';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Markdown from 'marked-react';
import Paper from '@mui/material/Paper';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Pricing from './Pricing';
import LanguageIcon from '@mui/icons-material/Language';
import StarIcon from '@mui/icons-material/Star';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Writer from 'components/Chat/Writer';
import myUserStore from '../../stores/UserStore';
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import TrySubscribe from "./Try_Subscribe";
import SuggestionsList from 'components/Chat/Suggestions/SuggestionsList';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { truncate } from 'lodash';




const chatTypes = [
    {
        title: 'AiMotor 1',
        description: 'Great for Personal Space',
        type: 'normal',
        icon: <FlashOnIcon fontSize="small" />,
        activeFor: ['Growth', 'Pro', 'Team'],
        userPlan: ['Growth'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day: 1,
            day_team: 1,
        },
        code: 'aimotor_1'
    },
    {
        title: 'AiMotor 2',
        description: 'Advanced model for Racing Teams',
        type: 'advanced',
        icon: <StarIcon fontSize="small" />,
        activeFor: ['Pro', 'Team'],
        userPlan: ['Growth', 'Pro', 'Team'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day: 1,
            day_team: 1,
        },
        code: 'aimotor_2'
    }
]


const Chat = ({ collapsed }) => {
    const [chatSuggestions, setChatSuggestions] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [internalEventSource, setInternalEventSource] = useState(null);
    const [allChatMessages, setAllChatMessages] = useState({});
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [openPricing, setOpenPricing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUserScrolledUp, setIsUserScrolledUp] = useState(false);
    const user = JSON.parse(localStorage.getItem("user_data") || "{}");
    const [chatType, setChatType] = useState(chatTypes[0])
    const selectedTeam = myUserStore.getSelectedTeam;
    const showPersonalButton = myUserStore.isPersonalSpace;
    const userPlan = myUserStore?.subscriptionDetails?.custom_id
    const [isDialogOpen, setIsDialogOpen] = useState(false); // Stato per Track Map Dialog
    const [openDistribution, setOpenDistribution] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isResponseStreaming, setIsResponseStreaming] = useState(false);  // New state to manage response streaming
    const [incomingFragments, setIncomingFragments] = useState([]); // Frammenti da scrivere
    const [messageText, setMessageText] = useState('');
    const [showChipSuggestions, setShowChipSuggestions] = useState(true);
    const history = useHistory();
    const [anchorElDropdown, setAnchorElDropdown] = useState(null);
    const [openChat, setOpenChat] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('en')
    const [dataset, setDataset] = useState({});
    const [isMessage, setIsMessage] = useState(false);

    const [newMemory, setNewMemory] = useState('');
    const [showScrollFab, setShowScrollFab] = useState(false);
    const [selection, setSelection] = useState("3");

    const toggleSelection = (value) => {
        setSelection((prev) => {
            let newSelection;
            if (prev === "3") {
                newSelection = value.toString(); // Set new value
            } else if (prev === "1" && value === 0) {
                newSelection = "2"; // Add Setup to Telemetry
            } else if (prev === "0" && value === 1) {
                newSelection = "2"; // Add Telemetry to Setup
            } else if (prev === "2" && value === 0) {
                newSelection = "1"; // Remove Setup
            } else if (prev === "2" && value === 1) {
                newSelection = "0"; // Remove Telemetry
            } else {
                newSelection = "3"; // Reset to default
            }
            
            setUserChoice(newSelection); // Call the API to save the choice
            return newSelection; // Update state
        });
    };


    const languageOptions = [
        { code: 'en', label: 'English', flag: '🇺🇸' },
        { code: 'it', label: 'Italiano', flag: '🇮🇹' },
        { code: 'fr', label: 'Français', flag: '🇫🇷' },
        { code: 'de', label: 'Deutsch', flag: '🇩🇪' },
        { code: 'es', label: 'Español', flag: '🇪🇸' },
        { code: 'pt', label: 'Português', flag: '🇵🇹' },
        { code: 'ru', label: 'Русский', flag: '🇷🇺' },
        { code: 'zh', label: '中文', flag: '🇨🇳' },
        { code: 'ja', label: '日本語', flag: '🇯🇵' },
        { code: 'ar', label: 'العربية', flag: '🇸🇦' },
        { code: 'hi', label: 'हिन्दी', flag: '🇮🇳' },
        { code: 'ko', label: '한국어', flag: '🇰🇷' },
        { code: 'tr', label: 'Türkçe', flag: '🇹🇷' },
        { code: 'nl', label: 'Nederlands', flag: '🇳🇱' },
        { code: 'pl', label: 'Polski', flag: '🇵🇱' },
        { code: 'sv', label: 'Svenska', flag: '🇸🇪' },
        { code: 'fi', label: 'Suomi', flag: '🇫🇮' },
        { code: 'no', label: 'Norsk', flag: '🇳🇴' },
        { code: 'da', label: 'Dansk', flag: '🇩🇰' },
        { code: 'cs', label: 'Čeština', flag: '🇨🇿' },
        { code: 'el', label: 'Ελληνικά', flag: '🇬🇷' },
        { code: 'he', label: 'עברית', flag: '🇮🇱' },
        { code: 'vi', label: 'Tiếng Việt', flag: '🇻🇳' },
        { code: 'th', label: 'ภาษาไทย', flag: '🇹🇭' },
        { code: 'ms', label: 'Bahasa Melayu', flag: '🇲🇾' },
        { code: 'id', label: 'Bahasa Indonesia', flag: '🇮🇩' },
    ];





    const handleAttachClick = (event) => {
        setAnchorElDropdown(event.currentTarget);
    };

    const handleCloseDropdown = () => {
        setAnchorElDropdown(null);
    };

    const isDropdownOpen = Boolean(anchorElDropdown);




    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };


        window.addEventListener('resize', handleResize);
        handleResize();


        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const chatContainerRef = useRef(null);


    const getChatByPlan = (userPlan) => {
        const getChatToBeSeleced = chatTypes.find(c => c.userPlan.includes(userPlan))
        if (getChatToBeSeleced) {
            setChatType(getChatToBeSeleced)
        } else {
            setChatType(chatTypes[0])
        }
    }


    const handleMenuItemClick = (model) => {
        const getChatToBeSeleced = chatTypes.find(c => c.title === model)


        if (getChatToBeSeleced) {
            const canSelect = getChatToBeSeleced.activeFor.includes(userPlan)
            if (canSelect) {
                setChatType(getChatToBeSeleced)
                handleClose();
            } else {
                setOpenPricing(true)
            }
        }
    };


    useEffect(() => {
        if (!selectedChatId) {


            handleLatestChat()
        } else {
            getChatById(selectedChatId);
        }
    }, [selectedChatId]);


    useEffect(() => {
        getChatByPlan(userPlan)
    }, [userPlan])


    const getChatById = async (chatId) => {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/get_conversations/?chat_id=${chatId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
                "Accept": "application/json",
                "Cache-Control": "no-cache",
                "Pragma": "no-cache",
            }


        });
        const data = await response.json();
        setAllChatMessages(prevMessages => ({ ...prevMessages, [chatId]: { name: '', messages: data.messages, createdAt: new Date() } }));
        return data;
    };


    const generateChatId = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/create_conversation`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        const data = await response.json();
        const conversation_id = data.conversation_id;
        return conversation_id;
    };


    const getlatestchat = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/get_last_conversation/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        let data
        if (response?.ok) {
            data = await response.json();
        } else {
            data = { _id: await generateChatId() }
        }
        return data;
    };


    const saveLatestSystemMessage = (message, role = "system", updated_at = dayjs().toISOString()) => {
        setAllChatMessages(prevMessages => {
            const updatedChat = prevMessages[selectedChatId];
            if (!updatedChat) return prevMessages;
            const updatedMessages = [...updatedChat.messages, { message: message, role, updated_at }];
            return {
                ...prevMessages,
                [selectedChatId]: { ...updatedChat, messages: updatedMessages }
            };
        });
    };

    console.log('chatType', chatType.title)
    useEffect(() => {
        if (isDropdownOpen) {  // o la condizione con cui rilevi l'apertura
            setIsLoading(true);
            axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/get_memory`, {
                    params: {
                        user_email: user.email,
                        team_id: selectedTeam.id,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        accept: "application/json",
                    },
                })
                .then((res) => {
                    setDataset(res.data);
                    setNewMemory(res.data?.memory || "");
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error("Axios Error:", error);
                    setIsLoading(false);
                });
        }
    }, [isDropdownOpen, selectedTeam]);

    // 1) Quando si apre il Popover, chiama l’endpoint GET e imposta la lingua corrente
    useEffect(() => {
        // o qualunque logica usi per verificare che il Popover sia aperto
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_language/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                accept: "application/json",
            },
        })
            .then((res) => {
                // Supponendo che l'endpoint restituisca { "language": "en" }
                setSelectedLanguage(res.data?.language || 'en');
            })
            .catch((error) => {
                console.error("GET language error:", error);
            });

    }, [, anchorElDropdown]);

    // 2) Quando selezioni una lingua nuova, chiama l’endpoint POST per salvarla
    const handlelanguagePost = (langCode) => {
        setSelectedLanguage(langCode);

        axios.post(`${process.env.REACT_APP_API_DOMAIN}/set_language/?language=${langCode}`,
            {},  // body
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    accept: "application/json",
                },
            }
        )
            .then(() => {
                console.log(`Lingua salvata: ${langCode}`);
            })
            .catch((error) => {
                console.error("POST set_language error:", error);
            });
    };
console.log("all chat", allChatMessages)

    const handleLanguageChange = (langCode) => {
        setSelectedLanguage(langCode);
        handlelanguagePost(langCode)

        const foundLang = languageOptions.find((lang) => lang.code === langCode);
        const languageName = foundLang ? foundLang.label : langCode;

        // 1. Leggo la memoria attuale (se non esiste, uso stringa vuota)
        debugger
        let currentMemory = dataset?.memory || [];

        // 2. Pattern per catturare "SPEAK TO ME ONLY IN Xxxx"
        const pattern = /SPEAK TO ME ONLY IN [^\n]+/;

        let updatedMemory = "";
        if (pattern.test(currentMemory)) {
            // Se esiste già, sostituisco solo quella parte
            updatedMemory = currentMemory?.replace(
                pattern,
                `SPEAK TO ME ONLY IN ${languageName}`
            );
        } else {
            // Altrimenti la aggiungo, per esempio, a fine testo
            // Volendo puoi anche metterla in testa
            if (currentMemory?.length > 0) updatedMemory = currentMemory?.trim();
            if (updatedMemory && currentMemory?.length > 0) updatedMemory += "\n\n";  // Aggiunge uno spazio se c'era già testo
            updatedMemory += `SPEAK TO ME ONLY IN ${languageName}`;
        }

        // Aggiorno lo stato locale
        setNewMemory(updatedMemory);

        // 3. Chiamata all'endpoint di update_memory
        axios
            .put(
                `${process.env.REACT_APP_API_DOMAIN}/update_memory?user_email=${user.email}&new_memory=${encodeURIComponent(updatedMemory)}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        accept: "application/json",
                    },
                }
            )
            .then(() => {
                // Aggiorno localmente il dataset
                setDataset((prev) => ({ ...prev, memory: updatedMemory }));
            })
            .catch((error) => {
                console.error("Axios Error:", error);
            });
    };







    const handleMessageComplete = (message, role) => {
        saveLatestSystemMessage(message)
        setIncomingFragments([])
        setIsResponseStreaming(false)
    }
    useEffect(() => {
        // Fetch initial user choice when the component mounts
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_user_choice/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                accept: "application/json",
            },
        })
            .then((res) => {
                setSelection(res.data?.choice || "3"); // Default to "3" if no choice is set
            })
            .catch((error) => {
                console.error("GET user choice error:", error);
            });
    }, []);

    const setUserChoice = (newChoice) => {
        axios.post(`${process.env.REACT_APP_API_DOMAIN}/set_user_choice/`, null, {
            params: { choice: newChoice },
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                accept: "application/json",
            },
        })
            .then(() => {
                console.log(`User choice set to: ${newChoice}`);
            })
            .catch((error) => {
                console.error("POST set user choice error:", error);
            });
    };

    const handleMessageSubmit = async (message) => {
        // 1. If the user’s message is empty, do nothing
        if (!message.trim()) {
            return;
        }

        // 2. If there's no chatId yet, create one now (the user is actually sending content)
        let chatId = selectedChatId;
        if (!chatId) {
            chatId = await generateChatId();
            setSelectedChatId(chatId);

            // Set up a local placeholder so we have a spot to store this chat’s messages in state
            setAllChatMessages((prev) => ({
                ...prev,
                [chatId]: { name: '', messages: [], createdAt: new Date() },
            }));
        }

        setIsLoading(true);
        const encodedMessage = encodeURIComponent(message);
        saveLatestSystemMessage(message, "user")
        setIsResponseStreaming(true); 


        // Add user message to state
        try {
            const eventSource = new EventSource(`${process.env.REACT_APP_API_DOMAIN}/${chatType?.code}?request=${encodedMessage}&conversation_id=${selectedChatId}&choice=${selection}&team_id=${selectedTeam.id}&day=${chatType.queryParams.day}&day_team=${chatType.queryParams.day_team}&personal_retrieval_limit=${chatType.queryParams.personal_retrieval_limit}&team_retrieval_limit=${chatType.queryParams.team_retrieval_limit}&token=${localStorage.getItem("access_token")}`,
                {


                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,




                }
            );
            setInternalEventSource(eventSource)




            eventSource.onmessage = async (event) => {
                setIsLoading(false);
                if (event.data === "[DONE]") {
                    // connection is completed
                    setIncomingFragments(prev => [...prev, JSON.parse('"[DONE]"')]);
                    eventSource.close();
                } else {
                    setIncomingFragments(prev => [...prev, JSON.parse(event.data)]);
                }
                return event;
            };


            eventSource.onerror = (err) => {
                eventSource.close();
                setIsLoading(false)
            };


        } catch (error) {
            setIsLoading(false);
            setIsResponseStreaming(false);
            toast.error("Error while generating message, please try again", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
            console.error("Error querying LLM:", error);
        }
    };


    const ScrollChatBottom = useCallback(() => {

        if (!isUserScrolledUp) { // Solo se l'utente non ha scrolled up manualmente
            const anchorEl = chatContainerRef.current;

            if (anchorEl) {
                anchorEl.scrollTop = anchorEl.scrollHeight - anchorEl.clientHeight;
            }
        }
    }, [allChatMessages?.messages?.length, chatContainerRef]);



    const handleNewChat = async () => {
        // //    const newChatId = await generateChatId();
        // //    setAllChatMessages(prevMessages => ({ ...prevMessages, [newChatId]: { name: '', messages: [], createdAt: new Date() } }));
        // //    setSelectedChatId(newChatId);
        // // We do NOT create the conversation here.
        // setSelectedChatId(null);
        // // Possibly clear out message text, etc.
        // setMessageText('');

        // Actually create a new chat and select it
        const newChatId = await generateChatId();
        setAllChatMessages(prevMessages => ({
            ...prevMessages,
            [newChatId]: { name: '', messages: [], createdAt: new Date() },
        }));
        setSelectedChatId(newChatId);

        setMessageText('');
    };


    const handleLatestChat = async () => {
        const { _id, messages = [] } = await getlatestchat()
        setAllChatMessages(prevMessages => ({ ...prevMessages, [_id]: { name: '', messages, createdAt: new Date() } }));
        setSelectedChatId(_id);
        ScrollChatBottom(3000);
    };

    const trackClick =  (e) => {
        setIsMessage(e)
        
        debugger
    };


    const onMessageEdit = async (newMessage, updated_at) => {
        const timestamp = dayjs(updated_at).toISOString();  // Ensure ISO 8601 format
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/edit_conversation?timestamp=${timestamp}&conversation_id=${selectedChatId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setAllChatMessages(prevMessages => {
                    const updatedChat = prevMessages[selectedChatId];
                    if (!updatedChat) return prevMessages;
                    return {
                        ...prevMessages,
                        [selectedChatId]: { ...updatedChat, messages: res.messages }
                    };
                });
                handleMessageSubmit(newMessage)
            })
            .catch((error) => {
                console.error("Error editing conversation:", error);
            });
    };


    const showSuggestionsByChip = (suggestion) => {
        // set message to add into searchbar -> helpText
        setMessageText(suggestion.helpText)
        setChatSuggestions(suggestion.suggestions)
        setShowChipSuggestions(false)
    };


    const handleSuggestionClick = (message) => {
        setMessageText('')
        setChatSuggestions([])
        handleMessageSubmit(message)


    };


    const suggestionsRef = useRef(null);


    // Close suggestions when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                setChatSuggestions([]);
                setShowChipSuggestions(true)// Clear the list


                //setMessageText('');
            }
        };


        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleStopChat = () => {
        internalEventSource?.close(); // Close the EventSource connection


        // Call the interrupt_stream endpoint to signal the backend to stop processing
        fetch(`${process.env.REACT_APP_API_DOMAIN}/interrupt_stream?conversation_id=${selectedChatId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log('Stream interruption requested:', data);
            })
            .catch(error => {
                console.error('Error requesting stream interruption:', error);
            });


        // Assemble the partial response from incomingFragments
        const partialMessage = incomingFragments.join('');


        // Save the partial response
        handleMessageComplete(partialMessage, 'system');


        setIsResponseStreaming(false);
        setIsLoading(false); // Update streaming state
    };


    useEffect(() => {
        const container = chatContainerRef.current;
        if (!container) return;

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = container;
            const isUserFarFromBottom = (scrollHeight - scrollTop - clientHeight) > 10; // Soglia di 10px
            const isUserScrolledUp = (scrollHeight - scrollTop - clientHeight) > 100; // Soglia di 10px

            setShowScrollFab(isUserFarFromBottom);

            if (isUserScrolledUp) {
                setIsUserScrolledUp(false); // L'utente ha scrolled up
            } else {
                setIsUserScrolledUp(false); // L'utente è in fondo
            }
        };

        container.addEventListener('scroll', handleScroll);

        // Pulizia
        return () => container.removeEventListener('scroll', handleScroll);
    }, []);


    const handleScrollToBottom = () => {
        if (!chatContainerRef.current) return;
        chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: 'smooth',
        });
        setShowScrollFab(false);
    };



    const handleSelectPreviousChat = (chatId) => setSelectedChatId(chatId);

    useEffect(() => {
        ScrollChatBottom();
    }, [ScrollChatBottom]);

    useEffect(() => {
        if (selectedChatId && chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [selectedChatId, allChatMessages])


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    console.log("conv id:", selectedChatId)
    console.log("token:", localStorage.getItem("access_token"))
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isContained, setIsContained] = useState(false);

    const handleChipClick = () => {
        setIsContained((prev) => !prev); // Cambia lo stato tra 'outlined' e 'contained'
        handleNewChat(); // Chiamata alla funzione
    };
    return (
        <>



            <DistributionDialog open={openDistribution} chatType={chatType.title} onClose={() => setOpenDistribution(false)} />
            <TrySubscribe open={dialogOpen} onClose={() => setDialogOpen(false)} />
            <Box className="chat-wrapper" container={true} justifyContent="center">
                {showScrollFab && (
                    <Fab
                        onClick={handleScrollToBottom}
                        size="small"
                        sx={{
                            position: 'fixed',
                            bottom: 175,              // alza il bottone
                            left: collapsed ? '50%' : '55%',            // centrato orizzontalmente
                            transform: 'translateX(-50%)',
                            backgroundColor: '#0D47A1',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#1565C0'
                            },
                            zIndex: 9999            // sopra altri elementi
                        }}
                    >
                        <ArrowDownwardIcon />
                    </Fab>
                )}
                <Box sx={{ position: 'absolute', top: '16px', left: '16px', display: 'flex', alignItems: 'center' }}>
                    <Button
                        aria-controls={open ? 'version-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        variant="text"
                        sx={{
                            color: '#0D47A1',
                            textTransform: 'none',
                            fontSize: {
                                xs: '20px',  // for mobile devices
                                sm: '22px',  // for tablets and above
                            },
                            fontWeight: 'bold',
                        }}
                    >
                        {chatType.title}
                        <ArrowDropDownIcon />
                    </Button>


                    <Menu
                        id="version-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ mt: 1 }}
                    >
                        {chatTypes.map((type) => (
                            <MenuItem key={type.title} onClick={() => handleMenuItemClick(type.title)}>
                                <ListItemIcon>
                                    {type.icon}
                                </ListItemIcon>
                                <ListItemText primary={type.title} secondary={type.description} />
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>


                <Box sx={{ height: "100%", width: '100%', pt: 10 }} display={'flex'} flexDirection={'column'} mx={'auto'}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ flex: '1', display: selectedChatId && allChatMessages[selectedChatId]?.messages?.length ? 'none' : 'flex' }}>
                        {showChipSuggestions && (<ChatSuggestion selectedLanguage={selectedLanguage} onClick={showSuggestionsByChip} sx={{ maxWidth: 800 }} />)}
                    </Stack>
                    {/* <div id="chat-container" style={{ flex: '1', overflowY: 'auto', textAlign: 'center', display: selectedChatId && allChatMessages[selectedChatId]?.messages?.length ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>
                       <Box>
                          
                       </Box>
                   </div> */}


                    <div
                        style={{ flex: '1', overflowY: 'auto', display: selectedChatId && allChatMessages[selectedChatId]?.messages?.length ? 'block' : 'none' }}
                        ref={chatContainerRef}
                    >
                        <Box sx={{ maxWidth: '800px', width: '100%', margin: '0 auto', position: "relative" }}>
                            {(allChatMessages[selectedChatId]?.messages || []).map((msg, index) => (
                                <div key={index} style={{ marginBottom: '10px', position: "relative", width: '100%' }}>
                                    <ChatMessage  isLast={index === allChatMessages[selectedChatId]?.messages.length - 1} owner={msg.role === "user"} trackClick={trackClick} message={msg.message} onMessageEdit={onMessageEdit} updated_at={msg.updated_at} />
                                </div>
                            ))}


                            {isLoading && (
                                <ChatMessageLoader />
                            )}
                            {/* Show the streaming message while loading */}
                            {isResponseStreaming && !isLoading && (
                                <div style={{ marginBottom: '10px' }}>
                                    <Writer
                                        incomingFragments={incomingFragments}
                                        setIncomingFragments={setIncomingFragments}
                                        onComplete={(message) => handleMessageComplete(message)}
                                        scrollChat={ScrollChatBottom}
                                    />
                                </div>
                            )}
                        </Box>
                    </div>
                    <div ref={suggestionsRef} style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                        <SuggestionsList list={chatSuggestions} onClick={handleSuggestionClick} />
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            const message = e.target.elements.message.value;
                            if (message.trim() !== '') {
                                handleMessageSubmit(message);
                                e.target.reset();
                                setMessageText(''); // Reset the input state
                            }
                        }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            bottom: 0,
                            padding: '10px',
                            zIndex: 2,
                            width: '100%',
                            maxWidth: '800px',
                            margin: '0 auto',
                        }}
                    >
                        <Paper
                            sx={{
                                p: '7px',
                                borderRadius: '16px',
                                display: 'flex',
                                flexDirection: "column",
                                alignItems: 'center',
                                width: '100%',
                                backgroundColor: 'whitesmoke',

                            }}
                        >
                            <TextField
                                fullWidth
                                placeholder="Message AiMotor"
                                variant="standard"
                                name="message"
                                value={messageText}
                                multiline
                                InputLabelProps={{
                                    style: { color: 'black', textAlign: 'center', width: '100%' },
                                    shrink: true,
                                }}
                                InputProps={{
                                    style: { color: 'black', textAlign: 'center' },
                                    disableUnderline: true,
                                }}
                                sx={{ textAlign: 'center', minHeight: 45 }}
                                onChange={(e) => setMessageText(e.target.value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                {/* Chat button */}
                                <div style={{ display: 'flex', gap: 10 }}>


                                    <Tooltip title="Start a New Chat">
                                        <IconButton
                                            onClick={handleNewChat}
                                            sx={{
                                                color: '#0D47A1',
                                                backgroundColor: 'transparent',
                                                padding: '10px',
                                                width: '35px',
                                                height: '35px'
                                            }}
                                        >
                                            <AddCommentIcon sx={{ fontSize: '25px' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <IconButton
                                        sx={{
                                            color: '#0D47A1', // Colore grigio
                                            backgroundColor: 'transparent', // Rimuove sfondo
                                            padding: '10px',
                                            width: '35px',
                                            height: '35px'
                                        }}
                                        onClick={handleAttachClick}
                                    >
                                        <ListIcon sx={{ fontSize: '25px' }} />
                                    </IconButton>
                                    <Popover
                                        open={isDropdownOpen}
                                        anchorEl={anchorElDropdown}
                                        onClose={handleCloseDropdown}
                                        anchorOrigin={{
                                            vertical: 'top', // Posizionamento sopra rispetto all'ancoraggio
                                            horizontal: 'center', // Centra orizzontalmente rispetto all'ancoraggio
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom', // L'origine parte dal basso del popover
                                            horizontal: 'center',
                                        }}
                                        PaperProps={{
                                            sx: {
                                                borderRadius: '16px', // Bordi arrotondati
                                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Ombra delicata
                                                padding: '10px', // Spaziatura interna
                                                backgroundColor: 'whitesmoke', // Colore di sfondo bianco
                                                marginTop: '-20px', // Aumenta la distanza per spostarlo sopra
                                            },
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            <Button
                                                startIcon={<AttachFileIcon />}
                                                onClick={() => history.push('/racing-space/telemetry-data')}
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    color: '#0D47A1', // Testo nero
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5', // Colore hover più chiaro
                                                    },
                                                }}
                                            >
                                                Insert Telemetry
                                            </Button>
                                            <Button
                                                startIcon={<AddIcon />}
                                                onClick={() => history.push('/racing-space/racing-data')}
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    color: '#0D47A1', // Testo nero
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5', // Colore hover più chiaro
                                                    },
                                                }}
                                            >
                                                Insert Setup
                                            </Button>
                                            <Button
                                                startIcon={<MyLocationIcon />} // Icona "Track Map"
                                                onClick={() => setOpenChat(true)} // Mostra dialogo Track Map
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    color: '#0D47A1', // Testo nero
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5', // Colore hover più chiaro
                                                    },
                                                }}
                                            >
                                                Track Map
                                            </Button>
                                            <Button
                                                startIcon={<AutoGraphIcon />} // Icona "Data Distribution"
                                                onClick={() => setOpenDistribution(true)} // Mostra dialogo Data Distribution
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    color: '#0D47A1', // Testo nero
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5', // Colore hover più chiaro
                                                    },
                                                }}
                                            >
                                                Data Distribution
                                            </Button>
                                            <Button
                                                startIcon={<ChatIcon />} // Icona Chat
                                                onClick={() => {
                                                    setIsDrawerOpen(true); // Apri il drawer
                                                    setAnchorElDropdown(false); // Chiudi il dropdown
                                                }} // Logica per aprire il drawer
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    color: '#0D47A1', // Testo nero
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5', // Colore hover più chiaro
                                                    },
                                                }}
                                            >
                                                Previous chat history
                                            </Button>
                                            {/* Selettore delle lingue */}
                                            <FormControl fullWidth
                                                sx={{
                                                    '& .MuiInputLabel-root': {
                                                        color: '#0D47A1',               // Etichetta blu
                                                    },
                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                        color: '#0D47A1',              // Etichetta blu quando in focus
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#0D47A1',        // Bordo blu iniziale
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#0D47A1',        // Bordo blu al passaggio mouse
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#0D47A1',        // Bordo blu quando in focus
                                                    },
                                                    borderRadius: '8px',             // Arrotondi gli angoli
                                                }}>
                                                <InputLabel id="language-select-label">Language</InputLabel>
                                                <Select
                                                    labelId="language-select-label"
                                                    value={selectedLanguage}                // <-- Deve mostrare la lingua selezionata
                                                    label="Language"
                                                    onChange={(e) => handleLanguageChange(e.target.value)} // <-- Aggiorna lo state
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200, // Altezza massima per lo scroll
                                                            },
                                                        },
                                                    }}
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        borderRadius: '8px',
                                                        fontFamily: 'Arial, sans-serif', // Necessario per le emoji
                                                    }}
                                                >
                                                    {languageOptions.map((lang) => (
                                                        <MenuItem key={lang.code} value={lang.code}>
                                                            {lang.flag} {lang.label} {/* Emoji + Nome lingua */}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Box>
                                    </Popover>





                                    {/* NEW "Start a new chat" button */}


                                </div>


                                {/* Bottom-right buttons (Send / Stop, etc.) */}
                                <div style={{ display: 'flex', gap: 10 }}>

                                    {/* <Tooltip title="Start a New Chat">
                                        <Chip
                                            onClick={handleChipClick}
                                            variant={isContained ? 'contained' : 'outlined'} // Cambia variante
                                            sx={{
                                                color: isContained ? 'white' : '#0D47A1',

                                                backgroundColor: isContained ? '#0D47A1' : 'transparent',
                                                borderColor: '#0D47A1',
                                                '&:hover': {
                                                    backgroundColor: isContained ? '#0D47A1' : '#e3f2fd',
                                                },
                                                '& .MuiChip-icon': {
                                                    color: isContained ? 'white' : '#0D47A1', // Colore icona in base allo stato
                                                },
                                            }}
                                            label="Search"
                                            icon={<ManageSearchIcon
                                                sx={{
                                                    fontSize: '25px',
                                                    color: isContained ? 'white' : '#0D47A1', // Bianco se contained, blu se outlined
                                                }}
                                            />}
                                        />
                                    </Tooltip> */}
                                    <Chip
                                        label="Telemetrist"
                                        clickable
                                        icon={<SignalCellularAltIcon />}
                                        variant={selection === "1" || selection === "2" ? "contained" : "outlined"}
                                        sx={{
                                            color: selection === "1" || selection === "2" ? "white" : "#0D47A1", // Colore del testo
                                            backgroundColor: selection === "1" || selection === "2" ? "#0D47A1" : "transparent", // Colore di sfondo
                                            borderColor: "#0D47A1", // Colore del bordo
                                            '& .MuiChip-icon': {
                                                color: selection === "1" || selection === "2" ? "white" : "#0D47A1", // Cambia colore dell'icona dinamicamente
                                            },
                                            '&:hover': {
                                                backgroundColor: selection === "1" || selection === "2" ? "#0D47A1" : "#e3f2fd", // Hover
                                            },
                                        }}
                                        onClick={() => toggleSelection(1)}
                                    />

                                    <Chip
                                        label="Setup"
                                        clickable
                                        icon={<TireRepairIcon />}
                                        variant={selection === "0" || selection === "2" ? "contained" : "outlined"}
                                        sx={{
                                            color: selection === "0" || selection === "2" ? "white" : "#0D47A1", // Colore del testo
                                            backgroundColor: selection === "0" || selection === "2" ? "#0D47A1" : "transparent", // Colore di sfondo
                                            borderColor: "#0D47A1", // Colore del bordo
                                            '& .MuiChip-icon': {
                                                color: selection === "0" || selection === "2" ? "white" : "#0D47A1", // Cambia colore dell'icona dinamicamente
                                            },
                                            '&:hover': {
                                                backgroundColor: selection === "0" || selection === "2" ? "#0D47A1" : "#e3f2fd", // Hover
                                            },
                                        }}
                                        onClick={() => toggleSelection(0)}
                                    />


                                    <IconButton
                                        type={isResponseStreaming ? 'button' : 'submit'}
                                        onClick={isResponseStreaming ? handleStopChat : undefined}
                                        style={{
                                            minWidth: 'auto',
                                            backgroundColor: isResponseStreaming
                                                ? '#0D47A1' // Blue for stop
                                                : messageText.trim() !== ''
                                                    ? '#0D47A1' // Blue for send action
                                                    : 'transparent',
                                            borderRadius: '50%',
                                            padding: '10px',
                                            width: '35px',
                                            height: '35px'
                                        }}
                                    >
                                        {isResponseStreaming ? (
                                            <StopCircleIcon style={{ color: 'white' }} />
                                        ) : (
                                            <ArrowUpwardIcon
                                                style={{ color: messageText.trim() !== '' ? 'white' : 'gray' }}
                                            />
                                        )}
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>




                    </form>


                    {isMobile && (
                        <Chip
                            label={showPersonalButton ? 'Personal Space' : selectedTeam.name}
                            sx={{
                                display: 'block',
                                margin: '0 auto',
                                textAlign: 'center',
                                color: 'black',
                                backgroundColor: '#whitesmoke',
                                padding: '5px 5px',
                                borderRadius: '22px',
                                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                            }}
                        />
                    )}
                </Box>


                <Drawer
                    onNewChat={handleNewChat}
                    handleChatSelection={handleSelectPreviousChat}
                    isOpen={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    openChat={openChat}
                    setOpenChat={setOpenChat}
                    trackClick={trackClick}
                    isMessage={isMessage}
                />


                <Pricing open={openPricing} onClose={() => setOpenPricing(false)} />
            </Box >
        </>
    )
};


export default Chat;


