import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery, useTheme, Popover, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import myUserStore from "../../stores/UserStore";
import { observer } from "mobx-react-lite";
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';

function Memory({ open, onClose }) {
  const selectedTeam = myUserStore.getSelectedTeam;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataset, setDataset] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [newMemory, setNewMemory] = useState("");
  const [anchorEl, setAnchorEl] = useState(null); // Per gestire il Popover
  const user = JSON.parse(localStorage.getItem("user_data") || "{}");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_memory`, {
        params: {
          user_email: user.email,
          team_id: selectedTeam.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        setDataset(res.data);
        setNewMemory(res.data?.memory || ""); // Pre-imposta il testo esistente
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        setIsLoading(false);
      });
  }, [selectedTeam, open]);

  const handleSave = () => {
    setIsLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_API_DOMAIN}/update_memory?user_email=${user.email}&new_memory=${newMemory}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        setDataset({ ...dataset, memory: newMemory }); // Aggiorna localmente il dataset

      })
      .catch((error) => {
        console.error("Axios Error:", error);
        setIsLoading(false);
      });
    setIsEditing(false); // Disabilita la modifica
    setIsLoading(false);
  };

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget); // Mostra il Popover
  };

  const handleInfoClose = () => {
    setAnchorEl(null); // Nasconde il Popover
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxHeight: "90vh",
          padding: isMobile ? "0px" : "20px",
          borderRadius: "10px",
          backgroundColor: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", color: "#000000" }}>
        Memory
        <InfoIcon
          sx={{ marginLeft: 1, cursor: "pointer", color: "gray" }}
          onClick={handleInfoClick}
        />
      </DialogTitle>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleInfoClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: 2, maxWidth: "300px" }}>
          <Typography variant={isMobile ? "body2" : "body1"} sx={{ fontSize: isMobile ? "0.85rem" : "1rem", lineHeight: "1.5" }}>
            <b>AiMotor</b> is designed to become increasingly helpful by learning from your inputs, preferences, and feedback. You can guide its memory or clarify details effortlessly through natural conversation. Here’s how:
            <br />
            <br />
            <b>Teach AiMotor Something New:</b>
            <br />- "Remember that I like concise explanations."
            <br />- "I recently adopted a puppy!"
            <br />
            <br />
            <b>Check What AiMotor Remembers:</b>
            <br />- "What do you remember about me?"
            <br />- "Where did we leave off on my last project?"
            <br />
            <br />
            <b>Edit AiMotor's Memory Directly:</b>
            <br /> Use the edit feature below to update or write new details into AiMotor's memory yourself. This ensures your preferences are always reflected in its responses.
          </Typography>
        </Box>
      </Popover>
      <DialogContent
        sx={{
          minHeight: "200px", // Altezza minima
          maxHeight: "400px", // Altezza massima (opzionale)
          overflowY: "auto", // Scorrimento verticale
        }}
      >
        {isEditing ? (
          <TextField
            fullWidth
            multiline
            rows={4}
            value={newMemory}
            onChange={(e) => setNewMemory(e.target.value)}
            variant="outlined"
          />
        ) : dataset?.memory ? (
          <Typography>{dataset?.memory}</Typography>
        ) : (
          <Typography sx={{ color: "gray", fontStyle: "italic" }}>
            There is no memory yet!
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Chip
          label="Edit"
          color="default"
          icon={<EditIcon />}
          onClick={() => setIsEditing(true)}
          sx={{ alignSelf: "flex-start", paddingBlock: 2 }}
        />
        {isEditing && (
          <Chip
            label="Save"
            color="primary"
            onClick={handleSave}
            sx={{ marginLeft: "auto" }}
          />
        )}
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(Memory);
