import { makeAutoObservable, set } from "mobx"

import localforage from "localforage"
import { makePersistable } from "mobx-persist-store"

const isBrowser = typeof window !== "undefined"
class UserStore {
    selectedTeam = {}
    userPlan = {}
    userLoggedIn = true

    constructor() {
        makeAutoObservable(this)
        makePersistable(this, {
            name: "MyUserStore",
            properties: ["selectedTeam", "userLoggedIn", "userPlan"],
            storage: isBrowser ? localforage : undefined,
        },
            { delay: 50, fireImmediately: true })
    }

    setIsLoggedIn = (isLogged) => {
        this.userLoggedIn = isLogged;
    }

    setSelectedTeam = (selectedTeam) => {
        this.selectedTeam = selectedTeam;
    }

    setUserPlanId = (planId) => {
        set(this.userPlan, 'planId', planId)
    }

    setPlanCode = (plan) => {
        set(this.planCode, 'plan', plan)
    }
    setStopUpload = (data) => {
        this.data = data;
    };

    setUserSubscriptionDetails = (subscriptionDetails) => {
        set(this.userPlan, 'subscriptionDetails', subscriptionDetails)
    }

    resetUserSubscription = () => {
        this.userPlan = {}
    }

    get isLoggedIn() {
        return this.userLoggedIn
    }

    get getUserPlanId() {
        return this.userPlan
    }

    get getSelectedTeam() {
        return this.selectedTeam
    }
    get getStopUpload () {
        return this.data
    }

    get isPersonalSpace() {
        return !!this.selectedTeam?.name?.startsWith("personal-");
    }

    get subscriptionId() {
        return this.userPlan?.subscriptionDetails?.id
    }

    get planId() {
        return this.userPlan?.planId
    }

    get subscriptionDetails() {
        return this.userPlan?.subscriptionDetails
    }

    get planCode() {
        return this.userPlan?.subscriptionDetails?.custom_id || ''
    }

    get status() {
        return this.userPlan?.subscriptionDetails?.status || ''
    }


}
const myUserStore = new UserStore()
export default myUserStore;