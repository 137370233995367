import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import PropTypes from 'prop-types';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Menu,
  OutlinedInput,
  Popover,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import ArchiveIcon from '@mui/icons-material/Archive';
import Autocomplete from '@mui/material/Autocomplete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PieChart } from '@mui/x-charts/PieChart';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import CorniceFront from './CorniceFront';
import CornicePressure from './CornicePressure';
import FilesRecap from 'components/FilesRecap/Recap.js';
import InsertData from "./InsertData.js";
import InsertDriver from './InsertDriver.js';
import ManagerForm from "../ManagerForm.js";
import TelemetryDownload from './TelemetryDownload.js';
import UploadTelemetry from './UploadTelemetry.js';

import STR1 from '../../img/starlane1.jpg';
import STR2 from '../../img/starlane2.jpg';
import STR3 from '../../img/starlane.jpg';
import STR4 from '../../img/starlane_new.png';

import UNI1 from '../../img/unipro1.jpg';
import UNI2 from '../../img/unipro2.jpg';
import UNI3 from '../../img/unipro3.jpg';

import MYCRON1 from '../../img/mycron1.png';
import MYCRON2 from '../../img/mycron2.jpg';
import MYCRON3 from '../../img/mycron3.jpg';
import MYCRON5 from '../../img/mychron_new.png';
import MYCRON4 from '../../img/mycron4.jpg';

import ALFANO1 from '../../img/alfano1.png';
import ALFANO2 from '../../img/alfano2.png';
import ALFANO1M from '../../img/alfano1M.png';
import ALFANO2M from '../../img/alfano2M.png';

import STR1_MOBILE from '../../img/starlane_mobile1.jpg';
import STR2_MOBILE from '../../img/starlane_mobile2.jpg';
import STR3_MOBILE from '../../img/starlane_mobile3.jpg';
import STR4_MOBILE from '../../img/starlane_mobile_new.jpg';

import UNI1_MOBILE from '../../img/unipro_mobile_new.jpg';
import UNI2_MOBILE from '../../img/unipro_mobile2.jpg';
import UNI3_MOBILE from '../../img/unipro_mobile3.jpg';

import MYCRON1_MOBILE from '../../img/mycron_mobile1.png';
import MYCRON2_MOBILE from '../../img/mycron_mobile2.jpg';
import MYCRON3_MOBILE from '../../img/mycron_mobile3.jpg';
import MYCRON4_MOBILE from '../../img/mychrom_new_mobile.png';
import MYCRON5_MOBILE from '../../img/mycron_mobile4.jpg';

import myUserStore from '../../stores/UserStore';

const StepperDot = styled(Box)(({ theme, active }) => ({
  width: active ? 10 : 8,
  height: active ? 10 : 8,
  borderRadius: "50%",
  margin: theme.spacing(0.5),
  backgroundColor: active ? theme.palette.primary.main : "rgba(0, 0, 0, 0.3)",
}));

const itemHeight = 48;
const itemPaddingTop = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PieChartWithSelect = ({ handleSend = () => { }, handleStopUpload = () => { }, stopUpload }) => {
  const [refreshUploadList, setRefreshUploadList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const [anchorElTeamAnalysis, setAnchorElTeamAnalysis] = useState(null);
  const [selectedOptionTeamAnalysis, setSelectedOptionTeamAnalysis] = useState('');
  const [competitions, setCompetitions] = useState([]);
  const [racingData, setRacingData] = useState([]);
  const [showFront, setShowFront] = useState(false);
  const [showPressure, setShowPressure] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [emailName, setEmailName] = useState([]);
  const [driver, setDriver] = useState([]);
  const [showEngine, setShowEngine] = useState(true);
  const [race, setRace] = useState("");
  const [day, setDay] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [competitionLoading, setCompetitionLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [helpOpen, setHelpOpen] = useState(false);
  const [selectedHelpImage, setSelectedHelpImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showDownloadInstructions, setShowDownloadInstructions] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [uploadData, setUploadData] = useState([])
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;

  // Ora ogni device ha DUE serie di istruzioni:
  // "instructions" per le slide di immagini
  // "instructionsFull" per il testo lungo da mostrare al click "How to download telemetry data from my device?"
  const devices = {
    starlane: {
      images: {
        mobile: [STR4_MOBILE, STR1_MOBILE, STR2_MOBILE, STR3_MOBILE],
        desktop: [STR4, STR1, STR2, STR3],
      },
      instructions: [
        "1. Click on 'Session Archive'.",
        "2. Double Click on a lap.",
        "3. Click on 'Tools'.",
        "4. Click on 'Export CSV' and choose 'full session'.",
      ],
      instructionsFull: [
        "1. Connect your device (e.g., insert the USB flash drive for STEALTH GPS-4, or use the USB cable for CORSARO/DAVINCI-II). If using Bluetooth, choose your device from the 'Connection To' menu in MAAT.",
        "2. Launch MAAT and open the Data Download panel by clicking the 'M' icon (labeled 'Data Download' or similar).",
        "3. Fill in the session details (EVENT, SESSION, DRIVER, VEHICLE, CIRCUIT) in the Data Download window.",
        "4. If no sessions are listed, click 'Update Session List' to refresh available sessions.",
        "5. Select one or more sessions from the list (based on date/time) and click 'Download'. The sessions will be saved to your PC and listed in the Sessions Archive.",
        "6. Once download completes, click 'Close' to return to the main MAAT window. Your data is now ready for viewing or further analysis.",
        "Link to the full manual:",
        "https://bit.ly/41PGdK6"
      ],
      tutorialLink: "https://youtu.be/XRyxjavnSbs?si=XJpVzo8buw8CQlYj",
    },
    unipro: {
      images: {
        mobile: [UNI1_MOBILE, UNI2_MOBILE, UNI3_MOBILE],
        desktop: [UNI1, UNI2, UNI3],
      },
      instructions: [
        "1. Choose the session to export",
        "2. Click on 'Export Session' --> 'For dashware'",
        "3. Export all fields.",
      ],
      instructionsFull: [
        "1. Connect the UniGo 7006 to Your Computer:",
        "   - Use a USB cable to connect the UniGo 7006 to your computer.",
        "   - The device should appear as a removable drive on your computer.",
        "   - Otherwise connect the devices via bluetooth.",
        "2. Transfer Data."
      ],
      tutorialLink: "https://youtu.be/VwUZoQFJtK4?si=NUsF60-4VF94Ymuo",
    },
    mychron: {
      images: {
        mobile: [MYCRON1_MOBILE, MYCRON2_MOBILE, MYCRON4_MOBILE, MYCRON3_MOBILE, MYCRON5_MOBILE],
        desktop: [MYCRON1, MYCRON3, MYCRON5, MYCRON4, MYCRON2],
      },
      instructions: [
        "1.(version above 3.71.12) Click on Preferences --> Data Download Preferences",
        "2. Select 'Advanced' and tick the CSV checkbox.",
        "3. Click on Analysis to view your sessions",
        "4. Right click on the session file you want to export.",
        "5. Select 'Export Item' --> Race Studio2.CSV",
      ],
      instructionsFull: [
        "MyChron5 connects to your PC via Wi-Fi. Follow these steps to establish the connection.",
        "1. Connect MyChron5 to Your Computer:",
        "- Set MyChron5's Wi-Fi to 'AUTO'",
        "- Find your MyChron device's name on the home page or under 'System Information'.",
        "- Open Race Studio 3 on your PC, click the Wi-Fi icon, and select your MyChron5.",
        "2. Download Data:",
        "- Go to the Download tab in Race Studio 3.",
        "- Select the desired files (laps, times, etc.).",
        "- Click 'Download' to transfer data to your PC.",
        "Link to the full manual:",
        "https://bit.ly/4gTuQ8g"
      ],
      tutorialLink: "https://youtu.be/MGno9R8r0I0?si=WB8AjA2K048bS-jN",
    },

    alfano: {
      images: {
        mobile: [ALFANO1M, ALFANO2M],
        desktop: [ALFANO1, ALFANO2],
      },
      instructions: [
        "1. Double Click on the session",
        "2. Click on 'Export your csv session' --> 'Compatible with Dashware'.",

      ],
      instructionsFull: [
        "There are 3 different option to download data from your Alfano device:",
        "1. Bluetooth:",
        "- Open the sharing menu by pressing the central right button.",
        "- Select Bluetooth to view the device's name, Bluetooth address, and PIN.",
        "- Use the Alfano ADA app on iOS, Android, or Windows to transfer data.",
        "2. Wireless:",
        "- Enable Wireless in the sharing menu.",
        "- Select the desired files (laps, times, etc.).",
        "- Use the security code displayed to connect.",
        "3. USB:",
        "- Connect the device to a Windows PC using the A4540 USB cable.",
        "- Select the desired files (laps, times, etc.).",
        "- Check the COM port in your PC's 'Device Manager' and start the transfer.",
        "Link to the full manual:",
        "https://bit.ly/4abyqIK"
      ],
      tutorialLink: "https://youtu.be/MGno9R8r0I0?si=WB8AjA2K048bS-jN",
    },
  };

  const sliderOptions = {
    slidesPerView: 3,
    spaceBetween: 16,
    breakpoints: {
      320: {
        centeredSlides: true,
        slidesPerView: 1.1,
        spaceBetween: 16,
      },
      900: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
  };

  const handleDeviceSelect = (deviceKey) => {
    const device = devices[deviceKey];
    if (!device) {
      console.error(`Device with key ${deviceKey} does not exist.`);
      return;
    }
    const images = isMobile ? device.images.mobile : device.images.desktop;
    if (!Array.isArray(images) || images.length === 0) {
      console.error(`Images for device ${deviceKey} are invalid or empty.`);
      return;
    }
    // Per unipro c'erano solo 3 immagini: le ripetiamo se vogliamo 4 slide
    const completeImages =
      deviceKey === "alfano"
        ? images.slice(0, 2)
        : deviceKey === "unipro"
          ? images.slice(0, 3)
          : [...images];

    // Stessa cosa per instructions: limitiamo a 2 per "alfano", a 3 per "unipro", e a 4 per gli altri dispositivi
    const completeInstructions =
      deviceKey === "alfano"
        ? device.instructions.slice(0, 2)
        : deviceKey === "unipro"
          ? device.instructions.slice(0, 3)
          : [...device.instructions];

    while (
      completeImages.length < 4 &&
      deviceKey !== "unipro" &&
      deviceKey !== "alfano"
    ) {
      completeImages.push(completeImages[completeImages.length - 1]);
    }

    while (
      completeInstructions.length < 4 &&
      deviceKey !== "unipro" &&
      deviceKey !== "alfano"
    ) {
      completeInstructions.push(
        completeInstructions[completeInstructions.length - 1]
      );
    }
    setSelectedDevice({
      ...device,
      images: completeImages,
      instructions: completeInstructions
    });
    setCurrentImageIndex(0);
    setHelpOpen(true);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex + 1 === selectedDevice.images.length ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedDevice.images.length - 1 : prevIndex - 1
    );
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowEngine(newValue === 0);
    setShowFront(newValue === 1);
    setShowPressure(newValue === 2);
  };
  console.log("su 2", stopUpload)
  const handleChangeTab1 = (newValue) => {
    setValue(0);
    setShowEngine(true);
    setShowFront(false);
  };

  const handleDateChange = (date) => {
    setValue(1);
    setSelectedDate(date);
    handleMenuClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-calendar-popover' : undefined;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUploadComplete = (status) => {
    setRefreshUploadList(status);
  };

  const handleHelpOpen = () => {
    setHelpOpen(true);
    setSelectedHelpImage(null);
  };
  const handleHelpClose = () => {
    setHelpOpen(false);
    setSelectedHelpImage(null);
    setShowDownloadInstructions(false);
  };

  const handleChipClick = (mobileImageUrl, desktopImageUrl) => {
    setSelectedHelpImage(isMobile ? mobileImageUrl : desktopImageUrl);
  };

  const handleBackToChips = () => {
    setSelectedHelpImage(null);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Grid
        item
        lg={showPersonalButton ? 6 : 4}
        md={showPersonalButton ? 6 : 4}
        sm={showPersonalButton ? 6 : 4}
        xs={12}
        sx={{ marginBlock: 2 }}
      >
        <Typography sx={{ fontSize: 40 }}>Upload Files </Typography>
        <Box
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={handleHelpOpen}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: theme.palette.primary.main
            }}
          >
            How to download and upload telemetry files?
          </Typography>
        </Box>
      </Grid>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        </Box>

        <div className='graphs'>
          {showEngine && (
            <UploadTelemetry
              race={race}
              day={day}
              handleSend={handleSend}
              stopUpload={stopUpload}
              handleStopUpload={handleStopUpload}
              driver={driver}
              fullName={emailName}
              showTable={!!race && !!day}
              onComplete={handleUploadComplete}
            />
          )}
          {showFront && (
            <TelemetryDownload
              race={race}
              day={day}
              driver={driver}
              fullName={emailName}
              showTable={!!race && !!day}
              handleChipClick={handleChangeTab1}
            />
          )}
        </div>
      </Box>

      <Box>
        <Dialog
          open={helpOpen}
          onClose={handleHelpClose}
          aria-labelledby="help-dialog-title"
          fullWidth
          maxWidth="md"
          sx={{
            "& .MuiDialog-paper": {
              padding: theme.spacing(3),
              borderRadius: theme.spacing(3),
              backgroundColor: "#f9f9f9",
            },
          }}
        >
          <DialogTitle
            id="help-dialog-title"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            Telemetry Device Help
          </DialogTitle>

          <DialogContent>
            {selectedDevice === null ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 3,
                  mt: 2,
                  p: 2,
                  padding: isMobile ? theme.spacing(2) : theme.spacing(3),
                  overflowX: 'hidden' // Prevent horizontal scrolling
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  Select a device to learn more:
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: isMobile ? 1 : 3,
                  }}
                >
                  {/* Example of narrower maxWidth on the Chips above */}
                  <Chip
                    label="Starlane"
                    onClick={() => handleDeviceSelect("starlane")}
                    clickable
                    variant="outlined"
                    sx={{
                      fontSize: "1rem",
                      padding: "12px 24px",
                      borderRadius: "24px",
                      borderColor: "#1976d2",
                      color: "#1976d2",
                      "&:hover": {
                        backgroundColor: "rgba(25, 118, 210, 0.1)",
                      },
                      maxWidth: 150,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  />
                  <Chip
                    label="Unipro"
                    onClick={() => handleDeviceSelect("unipro")}
                    clickable
                    variant="outlined"
                    sx={{
                      fontSize: "1rem",
                      padding: "12px 24px",
                      borderRadius: "24px",
                      borderColor: "#1976d2",
                      color: "#1976d2",
                      "&:hover": {
                        backgroundColor: "rgba(25, 118, 210, 0.1)",
                      },
                      maxWidth: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  />
                  <Chip
                    label="Mychron"
                    onClick={() => handleDeviceSelect("mychron")}
                    clickable
                    variant="outlined"
                    sx={{
                      fontSize: "1rem",
                      padding: "12px 24px",
                      borderRadius: "24px",
                      borderColor: "#1976d2",
                      color: "#1976d2",
                      "&:hover": {
                        backgroundColor: "rgba(25, 118, 210, 0.1)",
                      },
                      maxWidth: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  />
                  <Chip
                    label="Alfano"
                    onClick={() => handleDeviceSelect("alfano")}
                    clickable
                    variant="outlined"
                    sx={{
                      fontSize: "1rem",
                      padding: "12px 24px",
                      borderRadius: "24px",
                      borderColor: "#1976d2",
                      color: "#1976d2",
                      "&:hover": {
                        backgroundColor: "rgba(25, 118, 210, 0.1)",
                      },
                      maxWidth: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  />
                </Box>
              </Box>
            ) : !showDownloadInstructions ? (
              <Box>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: 400,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                    borderRadius: "12px",
                  }}
                >
                  <IconButton
                    onClick={handlePreviousImage}
                    sx={{
                      position: "absolute",
                      left: isMobile ? "-20px" : 10,
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#1976d2",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 1)",
                      },
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>

                  <img
                    src={selectedDevice.images[currentImageIndex]}
                    alt={`Slide ${currentImageIndex + 1}`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "12px",
                    }}
                  />

                  <IconButton
                    onClick={handleNextImage}
                    sx={{
                      position: "absolute",
                      right: isMobile ? "-20px" : 10,
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#1976d2",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 1)",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt:(selectedDevice?.instructions?.length === 2 && isMobile) ? -10 : -3,
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: isMobile ? "1rem" : "1.2rem",
                      fontWeight: "bold",
                      color: "#000000",
                      mt: 2,
                      textAlign: "center",
                    }}
                  >
                    {selectedDevice.instructions[currentImageIndex]}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {selectedDevice?.images.map((_, index) => (
                    <StepperDot key={index} active={index === currentImageIndex} />
                  ))}
                </Box>

                {isMobile && (
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography
                      onClick={() => setShowDownloadInstructions(true)}
                      sx={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                        textDecoration: 'underline'
                      }}
                      variant="subtitle2"
                    >
                      How to download telemetry data from my device?
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Downloading Telemetry Data Instructions
                </Typography>
                {selectedDevice.instructionsFull.map((item, index) => {
                  // Make URL clickable if present
                  const isURL = item.startsWith("http://") || item.startsWith("https://") || item.startsWith("bit.ly");
                  return (
                    <Typography key={index} sx={{ mb: 2 }}>
                      {isURL ? (
                        <a
                          href={item}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#1976d2", textDecoration: "underline" }}
                        >
                          {item}
                        </a>
                      ) : (
                        item
                      )}
                    </Typography>
                  );
                })}
                <Typography
                  onClick={() => setShowDownloadInstructions(false)}
                  sx={{
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                    mt: 2
                  }}
                  variant="subtitle2"
                >
                  Go Back
                </Typography>
              </Box>
            )}
          </DialogContent>

          {selectedDevice && !showDownloadInstructions && (
            <DialogActions
              // 1) Force no-wrap so both chips stay on the same line
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: isMobile ? "center" : "space-between",
                alignItems: "center",
                pb: 2,
                pt: 2,
                padding: isMobile ? theme.spacing(2) : theme.spacing(3),
                overflowX: "hidden"
              }}
            >
              {/* If not mobile, we show the link on the left, but that’s unchanged. */}
              {!isMobile && (
                <Typography
                  onClick={() => setShowDownloadInstructions(true)}
                  sx={{
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                    textDecoration: 'underline'
                  }}
                  variant="subtitle2"
                >
                  How to download telemetry data from my device?
                </Typography>
              )}

              <Box>
                <Chip
                  label="Go Back"
                  onClick={() => setSelectedDevice(null)}
                  clickable
                  // 2) Shrink the chips a bit when isMobile so they fit within 390px
                  sx={{
                    fontSize: isMobile ? "o.8rem" : "1rem",
                    padding: isMobile ? "6px 10px" : "12px 0px",
                    borderRadius: "24px",
                    borderColor: "#1976d2",
                    color: "#1976d2",
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "rgba(25, 118, 210, 0.1)",
                    },
                    mr: 2,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                />
                <Chip
                  label="Watch Tutorial"
                  onClick={() => window.open(selectedDevice.tutorialLink, "_blank")}
                  clickable
                  sx={{
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    padding: isMobile ? "6px 10px" : "12px 20px",
                    borderRadius: "24px",
                    color: "#fff",
                    backgroundColor: "#1976d2",
                    "&:hover": {
                      backgroundColor: "#115293",
                    },
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                />
              </Box>
            </DialogActions>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};

export default observer(PieChartWithSelect);
