import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, Fade, Chip, Grid, ToggleButton, ToggleButtonGroup, styled, useMediaQuery, useTheme, } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { toast } from 'react-toastify';
import PayPalButton from 'components/PayPalButton';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Plan from 'components/Plans/Plan';
import PlanExternal from 'components/Plans/PlanExternal';
import myUserStore from 'stores/UserStore';
import { observer } from 'mobx-react-lite';
import axios from "axios";
import 'swiper/css';
import PaypalProvider from "services/PaypalProvider";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const SECRET = process.env.REACT_APP_PAYPAL_SECRET;

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: '#fff',
  padding: '7px 24px',
  borderColor: 'transparent',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#fff',
    color: '#000',
  },
}));

const sliderOptions = {
  slidesPerView: 3,
  spaceBetween: 16,
  breakpoints: {
    320: {
      centeredSlides: true,
      slidesPerView: 1.1,
      spaceBetween: 21,
    },
    900: {
      centeredSlides: false,
      slidesPerView: 3,
      spaceBetween: 24,
    },
  },
};

const pricingOptions1 = [
  {
    chip: 'Basic',
    chip: {
      color: 'primary',
      label: 'Basic',
      variant: 'outlined',
    },
    title: 'Growth',
    currency: '€',
    description: 'For drivers racing individually looking to use AiMotor to improve their performance',
    price: '€25/month',
    yearPrice: '€280/year',
    priceNumber: 25,
    yearPriceNumber: 280,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
  {
    chip: {
      color: 'secondary',
      label: 'most popular',
      variant: 'contained',
    },
    title: 'Pro',
    currency: '€',
    price: '€55/month',
    description: 'For drivers seeking to use AiMotor to compare and improve their performance within a single, exclusive group of drivers and with our best AI model',
    yearPrice: '€640/year',
    priceNumber: 55,
    yearPriceNumber: 640,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'Join Unlimited Teams',
      'AI model AiMotor 2',
      'On Board insertion',
      'Create One Team (up to 5 members)'
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
  {
    chip: {
      color: 'success',
      label: 'best',
      variant: 'contained',
    },
    title: 'Team',
    price: '€70/month',
    description: 'For drivers seeking to use AiMotor to compare and improve their performance with multiple and large groups of drivers and with our best AI model',
    yearPrice: '€820/year',
    currency: '€',
    priceNumber: 70,
    yearPriceNumber: 820,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Driver Analytics',
      'On Board insertion',
      'Join Unlimited Teams',
      'AI model AiMotor 2',
      'Create Unlimited teams',
      'Invite Unlimited team members',
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
];

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "EUR",
  intent: "subscription",
  vault: true,
};

function Plans({ isExternal = false, onPlanClick = () => { }, onSuccess = () => { }, onUndo = () => { }, showAll, isDowngrade}) {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [plainId, setPlanId] = useState();
  console.log("isD")
  const [period, setPeriod] = useState("MONTH");
  const [showPaypal, setShowPaypal] = useState(false);
  const [newTmpPlan, setNewTmpPlan] = useState({});
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedYearPrice, setSelectedYearPrice] = useState(0);
  const [pricingOptions, setPricingOptions] = useState([]);
  const scrollToRef = useRef(null);  // Ref for scrolling
  const [scrollToPaypal, setScrollToPaypal] = useState(false);
  const paypalRef = useRef(null);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  console.log("show all", showAll)
  const myPlan =
    myUserStore?.subscriptionDetails === undefined || showAll || myUserStore?.subscriptionDetails?.status === 'CANCELLED'
      ? undefined
      : myUserStore?.subscriptionDetails?.custom_id;
  console.log("myPlan", myPlan)

  const isTrial = myUserStore?.subscriptionDetails?.status === 'CANCELLED' || showAll ? false : !myPlan;
  console.log("sub details", myUserStore?.subscriptionDetails?.custom_id)

  const handleUpgrade = () => {
    if (newTmpPlan) {
      updateSubscription([
        {
          "op": "replace",
          "path": "/plan/billing_cycles/@sequence==2/pricing_scheme/fixed_price",
          "value": {
            "currency_code": "EUR",
            "value": selectedPrice
          }
        },
        {
          "op": "replace",
          "path": "/custom_id",
          "value": newTmpPlan.title
        }
      ]);
      setShowUpgradeDialog(false);
      onSuccess();
    }
  };


  useEffect(() => {
    let filteredOptions;
    if (myPlan) {
      filteredOptions = pricingOptions1.filter(option => {
        if (myPlan === 'Growth') return true;
        if (myPlan === 'Pro') return option.title === 'Pro' || option.title === 'Team';
        if (myPlan === 'Team') return option.title === 'Team';
        return false;
      });
    } else {
      filteredOptions = pricingOptions1;
    }
    setPricingOptions(filteredOptions);
  }, [myPlan]);

  const selectedPlanId = myUserStore.subscriptionDetails?.custom_id;
  const subscriptionId = myUserStore.subscriptionId;

  const onUndoPayment = () => {
    setShowPaypal(false);
    setNewTmpPlan(undefined);
    onUndo();
  };
  const postSubscription = async (subscriptionDetails) => {
    console.log("details recieved", subscriptionDetails)
    const user = JSON.parse(localStorage.getItem("user_data") || {})
    if (user && user.email) {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/post_subscription/`
      await axios.post(
        apiUrl,
        {
          user_id: user.user_id,
          user_email: user.email,
          subscriptionDetails
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then(
          (response) => {
            toast.success("Plan Changed!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
            });
            myUserStore.setUserSubscriptionDetails(subscriptionDetails);
            debugger

          }


        )
        .catch(
          (error) => {
            console.error("Error!", error)
          }
        )
    } else {
      console.error('no email found')
      toast.error("Email not found!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
    }

  };

  const getPaypalSubscription = async (subId) => {
    debugger
    const token = await getToken();
    if (token) {
      return PaypalProvider.get(`billing/subscriptions/${subId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          debugger
          const subs = res.data
          postSubscription(subs);
          return subs
        })
        .catch((err) => {
          console.error('Error getting subscription details:', err);
          return {}
        })
    } else {
      return {}
    }
  }

  const updateSubscription = async (updateData) => {

    const token = await getToken();
    const subscriptionId = myUserStore.subscriptionDetails?.id
    debugger
    if (token) {
      try {
        const response = await axios.patch(`https://api-m.paypal.com/v1/billing/subscriptions/${subscriptionId}`, updateData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        console.log('Updated Subscription:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error updating subscription:', error);
      } finally {
       getPaypalSubscription(subscriptionId);
      }
    }
  };
  const getToken = async () => {

    try {
      const response = await PaypalProvider.post('oauth2/token',
        'grant_type=client_credentials',
        {
          auth: {
            username: CLIENT_ID,
            password: SECRET
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
      console.log('Token:', response.data.access_token);
      return response.data.access_token;
    } catch (error) {
      console.error('Error getting token:', error);
    } finally {

    }
  };

  const onPaymentComplete = (subscriptionDetails) => {
    myUserStore.setUserSubscriptionDetails(subscriptionDetails);
    setNewTmpPlan(undefined);
    setShowPaypal(false);
    onSuccess();
  };

  useEffect(() => {
    if (showPaypal && scrollToPaypal && paypalRef.current) {
      paypalRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrollToPaypal(false); // Reset the scroll state
    }
  }, [showPaypal, scrollToPaypal]);

  const handlePlanClick = (plan, isActive) => {
    if (!isActive) {
      if (myUserStore?.subscriptionDetails?.status === 'CANCELLED') {
        setShowPaypal(true);
      } else {
        setShowUpgradeDialog(true);
      }

      setSelectedPrice(plan.priceNumber);
      setSelectedYearPrice(plan.yearPriceNumber);
      setNewTmpPlan(plan);
      setScrollToPaypal(true); // Set scroll state to true to trigger scrolling after rendering
      onPlanClick(plan);

    }
  };

  const handleSwitchChange = (value) => {
    setPeriod(value);
    onUndoPayment();
  };

  const PlanByType = useCallback((props) => {
    return isExternal ? <PlanExternal {...props} /> : <Plan {...props} />;
  }, [isExternal]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Dialog
        open={showUpgradeDialog}
        onClose={() => setShowUpgradeDialog(false)}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '8px',
            padding: '30px',
            maxWidth: '600px',
            width: '90%', // Adattamento per schermi piccoli
            textAlign: 'center',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: { xs: '1.4rem', sm: '1.8rem' }, // Adatta il font size
            fontWeight: 'bold',
            color: '#2c3e50',
            marginBottom: '10px',
          }}
        >
               {isDowngrade
    ? `DO YOU WANT TO DOWNGRADE YOUR PLAN TO ${newTmpPlan?.title}?`
    : `DO YOU WANT TO UPGRADE YOUR PLAN TO ${newTmpPlan?.title}?`}
         
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
          <Chip
            label= {isDowngrade ? "Downgrade" : "Upgrade"}
            onClick={handleUpgrade}
            sx={{
              fontSize: { xs: '1.2rem', sm: '1.2rem' }, // Adatta il font size
              fontWeight: 'bold',
              padding: '10px 20px',
              backgroundColor: '#8e44ad',
              color: '#fff',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#732d91',
              },
            }}
          />
        </DialogActions>
        <DialogContent>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '0.9rem', sm: '1.1rem' }, // Adatta il font size
              color: '#34495e',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
           {isDowngrade
      ? "Confirm your plan downgrade to unlock additional features and enhance your experience."
      : "Confirm your plan upgrade to unlock additional features and enhance your experience."}
          </Typography>
        </DialogContent>
      </Dialog>


      <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
        <ToggleButtonGroup
          color="light"
          size="small"
          value={period}
          exclusive
          onChange={(e, value) => handleSwitchChange(value)}
          aria-label="Platform"
          sx={{
            overflow: 'hidden',
            borderRadius: '30px',
            border: '1px solid #fff'
          }}
        >
          <StyledToggleButton value="MONTH">MONTHLY</StyledToggleButton>
          <StyledToggleButton value="YEAR">YEARLY</StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box as={Swiper} {...sliderOptions} sx={{ width: '100%', padding: isMobile ? ' 16px' : '16px 80px', }}>
        {pricingOptions.map((option, index) => {
          const hasPlan = newTmpPlan && newTmpPlan?.title;
          const isSelected = newTmpPlan?.title && newTmpPlan.title === option.title;
          return (
            <SwiperSlide key={index} style={{ height: 'auto' }}>
              <PlanByType
                plan={option}
                period={period}
                isActive={selectedPlanId === option.title}
                loading={loading}
                onClick={handlePlanClick}
                sx={{
                  opacity: hasPlan ? (isSelected ? 1 : 0.2) : 1,
                  disabled: hasPlan && !isSelected
                }}
              />
            </SwiperSlide>
          );
        })}
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="stretch"></Grid>
      <Fade in={showPaypal} unmountOnExit>
        <Box px={3}> {/* Add ref to the PayPal container */}
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButton ref={paypalRef}
              period={period}
              price={period === 'MONTH' ? selectedPrice : selectedYearPrice}
              planId={plainId}
              currentSubscriptionId={subscriptionId}
              selectedPlan={newTmpPlan}
              onPaymentComplete={onPaymentComplete}
              onUndoPayment={onUndoPayment}
              isTrial={isTrial}
              noPromo={showAll}
            />
          </PayPalScriptProvider>
        </Box>
      </Fade>
    </Box>
  );
}

export default observer(Plans);
