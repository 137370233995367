import { CircularProgress, Stack } from '@mui/material';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material'; // Import MUI components
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import PaypalProvider from "services/PaypalProvider";
import axios from "axios";
import myUserStore from "stores/UserStore";
import { preparePlanOptions } from "services/PaypalHelper";
import { sub } from "date-fns";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

// flusso paypal utenti registrati
// alla success abilitare l'utente (cambio flag disabled false -> true)
// se invece l'utente è disablilitato rimandarlo alla pagina dei pricing per la scelta del piano
// in un secondo momento capire come creare una convalida della transazione per evitare frodi

const PayPalButton = ({
    period,
    price,
    currentSubscriptionId,
    selectedPlan = {},
    onUndoPayment = () => { },
    onPaymentComplete = () => { },
    noPromo,
    isTrial
}) => {

    const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const SECRET = process.env.REACT_APP_PAYPAL_SECRET;
    console.log("period", period)
    console.log("Need a trial?", isTrial)
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [currency, setCurrency] = useState(options.currency);
    const [planId, setPlanId] = useState(''); // State to store plan ID
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [discount, setDiscount] = useState(0); // State to store the discount value
    const [promoFieldColor, setPromoFieldColor] = useState(''); // Color for the TextField border
    const [message, setMessage] = useState('');
    const [promoMonths, setPromoMonths] = useState(null);

    const getToken = async () => {
        setIsLoading(true);
        try {
            const response = await PaypalProvider.post('oauth2/token',
                'grant_type=client_credentials',
                {
                    auth: {
                        username: CLIENT_ID,
                        password: SECRET
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );
            console.log('Token:', response.data.access_token);
            return response.data.access_token;
        } catch (error) {
            console.error('Error getting token:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const createProduct = async (token) => {
        try {
            const response = await PaypalProvider.post('catalogs/products', {
                name: "AiMotor subscription plan",
                description: "Subscription to Aimotor services",
                type: "SERVICE",
                category: "SOFTWARE",
                image_url: "https://example.com/streaming.jpg",
                home_url: "https://aimotor.eu/"
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'PayPal-Request-Id': uuidv4() // Generate a unique ID for each request
                }
            });
            console.log('Product ID:', response.data.id);
            return response.data.id;
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    const createPlan = async (token, productId) => {
        try {
            const response = await PaypalProvider.post('billing/plans', {
                product_id: productId,
                name: selectedPlan?.title || "Basic plan",
                description: selectedPlan?.title || "Basic plan",
                billing_cycles: preparePlanOptions(selectedPlan.title, { period, price, currency, isTrial, promoMonths }),
                payment_preferences: {
                    auto_bill_outstanding: true,
                    setup_fee_failure_action: "CONTINUE",
                    payment_failure_threshold: 3
                },
                taxes: {
                    percentage: "0",
                    inclusive: false
                }
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'PayPal-Request-Id': uuidv4() // Generate a unique ID for each request
                }
            });
            console.log('Plan ID:', response.data.id);
            return response.data.id;
        } catch (error) {
            console.error('Error creating plan:', error);
        }
    };

    const getSubscriptionDetails = async (subscriptionId) => {
        const token = await getToken();
        if (token) {
            try {
                const response = await axios.get(`https://api-m.paypal.com/v1/billing/subscriptions/${subscriptionId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log('Subscription Details:', response.data);
                return response.data;
            } catch (error) {
                console.error('Error getting subscription details:', error);
            }
        }
    };


    const postSubscription = async (subscriptionDetails) => {
        console.log("details recieved", subscriptionDetails)
        const user = JSON.parse(localStorage.getItem("user_data") || {})
        if (user && user.email) {
            const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/post_subscription/`
            await axios.post(
                apiUrl,
                {
                    user_id: user.user_id,
                    user_email: user.email,
                    subscriptionDetails
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        accept: "application/json",
                    },
                }
            )
                .then(
                    // (response) => {
                    //     toast.success("Plan Changed!", {
                    //         position: "top-right",
                    //         autoClose: 2000,
                    //         hideProgressBar: false,
                    //     });

                    // }

                )
                .catch(
                    (error) => {
                        console.error("Error!", error)
                    }
                )
        } else {
            console.error('no email found')
            toast.error("Email not found!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
        }

    };

    useEffect(() => {
        localStorage.setItem('subscriptionId', subscriptionId);
    }, [subscriptionId]);

    const getPaypalSubscription = async (subId) => {
        debugger
        const token = await getToken();
        if (token) {
          return PaypalProvider.get(`billing/subscriptions/${subId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          })
            .then((res) => {
              debugger
              const subs = res.data
              postSubscription(subs);
              return subs
            })
            .catch((err) => {
              console.error('Error getting subscription details:', err);
              return {}
            })
        } else {
          return {}
        }
      }

    const updateSubscription = async (updateData) => {
       
        const token = await getToken();
        const subscriptionId = myUserStore.subscriptionDetails?.id
        const subsDet= getPaypalSubscription(subscriptionId);
        debugger
        if (token) {
            try {
                const response = await axios.patch(`https://api-m.paypal.com/v1/billing/subscriptions/${subscriptionId}`, updateData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log('Updated Subscription:', response.data);
                return response.data;
            } catch (error) {
                console.error('Error updating subscription:', error);
            }
        }
    };

    const cancelSubscription = async (subscriptionId) => {
        const token = await getToken();
        console.log("sub id while canceling", subscriptionId)
        if (token) {
            try {
                const response = await PaypalProvider.post(`billing/subscriptions/${subscriptionId}/cancel`, {
                    reason: 'User requested cancellation'
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log('Cancelled Subscription:', response.data);
                return response.data;
            } catch (error) {
                console.error('Error cancelling subscription:', error);
            }
        }
    };

    const setupPayPal = async () => {
        setIsLoading(true);
        const token = await getToken();
        if (token) {
            const productId = await createProduct(token);
            if (productId) {
                const newPlanId = await createPlan(token, productId);
                console.log('New Plan ID:', newPlanId);
                setPlanId(newPlanId);
                myUserStore.setUserPlanId(newPlanId)
                if (newPlanId) {
                    setIsLoading(false);
                }
            }
        }
    };

    {/*useEffect(() => {
        setupPayPal();
    }, []);*/}

    const onCurrencyChange = ({ target: { value } }) => {
        setCurrency(value);
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: value,
            },
        });
    };

    const createNewSubscription = (data, actions) => {
        return actions.subscription.create({
            plan_id: planId,
            custom_id: selectedPlan.title // the id of selected plan
        }).then((subscription) => {
            setSubscriptionId(subscription);
            return subscription
        }).catch(error => {
            if (error === 'popup_closed') {
                console.log('Popup was closed by the user.');
            } else {
                console.error('Subscription creation error:', error);
            }
        });

    };

    const onApproveSubscription = (data, actions) => {
        return actions.subscription.get().then((details) => {
            handlePaymentSuccess(details);
        }).catch(error => {
            console.error('Subscription approval error:', error);
        });
    };

    const handlePaymentSuccess = (subscriptionDetails) => {
        myUserStore.setUserSubscriptionDetails(subscriptionDetails)
        postSubscription(subscriptionDetails);
        setSubscriptionId(subscriptionDetails.id)
        onPaymentComplete(subscriptionDetails, selectedPlan)
        // remove old subscription if present
        // if currentSubscriptionId is empty paypal return error
        cancelSubscription(currentSubscriptionId);
        // Add your custom logic here
    };

    const resetPaypal = () => {
        setPlanId('')
        setSubscriptionId('')
    }

    useEffect(() => {
        setupPayPal();

        return () => {
            resetPaypal()
        }
    }, [selectedPlan?.title, promoMonths]);
  

    // Function to handle promo code button click
    const handleUsePromoCode = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_discount?code=${promoCode}`);
            if (response.status === 200) {
                const { discount, active } = response.data;
                if (active) {
                    setDiscount(parseFloat(discount)); // Set the discount value
                    setPromoFieldColor('green'); // Make the field green
                    setPromoMonths(response.data.free_trial)
                    setMessage(`Free trial for ${response.data.free_trial} months!`);
                    toast.success('Promo code used!', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: false,
                    });
                } else {
                    throw new Error('Inactive promo code');
                }
            }
        } catch (error) {
            setPromoFieldColor('red'); // Make the field red
            setMessage('');
            setPromoMonths(null);
            toast.error('This promo code does not exist.', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
            });
        } finally {
            setPromoCode(''); // Reset the text field
        }
    };
    

    return (
        <div className="pay-pal">
            <IconButton
                sx={{
                    position: 'absolute',
                    right: 5,
                    top: 5,
                }}
                onClick={onUndoPayment}
            >
                <CloseIcon />
            </IconButton>
            <CircularProgress hidden={!isPending || !isLoading} />
            <div
                className="select-wrapper"
                style={{
                    display: 'flex',
                    flexDirection: window.innerWidth <= 600 ? 'column' : 'row',
                    justifyContent: window.innerWidth > 600 ? 'space-between' : 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                    gap: window.innerWidth <= 600 ? '16px' : '0',
                }}
            >
              
                <select
                    className="custom-select"
                    value={currency}
                    onChange={({ target: { value } }) => setCurrency(value)}
                    style={{
                        width: window.innerWidth <= 600 ? 'auto' : 'auto',
                    }}
                >
                    <option value="USD">💵 USD</option>
                    <option value="EUR">💶 Euro</option>
                </select>
                {!noPromo && (<div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: window.innerWidth <= 600 ? 1 : 20,
                        gap: '8px',
                        width: window.innerWidth <= 600 ? '100%' : 'auto',
                    }}
                >
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Enter promo code"
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value)}
                        style={{
                            flex: 1,
                            borderColor: promoFieldColor,
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: '#6C63FF',
                            whiteSpace: 'nowrap',
                        }}
                        onClick={handleUsePromoCode}
                    >
                        USE
                    </Button>
                </div>)}
            </div>
            {message && (
                <div style={{ marginTop: '8px',marginBottom: '8px', color: promoFieldColor }}>{message}</div>
            )}
            {planId ? (
                <div className="paypal-buttons">
                    <PayPalButtons
                        style={{
                            layout: 'vertical',
                            display: 'flex',
                            justifyContent: 'center',
                            shape: 'pill',
                            disableMaxWidth: true,
                        }}
                        createSubscription={(data, actions) => createNewSubscription(data, actions)}
                        onApprove={(data, actions) => onApproveSubscription(data,actions)}

                    />
                </div>
            ) : (
                <Stack direction="row" spacing={3} alignItems={'center'} py={2} color={'black'}>
                    <CircularProgress />
                    <span>Loading PayPal...</span>
                </Stack>
            )}
        </div>
    );
    
};

export default PayPalButton;
