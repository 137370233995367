import { Box, Button, Card, CardActions, CardContent, Chip, Fab, FormControl, CircularProgress, Grid, Hidden, IconButton, InputLabel, MenuItem, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import DriversDates from 'components/Steppers/DriversDates/DriversDates.js';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { LineChart } from '@mui/x-charts/LineChart';
import Mmenu from '@mui/material/Menu';
import { useHistory, } from 'react-router-dom';
import NewLineChart from './NewLineChart.js';
import Pricing from './Pricing.js';
import Select from '@mui/material/Select';
import TelemetryParent from './TelemetryParent.js';
import Draggable from 'react-draggable';
import OpenWithIcon from '@mui/icons-material/OpenWith'; import Telemetrytable from './Telemetrytable';
import TrackMap from './TrackMap'; // Adjust the path as necessary
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import axios from 'axios';
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite";
import { set } from 'date-fns';
import { toast } from "react-toastify";
import VideoTest from '../../img/VideoTestOnBoard.mp4';

const DriverAnalytics = ({ collapsed }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const showPersonalButton = myUserStore.isPersonalSpace;
    const [drivers, setDrivers] = useState([]);
    const [emailName, setEmailName] = useState([]);
    const [filterDriver, setFilterDriver] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const status = myUserStore.status
    const [myPlan, setMyPlan] = useState(myUserStore?.subscriptionDetails?.custom_id);

    const [competitionLoading, setCompetitionLoading] = useState(false);
    const [driver, setDriver] = useState([]);
    const [day, setDay] = useState("");
    const [race, setRace] = useState("");
    const [laps, setLaps] = useState([]);
    const [showRPM, setShowRPM] = useState(true);
    const [showSpeed, setShowSpeed] = useState(true);
    const [showTemp, setShowTemp] = useState(true);
    const [showMap, setShowMap] = useState(false);
    const [showTable, setShowTable] = useState(true);
    const [openFilter, setOpenFilter] = useState(false);
    const [selectOpen, setSelectOpen] = useState(false);
    const [dataset1, setDataset1] = useState([]);
    const [selectedSessionNumbers, setSelectedSessionNumbers] = useState([]);
    const selectedTeam = myUserStore.getSelectedTeam;
    const [sessionNumber, setSessionNumber] = useState(null);
    const [selectedDriver, setSelectedDriver] = useState('');
    const [selectedDriverDates, setSelectedDriverDates] = useState('');
    const [driverSessions, setDriverSessions] = useState([]);
    console.log("driver session", driverSessions)
    const [completed, setCompleted] = useState(false);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [sessionNumbersByDriver, setSessionNumbersByDriver] = useState({});
    const [hoveredChip, setHoveredChip] = useState(null); // New state to track the hovered chip
    const [hoverTimeout, setHoverTimeout] = useState(null);
    const [chartWidth, setChartWidth] = useState(1500);
    const [chartHeight, setChartHeight] = useState(250);
    const [speedData, setSpeedData] = useState([]);
    const [rpmData, setRpmData] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [videoStatuses, setVideoStatuses] = useState({});
    const [givenDriverSessions, setGivenDriverSessions] = useState([]);
    const [trackIndex, setTrackIndex] = useState('');
    const [lastSelectedDriver, setLastSelectedDriver] = useState(null);
    const [track, setTrack] = useState('');
    const [step, setStep] = useState(0);
    const [secondOpen, setSecondOpen] = useState(false);
    const [openDriverDates, setOpenDriverDates] = useState(true);
    const [hoveredData, setHoveredData] = useState({ latitude: null, longitude: null });
    const [showVideo, setShowVideo] = useState(false);
    const [videoId, setVideoId] = useState('67683b013838446396ecc4e7');
    const [video, setVideo] = useState([]);
    const history = useHistory();
    const [showPricing, setShowPricing] = useState(false);
    const [isMobile, setIsMobile] = useState([]);
    console.log('videos', video)
    const [stepPayload, setStepPayload] = useState({
        'race': race,
        'day': day,
        'drivers': [],
        'driverSessions': driverSessions,
        'laps': []


    })
    console.log("Step payload da", stepPayload)
    console.log("video file", video)
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const [highlightedItem, setHighLightedItem] = React.useState({
        seriesId: 'test-series',
        dataIndex: -1,
    });
    const [videoSize, setVideoSize] = useState({
        width: window.innerWidth < 600 ? 300 : 500,
        height: window.innerWidth < 600 ? 170 : 280,
    });
    const [showZoomIn, setShowZoomIn] = useState(true);

    const increaseSize = () => {
        if (videoSize.width < window.innerWidth * 0.95) {
            setVideoSize((prev) => ({
                width: prev.width * 1.1,
                height: prev.height * 1.1,
            }));
        }
    };

    const decreaseSize = () => {
        setVideoSize((prev) => ({
            width: Math.max(prev.width * 0.9, 200),
            height: Math.max(prev.height * 0.9, 100),
        }));
    };

    const handleResize = () => {
        if (window.innerWidth < 600) {
            setVideoSize({ width: 300, height: 170 });
        } else {
            setVideoSize({ width: 500, height: 280 });
        }
    };

    useEffect(() => {
        // Hide Zoom In button if video width is greater than 95% of the viewport width
        setShowZoomIn(videoSize.width < window.innerWidth * 0.90);
    }, [videoSize.width]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const sectorParts = [0, 50, 100, 150, 200, 250, 280, 306];

    // Subito dopo aver ottenuto `videoItem?.data` in useEffect() o dentro map(...):
    useEffect(() => {
        if (!video || video.length === 0) return;

        video.forEach((videoItem) => {
            if (videoItem?.data) {
                (async () => {
                    try {
                        const status = await getVideoStatus(361126, videoItem.data);
                        setVideoStatuses((prevStatuses) => ({
                            ...prevStatuses,
                            [videoItem.data]: status,
                        }));
                    } catch (error) {
                        console.error("Errore durante la verifica dello status video:", error);
                    }
                })();
            }
        });
    }, [video]);



    const getTK = useCallback(() => {
        axios
            .post(
                `${process.env.REACT_APP_API_DOMAIN}/get_filtered_telemetry_data_TK?race=${race}&day=${day}&team_id=${selectedTeam.id}`,
                driverSessions,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the access token in the header
                        accept: "application/json",
                        'Content-Type': 'application/json'
                    },
                }
            )
            .then((userResponse) => {
                setTempData(userResponse.data);
                if (selectedSessionNumbers?.length === 0) {
                    setSelectedSessionNumbers([1]);
                    setSessionNumber(1)
                    handleSessionNumberChange(1);
                }
            })
            .catch((error) => {
                console.error("Query Error:", error);
            });
    }, [race, day, driverSessions, selectedSessionNumbers]);

    const getSpeed = useCallback(() => {
        axios
            .post(
                `${process.env.REACT_APP_API_DOMAIN}/get_filtered_telemetry_data_Speed_Gps?race=${race}&day=${day}&team_id=${selectedTeam.id}`,
                driverSessions,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the access token in the header
                        accept: "application/json",
                        'Content-Type': 'application/json'
                    },
                }
            )
            .then((userResponse) => {
                setSpeedData(userResponse.data);
            })
            .catch((error) => {
                console.error("Query Error:", error);
            });
    }, [race, day, driverSessions]);

    const getRpm = useCallback(() => {
        axios
            .post(
                `${process.env.REACT_APP_API_DOMAIN}/get_filtered_telemetry_data_Rpm?race=${race}&day=${day}&team_id=${selectedTeam.id}`,
                driverSessions,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the access token in the header
                        accept: "application/json",
                        'Content-Type': 'application/json'
                    },
                }
            )
            .then((userResponse) => {
                setRpmData(userResponse.data);
            })
            .catch((error) => {
                console.error("Query Error:", error);
            });
    }, [race, day, driverSessions]);

    useEffect(() => {
        if (showTemp) {
            getTK();
        }
    }, [getTK, showTemp]);

    useEffect(() => {
        if (showSpeed) {
            getSpeed();
        }
    }, [getSpeed, showSpeed]);

    useEffect(() => {
        if (showRPM) {
            getRpm();
        }
    }, [getRpm, showRPM]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLapsSelection = (laps) => {
        setLaps(laps);
    };






    const getVideoMetadata = async () => {
        try {
            const videoPromises = driverSessions.flatMap((driverSession) => {
                return driverSession.sessions
                    .filter((session) => session.laps.length > 0) // Filtra le sessioni con almeno un giro
                    .map((session) => {
                        return axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_video_metadata`, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                                accept: "application/json",
                            },
                            params: {
                                race: race,
                                driver: driverSession.email,
                                day: day,
                                session: session.session,
                                team_id: selectedTeam.id,
                            },
                        }).then((response) => ({
                            driver: driverSession.email,
                            session: session.session,
                            data: response.data,
                        }));
                    });
            });

            const videos = await Promise.all(videoPromises);
            setVideo(videos); // Salva l'elenco dei video
        } catch (error) {
            console.error("Errore durante il recupero dei video:", error);
        } finally {
            setLoadingVideos(false); // Imposta il caricamento su false
        }
    };







    const handleApplyFilter = (close) => {
        setShowMap(true);
        const updatedDriverSessions = driver.map((d) => {
            let existingDriver = driverSessions.find(ds => ds.email === d);
            const selectedLaps = laps.find(lap => lap.email === d);

            const newSessions = (sessionNumbersByDriver[d] || []).map(sessionNum => {
                if ((sessionNum === sessionNumber) && (d === selectedDriver)) {
                    return {
                        "session": sessionNum,
                        "laps": selectedLaps ? selectedLaps.laps : []
                    };
                } else {
                    const existingSession = existingDriver ? existingDriver.sessions.find(session => session.session === sessionNum) : null;
                    return {
                        "session": sessionNum,
                        "laps": existingSession ? existingSession.laps : []
                    };
                }
            });

            if (existingDriver) {
                existingDriver.sessions = newSessions;
            } else {
                existingDriver = {
                    "email": d,
                    "sessions": newSessions
                };
            }

            return existingDriver;
        });

        setDriverSessions(updatedDriverSessions);
        if (close !== 1) {
            setOpenFilter(false);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const embedUrl = `https://iframe.mediadelivery.net/embed/361126/5f92f999-b1fd-41db-b5a7-3bb776d60fc2?autoplay=${true}&loop=${false}&muted=${false}&preload=true&responsive=true`;

    const handleSelectDay = (day) => {
        setDay(day);
        handleMenuClose();
    };
    const handleDoneClick = (event) => {
        // Chiudi il menu della Select
        console.log("ciaoo")
        event.stopPropagation();
        setSelectOpen(false);

    };

    const handleDriverChange = (event) => {
        const selectedDrivers = event.target.value;
        setDriver(Array.isArray(selectedDrivers) ? selectedDrivers : []);
        console.log("this are the selected drievrs", selectedDrivers);
        const { value } = event.target;
        if (value.length > 0) {
            setLastSelectedDriver(value[value.length - 1]);
        } else {
            setLastSelectedDriver(null);
        }
        const currentDriver = selectedDrivers[0];
        setSelectedDriver(currentDriver);
        setSelectedSessionNumbers(sessionNumbersByDriver[currentDriver] || []);

        const driverSessionData = driverSessions.find(ds => ds.email === currentDriver);
        if (driverSessionData) {
            const sessionData = driverSessionData.sessions.find(session => session.session === sessionNumber);
            setLaps([{ email: currentDriver, laps: sessionData ? sessionData.laps : [] }]);
        }
    };

    const handleSessionNumberChange = (number) => {
        // Aggiorna la sessione attualmente visualizzata
        setSessionNumber(number);
        //handleApplyFilter(1);
        setShowMap(true);

        // Aggiorna l'elenco delle sessioni selezionate
        setSelectedSessionNumbers((prevSelectedSessionNumbers) => {
            if (!prevSelectedSessionNumbers.includes(number)) {
                return [...prevSelectedSessionNumbers, number];
            }
            return prevSelectedSessionNumbers; // Mantieni l'elenco invariato se la sessione è già selezionata
        });

        setSessionNumbersByDriver(prevState => ({
            ...prevState,
            [selectedDriver]: selectedSessionNumbers.includes(number) ? selectedSessionNumbers : [...selectedSessionNumbers, number]
        }));

        // Gestione delle sessioni del driver
        const driverSessionData = driverSessions.find(ds => ds.email === selectedDriver);
        if (driverSessionData) {
            const sessionData = driverSessionData.sessions.find(session => session.session === number);
            setLaps([{ email: selectedDriver, laps: sessionData ? sessionData.laps : [] }]);
        }
    };

    const handleFilterReset = () => {
        // Filtra e mantieni tutte le sessioni dei driver tranne quelle del selectedDriver
        const updatedDriverSessions = driverSessions.map(ds => {
            if (ds.email === selectedDriver) {
                return {
                    ...ds,
                    sessions: []
                };
            }
            return ds;
        });

        // Aggiorna lo stato delle variabili dipendenti dal selectedDriver
        setDriverSessions(updatedDriverSessions);
        setSelectedSessionNumbers([]);
        setSessionNumber(null);
        setLaps([]);
        setSessionNumbersByDriver(prevState => ({
            ...prevState,
            [selectedDriver]: []
        }));
    };

    const racingDays = [
        { name: 'First Racing Day', value: 1 },
        { name: 'Second Racing Day', value: 2 },
        { name: 'Third Racing Day', value: 3 },
        { name: 'Fourth Racing Day', value: 4 },
        { name: 'Fifth Racing Day', value: 5 },
        { name: 'Sixth Racing Day', value: 6 },
        { name: 'Seventh Racing Day', value: 7 },
    ];

    const handleZoomIn = () => {
        setChartWidth((prevWidth) => prevWidth + 100); // Increase width by 100
        setChartHeight((prevHeight) => prevHeight + 50); // Increase height by 50
    };

    const handleZoomOut = () => {
        setChartWidth((prevWidth) => Math.max(prevWidth - 100, 200)); // Decrease width but not below 200
        setChartHeight((prevHeight) => Math.max(prevHeight - 50, 200)); // Decrease height but not below 200
    };

    const handleChipMouseEnter = (email) => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
        }
        setHoveredChip(email);
    };

    const handleChipMouseLeave = () => {
        const timeout = setTimeout(() => {
            setHoveredChip(null);
        }, 500);
        setHoverTimeout(timeout);
    };

    const handleTooltipMouseEnter = () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
        }
    };

    const handleTooltipMouseLeave = () => {
        const timeout = setTimeout(() => {
            setHoveredChip(null);
        }, 1500);
        setHoverTimeout(timeout);
    };

    const maxDataLength = Math.max(
        ...speedData.map(d => d.drivingData?.length),
        ...rpmData.map(d => d.drivingData?.length),
        ...tempData.map(d => d.drivingData?.length)
    );
    const xLabels = Array.from({ length: maxDataLength }, (_, i) => (i * 0.16).toFixed(3));

    const generateSeries = (data, label, yAxisKey, color) => data.map((entry) => ({
        data: entry.drivingData?.map(d => Object.values(d)[0]),
        label: `${label} - Lap ${entry.lap} - ${entry.email}`,
        yAxisKey,
        color,
        showMark: false,
    }));

    const rpmSeries = generateSeries(rpmData, 'RPM', 'leftAxisId', 'blue');
    const speedSeries = generateSeries(speedData, 'SPEED', 'rightAxisId', 'purple');
    const tempSeries = generateSeries(tempData, 'TEMP', 'rightAxisId', 'red');

    useEffect(() => {
        setCompetitionLoading(true);

        const fetchCompetitions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_competitions/?team_id=${selectedTeam.id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        accept: "application/json",
                    },
                });
                setCompetitions(response.data);
                setCompetitionLoading(false);
            } catch (error) {
                setCompetitionLoading(false);
                console.error('Error fetching competitions:', error);
            }
        };

        fetchCompetitions();
    }, [selectedTeam]);

    useEffect(() => {

        getVideoMetadata()

    }, [race, day, driverSessions, showVideo]);

    {/*useEffect(() => {
        getDrivers(false);
    }, [selectedTeam]);*/}
    useEffect(() => {
        getSessionsByDriver();
    }, [selectedDriver]);

    const getSessionsByDriver = async () => {
        axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/sessions_for_driver`, {
                params: {
                    user_email: selectedDriver,
                    race,
                    day: day,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    accept: "application/json",
                },
            })
            .then((res) => {
                setGivenDriverSessions(res.data);
                console.log("sessions given", res.data)
            })
            .catch((error) => {
                console.error("Axios Error:", error);
            });
    };

    const getDrivers = async (filter, racingData) => {
        axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    accept: "application/json",
                },
            })
            .then((res) => {
                if (showPersonalButton) {
                    setDrivers(res.data);
                    setDriver([res.data[0].email]);
                    setEmailName(res.data)
                } else {
                    setDrivers(res.data);
                    setEmailName(res.data);
                }

                if (filter) {
                    const racingDataEmails = racingData.map(item => item.email);
                    const filteredDrivers = res.data.filter(driver => racingDataEmails.includes(driver.email));
                    setDrivers(filteredDrivers);
                    setEmailName(filteredDrivers);
                }
            })
            .catch((error) => {
                console.error("Axios Error:", error);
            });
    };

    const handleFilterClick = (number, name) => {
        setOpenDriverDates(true);
        setStep(number);
        setSecondOpen(true)
        setCompleted(false);
        if (name) {
            setSelectedDriverDates(name)
        }
    }

    const renderDriverChips = () => {

        return driver.map((value) => {
            const selectedName = emailName.find(dataItem => dataItem.value === value);
            const name = selectedName?.label || '';
            const currentSession = driverSessions.find(ds => ds.email === value);
            const totalLaps = currentSession?.sessions[0]?.laps.length || 0;

            const handleChipClick = () => {
                if (filterDriver.includes(value)) {
                    setFilterDriver(prev => prev.filter(driver => driver !== value));
                } else {
                    setFilterDriver(prev => [...prev, value]);
                }
                setOpenFilter(prevState => !prevState);
                setSelectedDriver(selectedName.email);
                setSelectedSessionNumbers(sessionNumbersByDriver[selectedName.email] || []);

                const driverSessionData = driverSessions.find(ds => ds.email === selectedName.email);
                if (driverSessionData) {
                    const sessionData = driverSessionData.sessions.find(session => session.session === sessionNumber);
                    setLaps([{ email: selectedName.email, laps: sessionData ? sessionData.laps : [] }]);
                }
            };

            return (
                <div key={value} style={{ position: 'relative' }}>
                    <Chip
                        label={name}
                        onClick={() => handleFilterClick(showPersonalButton ? 2 : 3, name)}
                        onMouseEnter={() => handleChipMouseEnter(value)}
                        onMouseLeave={handleChipMouseLeave}
                        color={driverSessions.some(
                            item =>
                                item.email === value && // Controlla specificamente per il driver di questa Chip
                                item.sessions.some(session => session.laps.length > 0)
                        ) ? 'primary' : 'default'}
                        disabled={
                            openFilter && !filterDriver.includes(value)
                        }
                    />
                    {hoveredChip === value && totalLaps === 1 && (
                        <div
                            className="hover-tooltip"
                            style={{
                                position: 'absolute',
                                top: '-40px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                backgroundColor: 'white',
                                padding: '5px',
                                borderRadius: '4px',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                zIndex: 10,
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                        >
                            <ArrowBackIosIcon
                                style={{ cursor: 'pointer', fontSize: '12px' }}
                                onClick={() => handleLapChange(value, 'prev')}
                            />
                            <Typography variant="caption">{currentSession?.sessions[0]?.laps[0] || 0}</Typography>
                            <ArrowForwardIosIcon
                                style={{ cursor: 'pointer', fontSize: '12px' }}
                                onClick={() => handleLapChange(value, 'next')}
                            />
                        </div>
                    )}
                </div>
            );
        });
    };
    console.log('videoStatuses', videoStatuses)

    const getVideoStatus = async (libraryId, videoId) => {
        try {
            const url = `https://video.bunnycdn.com/library/${libraryId}/videos/${videoId}/play`;

            const response = await axios.get(
                url,
                // Corpo della richiesta con il titolo del video
                {
                    headers: {
                        accept: 'application/json', // Specifica il formato della risposta
                        'content-type': 'application/json', // Specifica il tipo di contenuto
                        AccessKey: '83d006c0-f495-4a2f-b2937024639c-b620-4a18', // Sostituisci con la tua Access Key
                    },
                }
            );

            console.log('Video creato con successo:', response.data?.video?.status);

            return response.data?.video?.status;
            // Restituisce i dettagli del video creato
        } catch (error) {
            console.error('Errore durante la creazione del video:', error.response?.data || error.message);
            throw error;
        }
    };


    const handleLapChange = (email, direction) => {
        setDriverSessions(prevSessions => {
            return prevSessions.map(ds => {
                if (ds.email === email) {
                    const currentLap = ds.sessions[0]?.laps[0] || 0;
                    const newLap = direction === 'next' ? currentLap + 1 : currentLap - 1;
                    ds.sessions[0].laps[0] = newLap;
                }
                return ds;
            });
        });
    };
    const comp = competitions?.find((competition) => competition.id === race)
    // const myCompetition = comp.competition;
    console.log("comp ", comp)

    const handleMouseMove = (event) => {
        const chart = event.currentTarget.getBoundingClientRect();
        const gridElement = event.currentTarget.querySelector('g.MuiChartsGrid-root');
        const gridRect = gridElement.getBoundingClientRect();

        const x = event.clientX;
        const y = event.clientY;

        const isWithinXAxis = x >= gridRect.left && x <= gridRect.right;
        const isWithinYAxis = y >= gridRect.top && y <= gridRect.bottom;

        if (isWithinXAxis && isWithinYAxis) {
            const closestIndex = Math.round((x - gridRect.left) / (gridRect.width) * (xLabels.length - 1));
            setTrackIndex(closestIndex);
        }
    };
    //console.log("driverSessions", driverSessions)
    console.log("Laps array ", laps)
    console.log("email array ", emailName)

    const handleArrayChange = (array) => {

        console.log("array changed", array)
        setStepPayload(array);
        setDriver(array.drivers?.map(driver => driver.value))
        setDay(array.day)
        setRace(array.race)
        setEmailName(array.drivers)
        setDriverSessions(array.driverSessions)
    }


    return (
        <>
            <Pricing open={showPricing} onClose={() => setShowPricing(false)} />
            <DriversDates isTry={false} selectedDriver={selectedDriverDates} completed={completed} isDriverAnalytics={true} setCompleted={setCompleted} laps={laps} driverSessions={driverSessions} stepPayload={stepPayload} setStepPayload={setStepPayload} isOpen={openDriverDates} setIsOpen={setOpenDriverDates} setStep={setStep} arrayChange={handleArrayChange} step={step} secondOpen={secondOpen} />
            <Grid container columnSpacing={3} sx={{ alignItems: "center" }}>
                <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
                    <Typography sx={{ fontSize: 40 }}>Driver analytics</Typography>
                </Grid>
                {/* <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
                    <div className="frame1" style={{ display: 'flex', flexDirection: "row", alignItems: 'center', gap: '10px', width: '100%' }}>
                        <FormControl fullWidth sx={{ textAlign: 'left', background: 'rgba(0, 0, 0, 0.00)', minWidth: 100 }}>
                            <InputLabel id="race-select-label">RACE</InputLabel>
                            <Select
                                labelId="race-select-label"
                                fullWidth
                                id="race-select"
                                value={race}
                                onChange={(e) => {
                                    let selectedCompetition;
                                    if (e.target.value !== 'select') {
                                        selectedCompetition = competitions.find(
                                            (competition) => competition.id === e.target.value
                                        );
                                    } else {
                                        selectedCompetition = 14
                                    }
                                    setRace(selectedCompetition.id);
                                    if (selectedCompetition) {
                                        setTrack(selectedCompetition.track);
                                    }
                                    if (!showPersonalButton) {
                                        setDriver([]);
                                    }
                                }}
                                label="Race"
                            >
                                {!competitionLoading ?
                                    (competitions.length === 0 ?
                                        <MenuItem value="select">Please Insert a Competition</MenuItem>
                                        :
                                        [
                                            <MenuItem key="select-race" value="select" id='14 '>Select Race</MenuItem>,
                                            ...competitions.map((competition) => (
                                                <MenuItem key={competition.id} value={competition.id}>
                                                    {competition.competition} ({competition.track})
                                                </MenuItem>
                                            ))
                                        ]
                                    ) : (
                                        <MenuItem value="" disabled>
                                            Loading...
                                        </MenuItem>
                                    )}
                            </Select>
                        </FormControl>
                        <Button
                            className="button"
                            aria-controls="number-menu"
                            aria-haspopup="true"
                            onClick={handleMenuOpen}
                            style={{ whiteSpace: 'nowrap', color: (!day ? 'red' : 'green') }}
                        >
                            <CalendarMonthIcon className="calendar-month" />
                        </Button>
                        <Mmenu
                            id="number-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {racingDays.map((racingDay) => (
                                <MenuItem key={racingDay.value} onClick={() => handleSelectDay(racingDay.value)}>
                                    {racingDay.name}
                                </MenuItem>
                            ))}
                        </Mmenu>
                    </div>
                </Grid>
                {!(showPersonalButton) && (
                    <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
                        <FormControl sx={{}} style={{ width: '100%' }}>
                            <InputLabel id="demo-multiple-chip-label">Driver</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={driver}
                                label="driver "
                                onChange={handleDriverChange}
                                open={selectOpen}
                                onClose={() => setSelectOpen(false)}
                                onOpen={() => setSelectOpen(true)}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => {
                                            const selectedName = emailName.find(dataItem => dataItem.email === value);
                                            const name = selectedName ? selectedName.surname : '';
                                            return (
                                                <Chip key={value} label={name} />
                                            );
                                        })}
                                    </Box>
                                )}
                            >
                                {drivers.map((driver) => (
                                    <MenuItem key={driver.id} value={driver.email}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            {driver.surname}
                                            {lastSelectedDriver === driver.email && (

                                                <Chip
                                                    label="done"
                                                    color="success"
                                                    size="small"
                                                    onClick={handleDoneClick}
                                                />

                                            )}
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}*/}
            </Grid>
            <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap', marginBottom: 10 }}>
                <Chip label={comp ? `${comp.competition} (${comp.track})` : "choose race"} icon={<SwapHorizIcon />} onClick={() => handleFilterClick(0)} />
                <Chip label={day ? ` Day ${day}  ` : 'choose day'} onClick={() => handleFilterClick(1)} />
                {!showPersonalButton && (<Chip label={driver?.length > 0 ? 'change drivers' : 'choose drivers'} onClick={() => handleFilterClick(2)} />)}
                {/*<div className='textEngine' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {renderDriverChips()}

                    </Box>
                </div>*/}
                <div style={{ marginLeft: 10 }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {renderDriverChips()}

                    </Box>
                </div>

            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>


                <Typography Variant="overline" Gutter Bottom='False' Content="Typography" className='customizeText'> CUSTOMIZE THE VISUALIZATION </Typography>
                <div className='engineButtons'>
                    <div style={{ display: 'flex', gap: 7 }}>
                        <Chip label="RPM" color='primary' size="medium" variant={showRPM ? 'contained' : 'outlined'} onClick={() => setShowRPM(!showRPM)} />
                        <Chip label="SPEED" color="secondary" size="medium" variant={showSpeed ? 'contained' : 'outlined'} onClick={() => setShowSpeed(!showSpeed)} />
                        <Chip label="WATER T°" color="error" size="medium" variant={showTemp ? 'contained' : 'outlined'} onClick={() => setShowTemp(!showTemp)} />
                    </div>
                    <div style={{ display: 'flex', gap: 7 }}>
                        <Chip label="TRACK MAP" color="primary" size="medium" variant={showMap ? 'contained' : 'outlined'} onClick={() => setShowMap(!showMap)} />
                        <Chip
                            label="ON BOARD"
                            color="warning" // Puoi mantenere il colore per default, ma lo stile lo sovrascrive
                            size="medium"
                            variant={showVideo ? 'contained' : 'outlined'}
                            onClick={() => {
                                if (myPlan === 'Pro' || myPlan === 'Team') {
                                    setShowVideo(!showVideo);
                                } else {
                                    setShowPricing(true);
                                }
                            }}
                        />
                        <Chip label="DATA TABLE" color='success' size="medium" variant={showTable ? 'contained' : 'outlined'} onClick={() => setShowTable(!showTable)} />
                    </div>





                </div>
                {showVideo && (
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            marginTop: 3,
                            gap: "16px", // Spaziatura tra i video
                        }}
                    >
                        {loadingVideos
                            ? (
                                <Box
                                    sx={{
                                        width: `${videoSize.width}px`,
                                        height: `${videoSize.height + 50}px`,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#f5f5f5", // Colore di sfondo del placeholder
                                        borderRadius: "16px",
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )
                            : video.map((videoItem, index) => {
                                const driverInfo = emailName.find(
                                    (email) => email.value === videoItem.driver
                                );
                                const driverLabel = driverInfo ? driverInfo.label : videoItem.driver;

                                return (

                                    videoItem?.data?.length > 0 ? (

                                        <Draggable key={index} bounds=".draggable-parent">
                                            <div
                                                style={{
                                                    position: "relative",
                                                    zIndex: 30000000000000,
                                                    width: `${videoSize.width}px`,
                                                    height: `${videoSize.height + 50}px`,
                                                    background: "black",
                                                    borderRadius: "16px",
                                                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        top: "20%",
                                                        left: "10%",
                                                        zIndex: 9999,
                                                        borderRadius: "16px",
                                                        backgroundColor: "white",
                                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                        overflow: "hidden",
                                                        width: `${videoSize.width}px`,
                                                        height: `${videoSize.height + 50}px`,
                                                    }}
                                                >

                                                    {/* Video controls */}
                                                    <Box
                                                        sx={{
                                                            position: "absolute",
                                                            top: window.innerWidth < 600 ? "4px" : "8px",
                                                            right: window.innerWidth < 600 ? "auto" : "8px",
                                                            left: window.innerWidth < 600 ? "8px" : "auto",
                                                            display: "flex",
                                                            flexDirection: window.innerWidth < 600 ? "row" : "column",
                                                            justifyContent: window.innerWidth < 600 ? "space-between" : "center", // Adjust alignment for mobile
                                                            gap: window.innerWidth < 600 ? 0 : 1,
                                                            zIndex: 9999,
                                                            width: window.innerWidth < 600 ? "calc(100% - 16px)" : "auto", // Add width for row layout on mobile
                                                        }}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor: "white",
                                                                borderRadius: "100%",
                                                                "&:hover": {
                                                                    backgroundColor: "#f0f0f0",
                                                                },
                                                            }}
                                                            onTouchStart={(e) => e.stopPropagation()}
                                                        >
                                                            <OpenWithIcon style={{ color: "black" }} />
                                                        </IconButton>

                                                        <IconButton
                                                            onClick={() => {
                                                                const updatedVideos = [...video];
                                                                updatedVideos.splice(index, 1); // Rimuovi solo il video corrente
                                                                setVideo(updatedVideos);
                                                                if (updatedVideos.length === 0) {
                                                                    setShowVideo(false); // Cambia variante del Chip solo se non ci sono più video
                                                                }
                                                            }}
                                                            onTouchStart={() => {
                                                                const updatedVideos = [...video];
                                                                updatedVideos.splice(index, 1); // Rimuovi solo il video corrente
                                                                setVideo(updatedVideos);
                                                                if (updatedVideos.length === 0) {
                                                                    setShowVideo(false); // Cambia variante del Chip solo se non ci sono più video
                                                                }
                                                            }}
                                                            sx={{
                                                                backgroundColor: "transparent",
                                                                borderRadius: "50%",
                                                                "&:hover": {
                                                                    backgroundColor: "#d3d3d3",
                                                                },
                                                                marginLeft: window.innerWidth < 600 ? "auto" : "0", // Align "Cancel" to the right on mobile
                                                            }}
                                                        >
                                                            <CloseIcon style={{ color: "black" }} />
                                                        </IconButton>
                                                        {window.innerWidth >= 600 && (
                                                            <>
                                                                <IconButton
                                                                    onClick={increaseSize}
                                                                    onTouchStart={increaseSize}
                                                                    sx={{
                                                                        backgroundColor: "transparent",
                                                                        borderRadius: "50%",
                                                                        "&:hover": {
                                                                            backgroundColor: "#d3d3d3",
                                                                        },
                                                                    }}
                                                                >
                                                                    <ZoomInIcon style={{ color: "black" }} />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={decreaseSize}
                                                                    onTouchStart={decreaseSize}
                                                                    sx={{
                                                                        backgroundColor: "transparent",
                                                                        borderRadius: "50%",
                                                                        "&:hover": {
                                                                            backgroundColor: "#d3d3d3",
                                                                        },
                                                                    }}
                                                                >
                                                                    <ZoomOutIcon style={{ color: "black" }} />
                                                                </IconButton>
                                                            </>
                                                        )}
                                                    </Box>


                                                    {videoStatuses[videoItem.data] === 7 ? (
                                                        // STATO 7: file non supportato
                                                        <Box
                                                            sx={{
                                                               
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "87%",
                                                                width: "100%",
                                                                backgroundColor: "red",
                                                                color: "#fff",
                                                                textAlign: "center",
                                                                fontSize: "1.2rem",
                                                                paddingTop:12
                                                            }}
                                                        >
                                                            File type not supported.
                                                            <br />
                                                            Try uploading an MP4 or .mov file, or contact{" "}
                                                            <strong>support@aimotor.eu</strong>.
                                                        </Box>
                                                    ) : videoStatuses[videoItem.data] !== 1 && videoStatuses[videoItem.data] !== 2 ? (
                                                        // STATO “pronto” – iframe video
                                                        <iframe
                                                            src={`https://iframe.mediadelivery.net/embed/361126/${videoItem.data}?autoplay=true&loop=false&muted=false&preload=true&responsive=true`}
                                                            loading="lazy"
                                                            style={{
                                                                border: 0,
                                                                position: "relative",
                                                                top: -10,
                                                                height: "87%",
                                                                width: "100%",
                                                            }}
                                                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
                                                            allowFullScreen
                                                            title="Video Player"
                                                        ></iframe>
                                                    ) : (
                                                        // STATO 1 o 2: elaborazione
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "87%",
                                                                width: "100%",
                                                                backgroundColor: "#000",
                                                                color: "#fff",
                                                            }}
                                                        >
                                                            Video processing...
                                                        </Box>
                                                    )}



                                                    <Typography
                                                        variant="body2"
                                                        align="center"
                                                        sx={{
                                                            marginTop: window.innerWidth < 500 ? "-10px" : "0px",
                                                            fontWeight: "bold",
                                                            color: "#333",
                                                            zIndex: 9999,
                                                        }}
                                                    >
                                                        {driverLabel} - Session {videoItem.session}
                                                    </Typography>
                                                </Box>
                                            </div>
                                        </Draggable>)
                                        :
                                        (

                                            <Draggable bounds=".draggable-parent">
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: videoSize.width,
                                                        zIndex: 3000000,
                                                        height: videoSize.height + 50,
                                                        backgroundColor: '#f0f0f0',
                                                        borderRadius: '8px',
                                                        flexDirection: 'column',
                                                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                    }}
                                                >
                                                    <Typography>No videos inserted yet!</Typography>
                                                    <Chip
                                                        label="Insert Video"
                                                        color="primary"
                                                        onClick={() => {
                                                            history.push('/racing-space/telemetry-data');
                                                        }}
                                                        sx={{ mt: 2 }}
                                                    />
                                                </Box>
                                            </Draggable>

                                        )
                                );
                            })}
                    </Box>
                )}





                <NewLineChart
                    speedData={speedData}
                    tempData={tempData}
                    rpmData={rpmData}
                    showRPM={showRPM}
                    showSpeed={showSpeed}
                    showTemp={showTemp}
                    setTrackIndex={setTrackIndex}
                    chartWidth={chartWidth}
                    chartHeight={chartHeight}
                    day={day}
                    driver={driver}
                    race={race}
                    driverSessions={driverSessions}
                    collapsed={collapsed}
                    showTable={showTable}

                />


                {rpmData.length > 0 && (
                    <Box mt={4}>
                        <TelemetryParent
                            race={race}
                            day={day}
                            driver={driver}
                            driverSessions={driverSessions}
                            hoveredData={hoveredData}
                            setHoveredData={setHoveredData}
                            setTrackIndex={setTrackIndex}
                            trackIndex={trackIndex}
                            collapsed={collapsed}
                        />
                    </Box>
                )}
                {showMap && (
                    <div style={{ marginTop: '20px', marginBottom: 50 }}>
                        <TrackMap
                            race={race}
                            day={day}
                            driver={driver}
                            laps={laps}
                            driverSessions={driverSessions}
                            track={track}
                            hoverLatitude={hoveredData.latitude}
                            hoverLongitude={hoveredData.longitude}
                            trackIndex={trackIndex}
                            sectorParts={sectorParts}
                        />
                    </div>
                )}
            </div>

            {openFilter && (
                <Card
                    className="filter-card"
                    elevation={4}
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '95%', // Make the card much wider
                        maxWidth: '1200px', // Set a maximum width for very large screens
                        height: '85%', // Increase the height to make the table more visible
                        zIndex: 1300,
                    }}
                >
                    <CardContent>
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                            {givenDriverSessions.length > 0 && (<Typography variant="subtitle1" gutterBottom>
                                Session Number
                            </Typography>)}
                            {givenDriverSessions.length == 0 && (<Typography variant="subtitle1" gutterBottom>

                            </Typography>)}
                            <CardActions sx={{ justifyContent: 'flex-end' }}>
                                <Button onClick={handleFilterReset} color="secondary">Reset</Button>
                                <Button onClick={handleApplyFilter} color="primary">Apply</Button>
                                {/* New Button to close the table */}
                                <Button onClick={() => setOpenFilter(false)} color="error">X</Button>
                            </CardActions>
                        </Box>
                        {givenDriverSessions.length === 0 ? (
                            // Show the message when there are no sessions
                            <Box sx={{ textAlign: 'center', p: 3, border: '1px solid #ddd', borderRadius: '8px', width: '100%', height: 450, alignContent: ' center' }}>
                                <Typography variant="h6" color="text.secondary">
                                    There are no files uploaded for this driver.
                                </Typography>
                            </Box>
                        ) : (
                            // Render session chips and telemetry table if sessions are available
                            <>
                                <Box sx={{ display: 'flex', gap: 1, marginBottom: '16px' }}>
                                    {givenDriverSessions.map((number) => (
                                        <Chip
                                            key={number}
                                            label={`${number}`}
                                            color={selectedSessionNumbers.includes(number) ? 'primary' : 'default'}
                                            variant={sessionNumber === number ? 'contained' : 'outlined'}
                                            onClick={() => handleSessionNumberChange(number)}
                                            clickable
                                        />
                                    ))}
                                </Box>
                                <Box sx={{ overflowY: 'auto', border: '1px solid #ddd', borderRadius: '8px', width: '100%' }}>
                                    <Telemetrytable
                                        race={race}
                                        day={day}
                                        drivers={driver}
                                        fullName={emailName}
                                        laps={laps}
                                        setLaps={handleLapsSelection}
                                        selectedDriverEmail={selectedDriver}
                                        sessionNumbersByDriver={sessionNumbersByDriver}
                                        setDatest={setDataset1}
                                        driverSessions={driverSessions}
                                    />
                                </Box>
                            </>
                        )}
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default observer(DriverAnalytics);
