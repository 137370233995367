import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Box,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DriversDates from "components/Steppers/DriversDates/DriversDates.js";
import myUserStore from "stores/UserStore";
import { observer } from "mobx-react-lite";
import HttpProvider from 'services/HttpProvider';
import { useHistory } from 'react-router-dom';

const DistributionDialog = ({ open, onClose, chatType }) => {
  const [selectedChip, setSelectedChip] = useState(null);
  const [customSlots, setCustomSlots] = useState(Array(10).fill(false));
  const [mainArray, setMainArray] = useState([]);
  const [originalMainArray, setOriginalMainArray] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
  const [stepPayload, setStepPayload] = useState({
    race: null,
    day: null,
    drivers: [],
    driverSessions: [],
  });
  const [openDriverDates, setOpenDriverDates] = useState(false);
  const [step, setStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [emailName, setEmailName] = useState([]);
  const [isSetting, setIsSetting] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [GivenLaps, setGivenLaps] = useState([]);

  const [selectedLap, setSelectedLap] = useState(
    GivenLaps?.length > 0 ? GivenLaps[0] : []
  );
  useEffect(() => {
    if (GivenLaps?.length > 0) {
      setSelectedLap(GivenLaps[0]);
    }
  }, [GivenLaps]);

  const history = useHistory();

  const selectedTeam = myUserStore.getSelectedTeam;

  const handleArrayChange = (array) => {
    setStepPayload(array);

    const { race, day, drivers, driverSessions } = array;

    const newEntries = [];
    driverSessions.forEach((driverSession) => {
      const { email, sessions } = driverSession;
      sessions.forEach(({ session, laps }) => {
        laps.forEach((lap) => {
          newEntries.push({
            race,
            day,
            team_id: selectedTeam.id,
            driver: email,
            session,
            lap,
          });
        });
      });
    });
    const updatedMainArray = [...mainArray];
    const slotIndex = currentSlot - 1;

    if (slotIndex < updatedMainArray.length) {
      if (newEntries[0]?.race && newEntries[0]?.driver) {
        updatedMainArray[slotIndex] = newEntries[0]; // Update only if valid data exists
      }
    } else {
      newEntries.forEach((entry) => {
        if (entry?.race && entry?.driver) {
          updatedMainArray.push(entry); // Add new entries only if valid
        }
      });
    }

    setMainArray(updatedMainArray);

    const updatedSlots = Array(10).fill(false);
    for (let i = 0; i < updatedMainArray.length; i++) {
      updatedSlots[i] = true;
    }
    setCustomSlots(updatedSlots);
  };
  const fetchGivenLaps = async () => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/laps_telemetry_highlights_user?team_id=${selectedTeam.id}&user_email=${parsedUserData.email}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {

        setGivenLaps(Array.isArray(res.data) ? res.data : []);

      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  useEffect(() => {
    fetchGivenLaps();
  }, [open]);
  useEffect(() => {
    if (open) {
      axios
        .get(`${process.env.REACT_APP_API_DOMAIN}/get_data_distribution/?team_id=${selectedTeam.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        })
        .then((response) => {
          const { data_distribution, custom_distribution } = response.data;

          setSelectedChip(data_distribution);
          setStep(0);
          if (data_distribution === 2) {
            setMainArray(custom_distribution);
            setOriginalMainArray(custom_distribution);

            const updatedSlots = Array(10).fill(false);
            for (let i = 0; i < custom_distribution.length; i++) {
              updatedSlots[i] = true;
            }
            setCustomSlots(updatedSlots);
          }
        })
        .catch((error) => {
          console.error("Error fetching data distribution:", error);
        });
    }
  }, [open]);

  const fetchCompetitions = async () => {
    HttpProvider.get(`/get_competitions/?team_id=${selectedTeam.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
    })
      .then((res) => {
        setCompetitions(res.data);
      })
      .catch((err) => {
        console.error("Error fetching competitions:", err);
      });
  };

  const updateDistribution = async (updatedArray) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/change_data_distribution/?new_data_distribution=${selectedChip}&team_id=${selectedTeam.id}`,
        updatedArray,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      console.log("Successfully updated distribution.");
    } catch (error) {
      console.error("Error updating distribution:", error);
    }
  };
  

  const handleChipClick = async (value) => {
    fetchCompetitions();
    setSelectedChip(value);
    
      try {
        await axios.post(
          `${process.env.REACT_APP_API_DOMAIN}/change_data_distribution/?new_data_distribution=${value}&team_id=${selectedTeam.id}`,
          mainArray,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
      } catch (error) {
        console.error("Error setting data distribution:", error);
      }
     

        fetchGivenLaps();
 
   



    if (value === 2) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/get_data_distribution/?team_id=${selectedTeam.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
        const { custom_distribution } = response.data;

        setMainArray(custom_distribution);
        setOriginalMainArray(custom_distribution);
        if (custom_distribution.length > 0) {
          try {
            await axios.post(
              `${process.env.REACT_APP_API_DOMAIN}/change_data_distribution/?new_data_distribution=${2}&team_id=${selectedTeam.id}`,
              custom_distribution,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                  accept: "application/json",
                },
              }
            );
          } catch (error) {
            console.error("Error setting data distribution:", error);
          }
        }

        const updatedSlots = Array(10).fill(false);
        for (let i = 0; i < custom_distribution.length; i++) {
          updatedSlots[i] = true;
        }
        setCustomSlots(updatedSlots);
      } catch (error) {
        console.error("Error fetching data distribution:", error);
      }
    } else {
      setCustomSlots(Array(10).fill(false));
      setMainArray([]);
      setOriginalMainArray([]);
    }
  };

  useEffect(() => {
    // Detect changes in mainArray and find new entries


    if (mainArray.length > 0) {
      // Call the API with only the new entries
      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}/change_data_distribution/?new_data_distribution=${selectedChip}&team_id=${selectedTeam.id}`,
          mainArray,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        )
        .then(() => {
          console.log("Successfully updated distribution with new entries.");
          // Update originalMainArray to include the new entries

        })
        .catch((error) => {
          console.error("Error updating data distribution:", error);
        });
    }
  }, [mainArray]);

  const handleSlotClick = (index) => {
    
    setCurrentSlot(index + 1);
    if (customSlots[index]) {
      const selectedSlot = mainArray[index];
      const emailNameEntry = emailName.find(item => item?.email === selectedSlot?.driver);
      debugger
      setStepPayload({
        race: selectedSlot.race || null,
        day: selectedSlot.day || null,
        drivers: selectedSlot.driver
        ? [{ value: selectedSlot.driver, label:    `${emailNameEntry?.name} ${emailNameEntry?.surname}` || selectedSlot?.driver }]
        : [],
        driverSessions: [],
      });
    } else {
      setStepPayload({
        race: null,
        day: null,
        drivers: [],
        driverSessions: [],
      });
      setOpenDriverDates(true);
    }
  };

  // const handleSetDistribution = async () => {
  //   setIsSetting(true);
  //   try {
  //     await axios.post(
  //       `${process.env.REACT_APP_API_DOMAIN}/change_data_distribution/?new_data_distribution=${selectedChip}&team_id=${selectedTeam.id}`,
  //       mainArray,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //           accept: "application/json",
  //         },
  //       }
  //     );
  //     onClose();
  //   } catch (error) {
  //     console.error("Error setting custom data distribution:", error);
  //   } finally {
  //     setIsSetting(false);
  //   }
  // };

  const hasChanges = () => JSON.stringify(mainArray) !== JSON.stringify(originalMainArray);

  const handleHelpDialogOpen = () => {
    setOpenHelpDialog(true);
  };

  const handleHelpDialogClose = () => {
    setOpenHelpDialog(false);
  };
  const getDrivers = async (filter, racingData) => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {

        setEmailName(res.data);

      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };
  useEffect(() => {
    fetchCompetitions();
    getDrivers();

  }, [selectedChip]);

  return (
    <>
      <DriversDates
        isDriverAnalytics={true}
        isDist={true}
        chatType={chatType}
        completed={completed}
        setCompleted={setCompleted}
        stepPayload={stepPayload}
        setStepPayload={setStepPayload}
        isOpen={openDriverDates}
        setIsOpen={(isOpen) => {
          setOpenDriverDates(isOpen);
          if (!isOpen) {
            setStep(0);
          }
        }}
        setStep={setStep}
        step={step}
        arrayChange={handleArrayChange}
      />

      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "8px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: "center" }}>
              Select a Distribution
            </Typography>

            <IconButton color="primary" onClick={handleHelpDialogOpen}>
              <HelpOutlineIcon />
            </IconButton>

          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              mb: 2,
              flexWrap: "wrap",
            }}
          >
            <Chip
              label="All Sessions"
              color={selectedChip === 0 ? "primary" : "default"}
              onClick={() => handleChipClick(0)}
              sx={{
                fontSize: "1rem",
              }}
            />
            <Chip
              label="Last Session"
              color={selectedChip === 1 ? "primary" : "default"}
              onClick={() => handleChipClick(1)}
              sx={{
                fontSize: "1rem",
              }}
            />
            <Chip
              label="Custom Distribution"
              color={selectedChip === 2 ? "primary" : "default"}
              onClick={() => handleChipClick(2)}
              sx={{
                fontSize: "1rem",
              }}
            />
          </Box>
          {(selectedChip === 0 || selectedChip === 1) && (
            <>
             {(selectedLap && GivenLaps?.length) > 0 && (
              <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "center", marginBottom: 2 }}>
              Laps analyzed
            </Typography>


             )} 
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                {GivenLaps?.map((lap, index) => (
                  <Chip
                    key={index}
                    label={`Lap ${lap.lap} (Session ${lap.session})`}
                    onClick={() => setSelectedLap(lap)}
                    sx={{
                      fontSize: "0.9rem",
                      cursor: "pointer",
                    }}
                    color={selectedLap === lap ? "primary" : "primary"}
                    variant={selectedLap === lap ? "outlined" : "outlined"}
                  />
                ))}
              </Box>
              {(selectedLap && GivenLaps?.length) > 0 ? (
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                    justifyContent: 'center',
                    display: 'flex',

                  }}
                >
                  <Box sx={{
                    maxWidth: 200,

                  }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                      Selected Lap Details
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ marginBottom: "10px" }} // Aggiunge spazio tra le righe
                    >
                      <strong>Day:</strong> {selectedLap.day}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ marginBottom: "10px" }}
                    >
                      <strong>Race:</strong>{" "}
                      {(() => {
                        const competitionDetails = competitions.find(
                          (item) => item.id === selectedLap?.race
                        );



                        return competitionDetails
                          ? competitionDetails.competition
                          : selectedLap?.race || "-";
                      })()
                      }
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ marginBottom: "10px" }}
                    >
                      <strong>Session:</strong> {selectedLap.session}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ marginBottom: "10px" }}
                    >
                      <strong>Lap:</strong> {selectedLap.lap}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ marginBottom: "10px" }}
                    >
                      <strong>Lap Time:</strong> {selectedLap.laptime}s
                    </Typography>


                  </Box>
                </Box>
              ) :
                (
                  <Box textAlign="center" sx={{ mt: 4 }}>
                    <Typography sx={{ mb: 2, fontSize: '1rem', color: 'gray' }}>
                      No telemetry data available
                    </Typography>
                    <Chip
                      label="Insert Telemetry Data"
                      clickable
                      onClick={() => { history.push('/racing-space/telemetry-data'); }}
                      sx={{
                        fontSize: '1rem',
                        padding: '12px 24px',
                        backgroundColor: '#1976d2',
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#115293',
                        },
                      }}
                    />
                  </Box>
                )}
            </>
          )}
          {selectedChip === 2 && (
            <Box sx={{ mb: 2 }}>
              <Grid container spacing={1} justifyContent="center">
                {customSlots?.map((slot, index) => (
                  <Grid item xs={4} sm={3} md={2} key={index}>
                    <Chip
                      label={`Slot ${index + 1}`}
                      color={slot ? "success" : "default"}
                      onClick={() => handleSlotClick(index)}
                      sx={{
                        fontSize: "0.9rem",
                        width: "100%",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {selectedChip === 2 && currentSlot && customSlots[currentSlot - 1] && mainArray[currentSlot - 1] && (
            <Box
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #ddd",
                borderRadius: "8px",

                padding: "15px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Slot {currentSlot} Data
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="body2">
                  <strong>Race:</strong>{" "}
                  {(() => {
                    const competitionDetails = competitions.find(
                      (item) => item.id === mainArray[currentSlot - 1]?.race
                    );
                    return competitionDetails
                      ? competitionDetails.competition
                      : mainArray[currentSlot - 1]?.race || "-";
                  })()}
                </Typography>
                <Typography variant="body2">
                  <strong>Driver:</strong>{" "}
                  {(() => {
                    const driverDetails = emailName.find(
                      (item) => item.email === mainArray[currentSlot - 1]?.driver
                    );
                    return driverDetails
                      ? `${driverDetails.name} ${driverDetails.surname}`
                      : mainArray[currentSlot - 1]?.driver || "-";
                  })()}
                </Typography>

                <Typography variant="body2">
                  <strong>Day:</strong> {mainArray[currentSlot - 1]?.day || "-"}
                </Typography>

                <Typography variant="body2">
                  <strong>Session:</strong> {mainArray[currentSlot - 1]?.session || "-"}
                </Typography>
                <Typography variant="body2">
                  <strong>Lap:</strong> {mainArray[currentSlot - 1]?.lap || "-"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 3,
                  width: "100%",
                  mt: 2,
                }}
              >
                <Chip
                  icon={<EditIcon />}
                  label="Edit"
                  color="primary"
                  onClick={() => setOpenDriverDates(true)}
                  sx={{
                    cursor: "pointer",
                  }}
                />
                <Chip
  icon={<DeleteIcon />}
  label="Cancel"
  color="error"
  onClick={() => {
    // Rimuovi lo slot selezionato da mainArray
    const updatedMainArray = [...mainArray];
    updatedMainArray.splice(currentSlot - 1, 1); // Rimuove lo slot selezionato

    // Aggiorna lo stato per mainArray e customSlots
    setMainArray(updatedMainArray);

    const updatedSlots = Array(10).fill(false);
    for (let i = 0; i < updatedMainArray.length; i++) {
      updatedSlots[i] = true;
    }
    setCustomSlots(updatedSlots);

    // Reset di currentSlot e stepPayload
    setCurrentSlot(null);
    setStepPayload({
      race: null,
      day: null,
      drivers: [],
      driverSessions: [],
    });

    // Chiamata della funzione per aggiornare la distribuzione
    updateDistribution(updatedMainArray);
  }}
  sx={{
    cursor: "pointer",
  }}
/>

              </Box>

            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          {/* {selectedChip === 2 && hasChanges() && (
            <Chip
              label={isSetting ? "Setting..." : "Set Distribution"}
              variant="contained"
              color="primary"
              onClick={handleSetDistribution}
              sx={{
                fontSize: "1rem",
              }}
              disabled={isSetting}
            />
          )} */}
          <Chip
            label="Close"
            variant="outlined"
            color="primary"
            onClick={onClose}
            sx={{
              fontSize: "1rem",
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={openHelpDialog} onClose={handleHelpDialogClose} fullWidth maxWidth="xs">
        {selectedChip === 2 ? (
          <>
            <DialogTitle>Custom Distribution Information</DialogTitle>
            <DialogContent>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>Select the slots</strong> you want to include in your custom distribution. <br />
                With <strong>Custom Distribution</strong>, you can <strong>customize</strong> your data retrieval preferences
                by selecting specific sessions and laps for tailored insights.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Chip
                label="Close"
                variant="outlined"
                color="primary"
                onClick={handleHelpDialogClose}
                sx={{
                  fontSize: "1rem",
                }}
              />
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>Distribution Info</DialogTitle>
            <DialogContent>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                ALL SESSIONS
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                With Distribution 1, AiMotor retrieves only the fastest lap, but from different sessions. It starts from the last session added and continues until the maximum number of laps is reached.
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                LAST SESSION 
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                With Distribution 2, AiMotor retrieves more than one lap, but only from the latest session.
              </Typography>
              <Typography variant="h7" sx={{ fontWeight: "bold", mt: 3 }}>
                The laps shown are the laps analyzed by the AI.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Chip
                label="Close"
                variant="outlined"
                color="primary"
                onClick={handleHelpDialogClose}
                sx={{
                  fontSize: "1rem",
                }}
              />
            </DialogActions>
          </>
        )}
      </Dialog>

    </>
  );
};

export default observer(DistributionDialog);