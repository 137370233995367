import '../components.css';

import { Card, CardActions, CardContent, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, TextField, } from '@mui/material';
import { Link, NavLink, Route, Switch, useHistory, } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import EmailIcon from '@mui/icons-material/Email';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import OutlinedInput from '@mui/material/OutlinedInput';
import Pricing from "./Pricing.js";
import Settings1 from './Settings1';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { googleLogout } from '@react-oauth/google';
import myUserStore from "../../stores/UserStore";
import { observer } from "mobx-react-lite"
import { toast } from "react-toastify";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import Memory from './Memory';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContactInside from './ContactInside';

const theme = createTheme({
  palette: {
    gold: {
      main: '#DAA520',
    },
  },
});

const Dataset =
{

  email: "albaginoguy@gmail.com",
  full_name: "Guy Albag",
  teams: ["Kart Republic", "Bugush Team "],
  teamInfo: [
    {
      id: 3,
      name: "Kart Republic",
      role: "manager",
      isOwner: true,
      members: [
        {
          email: "albaginoguy@gmail.com",
          full_name: " Guy Albag",
          role: "driver"
        },
        {
          email: "grassimartino3@gmail.com",
          full_name: " Iacopo Martinese",
          role: "manager"
        },
      ]

    },

  ]
}
  ;
const Requests = [
  {
    email: "albaginoguy@gmail.com",
    full_name: "Guy Albag",
    role: "driver",
    teams: ["Kart Republic", "Bugush Team"],
    teamInfo: [
      {
        id: 3,
        name: "Kart Republic",
        role: "driver",
        Owner: "Alberto Gambino",
        members: [
          {
            email: "albaginoguy@gmail.com",
            full_name: "Guy Albag",
            role: "driver"
          },
          {
            email: "grassimartino3@gmail.com",
            full_name: "Iacopo Martinese",
            role: "manager"
          }
        ]
      }
    ]
  },
  {
    email: "autoctono@gmail.com",
    full_name: "Denis",
    role: "driver",
    teams: ["Tony Kart"],
    teamInfo: [
      {
        id: 3,
        name: "Tony Kart",
        role: "driver",
        Owner: "Denis",
        members: [
          {
            email: "zy@gmail.com",
            full_name: "Guy Albag",
            role: "driver"
          },
          {
            email: "grassimartino3@gmail.com",
            full_name: "Iacopo Martinese",
            role: "manager"
          }
        ]
      }
    ]
  },

];



function Settings({ isLoggedIn }) {
  console.log("user data",)
  const [parsedUserData, setParsedUserData] = useState(JSON.parse(localStorage.getItem("user_data")));
  //const [selectedTeam, setSelectedTeam] = useState(JSON.parse(localStorage.getItem("selectedTeam")));
  const [selectedTeam, setSelectedTeam] = useState(myUserStore.getSelectedTeam);
  const [fullName, setFullName] = useState(Dataset.full_name);
  const [insertName, setInsertName] = useState(parsedUserData.name);
  const [insertSurname, setInsertSurname] = useState(parsedUserData.surname);
  const [showChangeName, setShowChangeName] = useState(false);
  const [showChangeSurname, setShowChangeSurname] = useState(false);
  const [showCreateTeam, setShowCreateTeam] = useState(false);
  const [showAllTeams, setShowAllTeams] = useState(false); // New state for showing all teams
  const [teamName, setTeamName] = useState('');
  const [data, setData] = useState(Dataset);
  const [Invitations, setInvitations] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [data1, setData1] = useState(Requests)
  const [openPrice, setOpenPrice] = useState(false)
  const [openMemory, setOpenMemory] = useState(false)
  const [showPricing, setShowPricing] = useState(false);
  const status = myUserStore.status
  const [myPlan, setMyPlan] = useState(status != 'CANCELLED' ? myUserStore?.subscriptionDetails?.custom_id : 'No Plan');
  //const [myPlan, setMyPlan] = useState("Team");
  console.log("my plan start is", myPlan)
  useEffect(() => {
    setMyPlan(myUserStore?.subscriptionDetails?.custom_id);
    console.log("my plan is", myPlan)
  }, [, myUserStore.subscriptionDetails]);

  const [openDialog, setOpenDialog] = useState(false);

  // Filter the teams where the user is the owner
  const ownedTeams = data.teamInfo.filter((team) => team.isOwner);

  useEffect(() => {
    if (myPlan === "Team" && ownedTeams.length > 1) {
      setOpenDialog(true);
    }
  }, [myPlan, ownedTeams]);


  const handleAddMember = async (team_id, showToast = true) => {


    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/add_member/?role=MGR&team_id=${team_id}&status=ACTIVE&user_id=${parsedUserData.user_id}`
    await axios.post(
      apiUrl,
      {
        role: "MGR",
        team_id: team_id,
        status: "ACTIVE",
        user_id: parsedUserData.user_id


      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      }
    )
      .then(
        (response) => {
          // showToast && toast.success("Data inserted correctly!", {
          //   position: "top-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          // });
        }


      )
      .catch(
        (error) => {
          console.error("Error Api", error)
        }
      )

  };

  const acceptInvitation = async (invitation_id) => {


    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/accept_invitation/?invitation_id=${invitation_id}`
    await axios.post(
      apiUrl,
      {
        invitation_id: invitation_id


      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      }
    )
      .then(
        (response) => {
          // toast.success("Request accepted!", {
          //   position: "top-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          // });
          getInvitations();
        }


      )
      .catch(
        (error) => {
          console.error("Error!", error)
        }
      )

  };


  const rejectInvitation = async (invitation_id) => {


    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/reject_invitation/?invitation_id=${invitation_id}`
    await axios.post(
      apiUrl,
      {
        invitation_id: invitation_id


      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      }
    )
      .then(
        (response) => {
          // toast.success("Request rejected!", {
          //   position: "top-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          // });
          getInvitations();
        }


      )
      .catch(
        (error) => {
          console.error("Error!", error)
        }
      )

  };






  const handleInsertTeam = async () => {
    if (teamName == '') {
      toast.error("Please insert the team name", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/insert_team/?team_name=${teamName}`
      setShowCreateTeam(false);
      const res = await axios.post(
        apiUrl,
        {
          team_name: teamName
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then(
          async (response) => {
            const team_id = response.data
            // toast.success("Team added!", {
            //   position: "top-right",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            // });
            const newSettingsData = await getSettings();

            if (newSettingsData != false) {

              console.log(team_id);
              await handleAddMember(team_id, false);
              const dont = false;
              getSettings(dont);

            } else {
              console.log("error while fetching settings")
              //return false;

            }
            history.push(`/racing-space/settings/team/${response.data}`)




          }


        )

    } catch (error) {
      console.error("Error while creating the team:", error);
    }
  };

  const handleChangeName = async () => {
    if (insertName == '') {
      toast.error("Please insert a name", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/change_name/?new_name=${insertName}`
      setShowCreateTeam(false);
      const res = await axios.post(
        apiUrl,
        {
          new_name: insertName
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then(
          (response) => {
            setShowChangeName(false);
            // toast.success("Name changed", {
            //   position: "top-right",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            // });
            const newUserData = {
              ...parsedUserData,
              name: insertName
            }
            setParsedUserData(newUserData)
            localStorage.setItem("user_data", JSON.stringify(newUserData));



          }


        )

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChangeSurname = async () => {
    if (insertSurname == '') {
      toast.error("Please insert a surname", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/change_surname/?new_surname=${insertSurname}`
      setShowCreateTeam(false);
      const res = await axios.post(
        apiUrl,
        {
          new_surname: insertSurname
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then(
          (response) => {
            setShowChangeSurname(false);
            // toast.success("Surname changed", {
            //   position: "top-right",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            // });
            const newUserData = {
              ...parsedUserData,
              surname: insertSurname
            }
            setParsedUserData(newUserData)
            localStorage.setItem("user_data", JSON.stringify(newUserData));



          }


        )

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSettings = async (dont) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/get_settings`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );

      console.log("Team info before", res.data);
      res.data.owner.forEach(ownerItem => {
        const teamExists = res.data.teams.some(team => team.team_id === ownerItem.team_id);
        if (!teamExists) {
          if (dont) {
            handleAddMember(ownerItem.team_id);
          }

          console.log("Membro inserito nel team", ownerItem.team_id)
        }
      });
      const transformedData = transformData(res.data);
      setData(transformedData);
      setIsLoading(false);
      const local = myUserStore.getSelectedTeam;
      const alreadySelected = transformedData.teamInfo.find(t => t.id === local.id)
      console.log(myUserStore.getSelectedTeam)
      if (Object.keys(local).length === 0 || !alreadySelected) {
        const selectedTeam = transformedData.teamInfo[0] || {};
        localStorage.setItem("selectedTeam", JSON.stringify(selectedTeam))
        myUserStore.setSelectedTeam(selectedTeam)
      }
      // Assuming your response contains an array of sessions
      console.log("Team info", transformData(res.data))

      console.log("user data", parsedUserData)

      return transformedData

    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
      return false;
    }
  };

  const getInvitations = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/get_invitations/`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );

      console.log("Invitations", res.data);
      setInvitations(res.data);
      const dont = true;
      getSettings(dont);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };




  useEffect(() => {

    getInvitations();
  }, []);








  const transformData = (data) => {
    const { email, surname, teams, owner, teams_info } = data;

    const transformedTeamsInfo = teams_info.map(team => ({
      id: team.team_id,
      name: team.team_name,
      role: team.role,
      isOwner: owner.some(t => t.team_id === team.team_id && t.team_owner === parsedUserData.user_id),
      members: team.members
    }));

    return {
      email: parsedUserData.email,
      surname: parsedUserData.surname,
      teams: teams.map(team => team.team_name),
      owners: owner,
      teamInfo: transformedTeamsInfo
    };
  };


  const findTeamId = (data, teamName) => {
    const team = data.owners.find(ownerItem => ownerItem.team_name === teamName);
    if (team) {
      return team.team_id;
    }
    return null; // Restituisce null se non trova il teamName
  };


  const handleNameChange = (event) => {
    setInsertName(event.target.value);
  };
  const handleSurnameChange = (event) => {
    setInsertSurname(event.target.value);
  };
  const handleTeamChange = (event) => {
    setTeamName(event.target.value);
  };
  console.log("team", teamName)

  const handleNameClick = () => {
    setFullName(fullName);
    setShowChangeName(false);
  };


  const handleTeamClick = (team) => {
    localStorage.setItem("selectedTeam", JSON.stringify(team))
    myUserStore.setSelectedTeam(team);
    setSelectedTeam(team);
    //setPersonalSpace(false);
    history.push(`/racing-space/settings/team/${team.id}`)
  };
  const handlePersonalClick = () => {
    localStorage.setItem("selectedTeam", JSON.stringify(personalTeam))
    myUserStore.setSelectedTeam(personalTeam)
    //setPersonalSpace(true);
    setSelectedTeam('');
  };
  const handleEditNameClick = () => {
    setShowChangeName(true);
  };
  const handleEditSurnameClick = () => {
    setShowChangeSurname(!showChangeSurname);
  };

  const handleCreateTeam = () => {
    !isNormal && !finishTeams ?
      setShowCreateTeam(true) :
      setShowPricing(true)
  };
  const handleCloseCreate = () => {
    setShowCreateTeam(false);
  };

  const handlePlanClick = () => {
    history.push(`/racing-space/plans`)
  }

  const handleCreate = () => {
    setShowCreateTeam(false);
    const newTeam = {
      id: Math.floor(Math.random() * 1000000), // Genera un ID casuale
      name: teamName,
      role: "manager",
      isOwner: true,
      members: [],
    };

    const updatedData = {
      ...Dataset,
      teamInfo: [...Dataset.teamInfo, newTeam],
      teams: [...Dataset.teams, newTeam.name],
    };

    setData(updatedData);
    setTeamName(''); // Resetta il campo di input
  }


  const logOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_data");
    myUserStore.setIsLoggedIn(false);
    myUserStore.resetUserSubscription();
    googleLogout()
    history.push("/sign-in");

  };

  const handleAcceptRequest = (teamId) => {
    console.log(`Accept request for team ID: ${teamId}`);
    // Implement the logic for accepting the request here
  };

  const handleRejectRequest = (teamId) => {
    console.log(`Reject request for team ID: ${teamId}`);
    // Implement the logic for rejecting the request here
  };

  console.log("Selected Team : ", selectedTeam)

  const personalTeam = data.teamInfo.find(team => team.name.startsWith('personal-'));
  console.log("Personal team found", personalTeam)

  const showPersonalButton = Boolean(personalTeam);
  const isNormal = myPlan == "Growth";
  const finishTeams = myPlan == 'Pro' && data?.teams?.length > 1

  console.log(fullName)
  console.log("Dataset", data)
  console.log("show", showCreateTeam)

  return (

    <React.Fragment>

      <Dialog
        open={false}
        onClose={null} // Prevent closing by clicking outside
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            Plan Limitation
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: '20px' }}>
            <Typography
              variant="body1"
              sx={{ fontSize: '1.2rem', marginBottom: '20px', textAlign: 'center' }}
            >
              <strong>
                With your current subscription plan, you can only manage one team.
                The other teams will be temporarily disabled until you upgrade your
                plan.
              </strong>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                justifyContent: 'center',
              }}
            >
              {ownedTeams
                .filter((team) => !team.name.startsWith("personal-"))
                .map((team, index) => (
                  <Chip
                    key={index}
                    label={team.name}
                    clickable
                    color={selectedTeam === team.name ? "primary" : "default"}
                    variant={selectedTeam === team.name ? "filled" : "outlined"}
                    sx={{
                      fontSize: '1rem',
                      padding: '10px',
                      borderRadius: '10px',
                      transition: 'background-color 0.3s ease, color 0.3s ease',
                    }}
                    onClick={() => {
                      setSelectedTeam(team.name); // Toggle the selected state
                    }}
                  />
                ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: '20px' }}>
          <Button
            startIcon={<UpgradeIcon />}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              history.push("/racing-space/plans"); // Redirect to plans page
            }}
            sx={{
              fontSize: '1rem',
              padding: '10px 20px',
              borderRadius: '20px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            Upgrade Plan
          </Button>
        </DialogActions>
      </Dialog>

      <div className="box">
        <Pricing open={showPricing} onClose={() => setShowPricing(false)} />
        <div className="title-and-contents">
          <Typography variant="h4" className="text-wrapper"> Settings</Typography>
          <div className="frame-2">
            <div className="frame-3">
              <div className="text-wrapper-2"><Typography variant='h5'> PERSONAL INFO </Typography></div>
              <div className="frame-4">
                <div className="frame-5">
                  <div className="frame-6">
                    <div className="frame-7">
                      <div className="text-wrapper-3"><Typography sx={{ fontWeight: 'bold' }}> EMAIL ADDRESS</Typography></div>
                      <div className="content">
                        <div className="min-height" />
                        <div className="text-wrapper-4">
                          <Typography> {data.email} </Typography>

                        </div>
                      </div>
                    </div>

                    <Dialog
                      open={showChangeName}
                      onClose={() => setShowChangeName(false)} // Prevent closing by clicking outside
                      maxWidth="sm"
                      fullWidth
                    >
                      <div className="change-name" data-palette-mode="dark">
                        <div className="frame">
                          <div className="text-wrapper">CHANGE NAME</div>
                          <TextField hasDiv={false} defaultValue={parsedUserData.name} onChange={handleNameChange} className="text-field-instance" label="Name" variant="filled" size="medium" />
                        </div>
                        <CheckIcon
                          className="icon-button-instance"
                          sx={{ color: 'white', cursor: 'pointer' }}
                          onClick={handleChangeName}
                          size="medium" />
                      </div>
                    </Dialog>
                    <Dialog
                      open={showChangeSurname}
                      onClose={() => setShowChangeSurname(false)} // Prevent closing by clicking outside
                      maxWidth="sm"
                      fullWidth
                    >
                      <div className="change-name" data-palette-mode="dark">
                        <div className="frame">
                          <div className="text-wrapper">CHANGE SURNAME</div>
                          <TextField hasDiv={false} defaultValue={parsedUserData.surname} onChange={handleSurnameChange} className="text-field-instance" label="Name" variant="filled" size="medium" />
                        </div>

                        <CheckIcon
                          className="icon-button-instance"
                          sx={{ color: 'white', cursor: 'pointer' }}
                          onClick={handleChangeSurname}
                          size="medium" />
                      </div>

                    </Dialog>

                  </div>
                  <div className="frame-6">
                    <div className="frame-wrapper">
                      <div className="frame-8" />
                    </div>
                  </div>
                </div>
                <div className="frame-5">
                  <div className="frame-6">
                    <div className="frame-6">
                      <div className="frame-7">
                        <div className="text-wrapper-3"><Typography sx={{ fontWeight: 'bold' }}> NAME </Typography></div>
                        <div className="content">
                          <div className="min-height" />
                          <div className="text-wrapper-4">
                            <Typography>{parsedUserData.name} </Typography>
                          </div>
                        </div>
                      </div>
                      <Button onClick={handleEditNameClick} variant='outlined'> EDIT</Button>
                    </div>
                    <div className="frame-wrapper">
                      <div className="frame-8" />
                    </div>
                  </div>
                </div>
                <div className="frame-5">
                  <div className="frame-6">
                    <div className="frame-6">
                      <div className="frame-7">
                        <div className="text-wrapper-3"><Typography sx={{ fontWeight: 'bold' }}> SURNAME </Typography></div>
                        <div className="content">
                          <div className="min-height" />
                          <div className="text-wrapper-4">
                            <Typography>{parsedUserData.surname} </Typography>
                          </div>
                        </div>
                      </div>
                      <Button onClick={handleEditSurnameClick} variant='outlined'> EDIT</Button>
                    </div>
                    <div className="frame-wrapper">
                      <div className="frame-8" />
                    </div>
                  </div>
                </div>
                <div className="frame-5">
                  <div className="frame-6">
                    <div className="frame-6">
                      <div className="frame-7">
                        <div className="text-wrapper-3"><Typography sx={{ fontWeight: 'bold' }}> YOUR PLAN </Typography></div>
                        <div className="content">
                          <div className="min-height" />
                          <div className="text-wrapper-4">
                            {status !== 'CANCELLED' ? (
                              <>
                                {myPlan === "Growth" && <Chip sx={{ marginTop: 2 }} label="Growth Plan" />}
                                {myPlan === "Pro" && <Chip sx={{ marginTop: 2 }} label="Pro Plan" color="secondary" />}
                                {myPlan === "Team" && <Chip sx={{ marginTop: 2 }} label="Team Plan" color="secondary" />}
                              </>
                            ) : (
                              <Chip sx={{ marginTop: 2 }} label="No Plan" />
                            )}


                          </div>
                        </div>
                      </div>
                      <Button sx={{ marginTop: 5 }} onClick={handlePlanClick} variant='outlined'> MANAGE </Button>
                    </div>
                    <div className="frame-wrapper">
                      <div className="frame-8" />
                    </div>


                  </div>
                </div>
                <div className="frame-5">
                  <div className="frame-6">
                    <div className="frame-6">
                      <div className="frame-7">
                        <div className="text-wrapper-3"><Typography sx={{ fontWeight: 'bold' }}>PERSONALIZATION </Typography></div>
                        <div className="content">
                          <div className="min-height" />
                          <div className="text-wrapper-4">
                            <Chip sx={{ marginTop: 2 }} label="Memory" />
                            <Memory open={openMemory} onClose={() => setOpenMemory(false)} />
                          </div>
                        </div>
                      </div>
                      <Button sx={{ marginTop: 5 }} onClick={() => setOpenMemory(true)} variant='outlined'> MANAGE </Button>
                    </div>
                    <div className="frame-wrapper">
                      <div className="frame-8" />
                    </div>


                  </div>
                </div>

              </div>
            </div>
            <div className="frame-3">
              <div className="text-wrapper-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: 'bold' }}>YOUR TEAMS</Typography>
                {data?.teamInfo?.length > 4 && (<Chip
                  label={showAllTeams ? "Show Less" : "View All"} // Dynamic label
                  color="info" // Set color to "info"
                  variant={showAllTeams ? 'outlined' : 'outlined'} // Toggle between outlined and contained
                  onClick={() => setShowAllTeams(!showAllTeams)} // Toggle state
                  sx={{
                    cursor: 'pointer',
                    fontSize: '1rem', // Make the button larger
                    padding: '8px 15px', // Add padding for size
                    marginRight: '10px', // Add margin to the right
                  }}
                />)}
              </div>
              <Grid container spacing={2} justifyContent="flex-start">
                {isLoading && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Skeleton variant="rectangular" className="personal-info" maxWidth={300} height={80} />
                  </Grid>
                )}
                {!isLoading &&
                  (<>
                    {showPersonalButton && (<Grid item xs={12} sm={6} md={3}>
                      <Button
                        className="personal"
                        variant={myUserStore.isPersonalSpace ? 'contained' : 'outlined'}
                        onClick={handlePersonalClick}
                        fullWidth
                      >
                        <div className="title-description">
                          <div className="frame-7">
                            <div className="text-wrapper-5">PERSONAL SPACE</div>
                            <div className="text-wrapper-6"><AccountCircleIcon />   {parsedUserData.name} {parsedUserData.surname}</div>
                          </div>
                        </div>
                      </Button>
                    </Grid>)}
                    {showAllTeams
                      ? data.teamInfo.map((team) => team !== personalTeam ? (
                        <Grid item xs={12} sm={6} md={3} xxs={12} key={team.id}>
                          <Button
                            className="personal-info"
                            variant={myUserStore.getSelectedTeam?.id === team.id ? 'contained' : 'outlined'}
                            onClick={() => handleTeamClick(team)}
                            fullWidth
                          >
                            <div className="title-description">
                              <div className="frame-7">
                                <div className="text-wrapper-5">{team.name}</div>
                                <div className="text-wrapper-6">{team.members.length} members</div>
                              </div>
                            </div>
                          </Button>
                        </Grid>
                      ) : null)
                      : data.teamInfo.slice(0, 4).map((team) => team !== personalTeam ? (
                        <Grid item xs={12} sm={6} md={3} xxs={12} key={team.id}>
                          <Button
                            className="personal-info"
                            variant={myUserStore.getSelectedTeam?.id === team.id ? 'contained' : 'outlined'}
                            onClick={() => handleTeamClick(team)}
                            fullWidth
                          >
                            <div className="title-description">
                              <div className="frame-7">
                                <div className="text-wrapper-5">{team.name}</div>
                                <div className="text-wrapper-6">{team.members.length} members</div>
                              </div>
                            </div>
                          </Button>
                        </Grid>
                      ) : null)}</>)}
                <Grid item xs={12} sm={6} md={3}>
                  <Button onClick={handleCreateTeam} className="add-wrapper" fullWidth>
                    <AddIcon />
                  </Button>


                  <Dialog
                    open={showCreateTeam}
                    onClose={() => setShowCreateTeam(false)} // Prevent closing by clicking outside
                    maxWidth="sm"
                    fullWidth
                  >
                    <div className="create-team" data-palette-mode="dark">
                      <div className="frame">
                        <div className="text-wrapper">CREATE A TEAM</div>
                        <TextField
                          defaultValue=""
                          className="text-field-instance"
                          onChange={handleTeamChange}
                          label="Team Name"
                          variant="filled"
                          size="medium"
                          fullWidth
                        />
                        <p className="p">After creating a team you can invite others to join.</p>
                        <div className="button-wrapper" data-palette-mode="dark">
                          <Button
                            className="design-component-instance-node-2"
                            color="secondary"
                            size="medium"
                            variant="contained"
                            onClick={handleInsertTeam}
                          >
                            CREATE TEAM
                          </Button>
                        </div>
                      </div>
                      <CloseIcon
                        className="icon-button-instance"
                        sx={{ color: 'white', cursor: 'pointer' }}
                        onClick={handleCloseCreate}
                        size="medium"
                      />
                    </div>
                  </Dialog>
                </Grid>
              </Grid>
            </div>
          </div>
          {Invitations.length > 0 && <Typography sx={{ fontWeight: 'bold' }}>REQUESTS</Typography>}

          <div className="frame-10">
            <Grid container spacing={2}>
              {Invitations.map((request, index) => (
                <Grid item xs={12} key={index}>
                  <Card>
                    <CardContent>
                      <Typography >{request.full_name} Invitation:</Typography>

                      <div key={request.id}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">{request.team_name}</Typography>
                        <Typography variant="body1">Invited by: {request.invited_by_name}   {request.invited_by_surname}</Typography> {/* Corrected Typography */}
                        <CardActions>
                          <Button
                            color="success"
                            startIcon={<CheckIcon />}
                            onClick={() => acceptInvitation(request.id)}
                          >
                            Accept
                          </Button>
                          <Button
                            color="error"
                            startIcon={<ClearIcon />}
                            onClick={() => rejectInvitation(request.id)}
                          >
                            Reject
                          </Button>
                        </CardActions>
                      </div>

                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <div className="frame-7">
              <Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>CONTACT US</Typography>
              <div className="content">
                <div className="min-height" />
                <div className="text-wrapper-4">
                  {/* Email Chip */}
                  <Chip
                    label="Email"
                    icon={<EmailIcon sx={{ color: "#2196f3" }} />} // Blue Email Icon
                    variant="outlined"
                    color="primary" // Outlined chip with primary color
                    sx={{
                      marginRight: 1, // Spacing between chips
                    }}
                    onClick={() => setContactDialogOpen(true)} // Opens the dialog
                  />
                  {/* WhatsApp Chip */}
                  <Chip
                    label="WhatsApp"
                    icon={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <WhatsAppIcon sx={{ color: "green" }} />
                      </Box>
                    }
                    variant="outlined"
                    sx={{
                      borderColor: "green",
                      color: "green",
                      margin: 1,
                    }}
                    onClick={() => window.open("https://wa.me/393397636905", "_blank")}
                  />
                </div>
              </div>
            </div>
            <div className="frame-10">
              <div className="text-wrapper-3"><Typography sx={{ fontWeight: 'bold' }}>ACCOUNT </Typography></div>

              <div className="frame-4" >
                <div className="frame-5">
                  <div className="frame-6">
                    <div className="frame-6">
                      <div className="frame-7">
                        <div className="content">
                          <div className="min-height" />
                          <div className="text-wrapper-4"><Typography> Log out</Typography></div>
                        </div>
                      </div>
                      <Button
                        className="design-component-instance-node"
                        color="primary"
                        size="small"
                        onClick={logOut}
                        variant="outlined"
                      > LOGOUT</Button>
                    </div>
                    <div className="div-wrapper">
                      <div className="frame-11" />
                    </div>
                  </div>
                </div>
                <div className="frame-5">
                  <div className="frame-6">

                    <div className="div-wrapper">
                      <div className="frame-11" />
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <Dialog open={contactDialogOpen} onClose={() => setContactDialogOpen(false)} maxWidth="sm" fullWidth>
        <ContactInside />
      </Dialog>

    </React.Fragment>
  );
}

export default observer(Settings);